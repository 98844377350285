import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ApiService } from 'src/app/_api/api.service';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ModalReturnComponent } from 'src/app/components/modal-return/modal-return.component';
import { finalize } from 'rxjs/internal/operators/finalize';
import { Payment } from 'src/app/models/payment';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-distributor-payments',
  templateUrl: './distributor-payments.component.html',
  styleUrls: ['./distributor-payments.component.sass']
})
export class DistributorPaymentsComponent implements OnInit {
  @ViewChild('showRecord')
  showRecordModal: ModalReturnComponent;
  paymentsFormArray: FormArray;
  recordForm: FormGroup;
  accountsTable: any;
  formatter: any;

  constructor(private api: ApiService, private changes: ChangeDetectorRef, private formBuilder: FormBuilder) {
    this.formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
  }

  ngOnInit() {
    this.recordForm = this.formBuilder.group({
      season: null,
      accountQuantity: null,
      payments: this.formBuilder.array([])
    });
    this.paymentsFormArray = this.recordForm.get('payments') as FormArray;
  }

  ngAfterViewInit(): void {
    this.accountsTable = $('#accounts-table').DataTable({
      'paging': true,
      'pageLength': 10,
      'searching': false,
      'serverSide': true,
      'lengthChange': false,
      'ajax': { url: `${environment.apiUrl}/payments/distributor`, data: (data) => { } },
      'language': { 'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json' },
      columns: [
        {
          data: 'seasonId.name',
          render: (data, type, row) => {
            return row.seasonId.name;
          }
        },
        {
          data: 'quantity',
          render: (data, type, row) => {
            var amount = this.formatter.format(row.quantity).slice(0, 1) + ' ' + this.formatter.format(row.quantity).slice(1);
            return '<p class="text-dark font-weight-bold">' + amount + '</p>';
          }
        },
        {
          data: null,
          orderable: false,
          searchable: false,
          defaultContent: '',
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).prepend(
              `<div class="btn-group" role="group" aria-label="Acciones">
                <button type="button" class="btn btn-info account-details" title="Ver detalles">
                  <i class="mdi mdi-plus"></i>
                </button>
              </div>`
            );
          }
        }
      ],
      drawCallback: () => {
        $('.account-details').on('click', (element) => {
          let account: any = this.accountsTable.row($(element.currentTarget).closest('tr')).data();
          this.loadRecordModal(account);
          this.showRecordModal.open(true);
        });
      }
    });
  }

  loadRecordModal(account: any) {
    this.recordForm.controls['season'].setValue(account.seasonId.name);
    this.recordForm.controls['accountQuantity'].setValue(this.formatter.format(account.quantity).slice(0, 1) + ' ' + this.formatter.format(account.quantity).slice(1));
    this.api.get('payments/getDistributorPaymentsByAccount/' + account.distributorId.id, account.id).subscribe((payments: Payment[]) => {
      payments.forEach(element => {
        this.paymentsFormArray.push(this.formBuilder.group({
          id: element.id,
          type: element.type ? 'Abono' : 'Descuento',
          paymentFormId: element.paymentFormId.paymentForm,
          date: element.date.split('-').reverse().join('/'),
          quantity: this.formatter.format(element.quantity).slice(0, 1) + ' ' + this.formatter.format(element.quantity).slice(1)
        }));
      });
    });
  }

  cancelRecordForm() {
    this.showRecordModal.close();
    this.recordForm.reset();
    while (this.paymentsFormArray.length !== 0) {
      this.paymentsFormArray.removeAt(0);
    }
    $('.modal-backdrop').remove();
  }
}
