import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/_api/api.service';
import { finalize } from 'rxjs/internal/operators/finalize';
import { Alerts } from 'src/app/utils/alerts';
import { Label } from 'src/app/models/label';
import FileSaver from 'file-saver';

declare var $: any;

@Component({
  selector: 'app-generate-codes',
  templateUrl: './generate-codes.component.html',
  styleUrls: ['./generate-codes.component.sass']
})
export class GenerateCodesComponent implements OnInit {
  lastGenerationData: Label;
  codesForm: FormGroup;
  products: any[] = [];
  grades: any[] = [];
  loading = false;
  submit = false;

  constructor(private apiService: ApiService, private formBuilder: FormBuilder) {
    this.apiService.getAll('labels').subscribe((label: Label) => {
      this.lastGenerationData = label;
    });

    this.apiService.getAll('productsCodes/getAll').subscribe((products: any[]) => {
      this.products = products;
    });
  }

  ngOnInit(): void {
    this.codesForm = this.formBuilder.group({
      grade: [null, Validators.required],
      productId: [null, Validators.required],
      quantity: [null, [Validators.required, Validators.pattern(new RegExp('^[0-9]*$'))]],
      initialBook: [null, [Validators.required, Validators.pattern(new RegExp('^[0-9]*$'))]],
      initialBox: [null, [Validators.required, Validators.pattern(new RegExp('^[C|c][0-9]{7}$'))]]
    });
  }

  ngAfterViewInit(): void {
    $('.select2').select2({ width: '100%', theme: 'bootstrap' });

    $('#code-product').on('change', () => {
      var value = $('#code-product').val();
      this.codesForm.controls['productId'].setValue(value);
      if (value) {
        var selected: any = this.products.find((product: any) => {
          return product.id == value;
        });
        if (selected.level == 'Preescolar' || selected.level == 'Secundaria') {
          this.grades = [1, 2, 3, "ÚNICO"];
        } else if (selected.level == 'Primaria') {
          this.grades = [1, 2, 3, 4, 5, 6, "ÚNICO"];
        } else {
          this.grades = [];
        }
        $('#grade').val(null).change();
        $('#booksxBox').val(selected.quantity);
        $('#select-control-1').find('.select2-selection').removeClass('select2-is-invalid');
      } else {
        this.grades = [];
        $('#booksxBox').val('');
        $('#grade').val(null).change();
      }
    });

    $('#grade').on('change', () => {
      var value = $('#grade').val();
      this.codesForm.controls['grade'].setValue(value);
      if (value) {
        $('#select-control-2').find('.select2-selection').removeClass('select2-is-invalid');
      }
    });
  }

  getLastGenerationData(): void {
    this.apiService.getAll('labels').subscribe((label: Label) => {
      this.lastGenerationData = label;
    });
  }

  submitForm(): void {
    if (this.codesForm.invalid) {
      this.submit = true;
      if (!this.codesForm.controls['productId'].value) {
        $('#select-control-1').find('.select2-selection').addClass('select2-is-invalid');
      }
      if (!this.codesForm.controls['grade'].value) {
        $('#select-control-2').find('.select2-selection').addClass('select2-is-invalid');
      }
      return;
    }
    this.loading = true;
    this.apiService.getCSVFile('labels/generateCodes', { grade: this.codesForm.controls['grade'].value, quantity: this.codesForm.controls['quantity'].value, box: this.codesForm.controls['initialBox'].value, book: this.codesForm.controls['initialBook'].value, productId: this.codesForm.controls['productId'].value }).pipe(finalize(() => {
      this.loading = false;
    })).subscribe((data: any) => {
      var blob = new Blob([data.body], { type: 'text/csv' });
      if (blob.size > 0) {
        var file = new File([blob], 'codigos.csv', { type: 'text/csv' });
        FileSaver.saveAs(file);
      } else {
        Alerts.AlertWarning('No existe suficiente información para generar este reporte');
      }
      this.cancelForm();
      this.getLastGenerationData();
    }, (error: any) => {
      Alerts.AlertFailed(error.message);
    });
  }

  cancelForm(): void {
    this.submit = false;
    this.codesForm.reset();
    $('#code-product').val(null).change();
    $('#select-control-1').find('.select2-selection').removeClass('select2-is-invalid');
    $('#select-control-2').find('.select2-selection').removeClass('select2-is-invalid');
  }
}