import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/_api/api.service';
import { Router } from '@angular/router';
import { Alerts } from 'src/app/utils/alerts';
import { CodesProducts } from 'src/app/models/codes-products';
import { finalize } from 'rxjs/operators';
import gradesList from 'src/assets/js/grade.json';
import { HttpParams } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-product-code-withouth-label-supply',
  templateUrl: './product-code-withouth-label-supply.component.html',
  styleUrls: ['./product-code-withouth-label-supply.component.sass']
})
export class ProductCodeWithouthLabelSupplyComponent implements OnInit, AfterViewInit {

  productSupply: FormGroup;
  products: CodesProducts[] = [];
  currentProductCode: CodesProducts = new CodesProducts();
  loading = false;
  submitted = false;
  currentStockOfProduct: number = 0;
  grades: any;

  constructor(private api: ApiService, private formBuilder: FormBuilder, private router: Router) {
    this.productSupply = this.formBuilder.group({
      product_code: [null, Validators.required],
      grade: ['', Validators.required],
      current_stock: ['', Validators.required],
      new_stock: ['', [Validators.required, Validators.min(1)]]
    });

    $("#grade").prop("disabled", true);
  }
  ngAfterViewInit(): void {
    $('#product_code').select2({
      theme: "bootstrap",
      width: '100%'
    });

    $('#grade').select2({
      theme: "bootstrap",
      width: '100%'
    });

    this.api.getAll('productsCodes/getAllProductsWithoutLabels').subscribe((productsData: CodesProducts[]) => {
      this.products = productsData;      
    });

    $('#product_code').on("change", () => {
      if ($('#product_code').val() != '') {
        this.currentProductCode = this.products.find((product: CodesProducts) => {
          return product.id == $('#product_code').val();
        });

        gradesList.forEach(element => {
          if (this.currentProductCode.level == element['code']) {
            this.grades = element['grades'];
          }
        });
        $('#grade').empty().trigger("change");
        this.grades.forEach((grades: any) => {
          if (grades == '7') {
            grades = 'UNICO';
          }
          var newOption = new Option(grades, grades, false, false);
          $('#grade').append(newOption);

        });
        $('#grade').val(null).trigger("change");
        $("#grade").prop("disabled", false);

        this.productSupply.controls['product_code'].setValue(this.currentProductCode.id);
        this.productSupply.controls['grade'].setValue(null);
        this.productSupply.controls['current_stock'].setValue(null);
        this.currentStockOfProduct = 0;
      }
    });

    $('#grade').on("change", () => {
      if ($('#grade').val() != null) {
        var grade = $('#grade').val();
        this.productSupply.controls['grade'].setValue(grade);
        this.api.getAll('boxes/getStockOfProduct', { code_product_id: this.productSupply.controls['product_code'].value, grade: this.productSupply.controls['grade'].value }).subscribe((stock : number) => {
          this.currentStockOfProduct = stock;
          this.productSupply.controls['current_stock'].setValue(this.currentStockOfProduct);
        });
      }
    });
  }

  ngOnInit() {
  }

  currentStock(): any {
    if (this.productSupply.controls['new_stock'].value) {
      var final = parseInt(this.productSupply.controls['new_stock'].value) + parseInt(this.productSupply.controls['current_stock'].value);
      return final;
    } else {
      return 0;
    }
  }

  onSubmit() {
    if (this.productSupply.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    var formData: FormData = new FormData();

    formData.append('product', this.currentProductCode.id);
    formData.append('grade', this.productSupply.controls['grade'].value);
    formData.append('new_stock', this.productSupply.controls['new_stock'].value);

    this.api.post("boxes/addCodeProductsToStock", formData).pipe(finalize(() => { this.loading = false })).subscribe((productsData: any[]) => {
      this.cancelSupplyProduct();
      Alerts.AlertSuccess('Se agregaron los productos al inventario');
    });
  }

  cancelSupplyProduct(){
    this.productSupply.reset();
    $('#product_code').val(null).trigger('change');
    $('#grade').val(null).trigger('change');
    this.currentStockOfProduct = 0;
    this.currentProductCode = null;
    this.router.navigate(['/']);
  }
}
