import { Distribution } from './distribution';
import { CodesProducts } from './codes-products';

export class DistributionBoxWithoutLabels{
    id : any;
    idDistribution: Distribution;
    idProduct: CodesProducts;
    level: string;
    grade: string;
    quantity: number;
}