import { Order } from './order';
import { CodesProducts } from './codes-products';

export class OrderProductsToSupply{
    id : any;
    idOrder: Order;
    idProduct: CodesProducts;
    level: string;
    grade: string;
    description: string;
    quantity: number;
    quantitySupplied: number;
}