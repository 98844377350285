import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { Distributor } from 'src/app/models/distributor';
import { DeleteModalComponent } from 'src/app/components/delete-modal/delete-modal.component';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ApiService } from 'src/app/_api/api.service';
import { ModalReturnComponent } from 'src/app/components/modal-return/modal-return.component';
import { DatePipe } from '@angular/common';
import { User } from 'src/app/models';
import { finalize } from 'rxjs/operators';
import { ValidationState } from 'src/app/utils/validation-state';
import { ValidationTown } from 'src/app/utils/validation-town';
import statesList from 'src/assets/js/statelist.json';
import townsList from 'src/assets/js/townlist.json';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-distributors',
  templateUrl: './distributors.component.html',
  styleUrls: ['./distributors.component.sass']
})

export class DistributorsComponent implements OnInit, AfterViewInit {
  @ViewChild('edit')
  private editModal: ModalReturnComponent;
  @ViewChild('create')
  private createModal: ModalReturnComponent;
  @ViewChild('delete')
  private deleteModal: DeleteModalComponent;
  @ViewChild('rejectModal')
  private rejectModal: DeleteModalComponent;
  @ViewChild('approveModal')
  private approveModal: DeleteModalComponent;

  createDistributorForm: FormGroup;
  editDistributorForm: FormGroup;
  isShowingForm: boolean = false;
  private distributorsTable: any;
  nameDistributor: string = '';
  
  submitted = false;

  states: any;
  towns: any;
  //nowDate: any;
  loading: boolean = false;
  copyAddress: boolean = false;
  

  constructor(private formBuilder: FormBuilder, private api: ApiService) {
    this.states = statesList;
  }

  ngOnInit() {
    this.createDistributorForm = this.formBuilder.group({
      code: [''],
      name: ['', [Validators.required, Validators.minLength(2)]],
      paternalSurname: ['', Validators.required],
      maternalSurname: ['', Validators.required],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      email: ['', [Validators.email, Validators.required]],
      state: [null, Validators.required],
      town: [null, Validators.required],
      business: ['', Validators.required],
      addressName: ['', Validators.required],
      freight: ['', Validators.required],
      freightName: ['', Validators.required],
      freightPaternalSurname: ['', Validators.required],
      freightMaternalSurname: ['', Validators.required],
      freightPhone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      shippingAddress: ['', Validators.required],
      shippingColony: ['', Validators.required],
      shippingZip: ['', Validators.required],
      shippingTown: [null, Validators.required],
      shippingState: [null, Validators.required],
      shippingPhone: [''],
      shippingWay: ['', Validators.required],
      invoiceAddress: ['', Validators.required],
      invoiceColony: ['', Validators.required],
      invoiceZip: ['', Validators.required],
      businessName: ['', Validators.required],
      rfc: ['', Validators.required],
      username: ['', Validators.required],
    }, { validators: [ValidationState.ValidateState, ValidationTown.ValidateTown] });

    this.editDistributorForm = this.formBuilder.group({
      id: [null],
      code: [''],
      name: ['', [Validators.required, Validators.minLength(2)]],
      paternalSurname: ['', Validators.required],
      maternalSurname: ['', Validators.required],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      email: ['', [Validators.email, Validators.required]],
      state: [null, Validators.required],
      town: [null, Validators.required],
      businessName: ['', Validators.required],
      username: ['', Validators.required],
    }, { validators: [ValidationState.ValidateState, ValidationTown.ValidateTown] });
  }

  ngAfterViewInit(): void {
    $('#states_code_1,#states_code_2').select2({ width: '100%', theme: 'bootstrap', placeholder: 'Selecciona el estado', dropdownParent: $('#CreateDistributorModal') });

    $('#states_code_3').select2({ width: '100%', theme: 'bootstrap', placeholder: 'Selecciona el estado', dropdownParent: $('#EditDistributorModal') });

    $('#states_code_1,#states_code_2,#states_code_3').val(null).trigger('change');

    $('#towns_code_1,#towns_code_2').select2({ width: '100%', disabled: true, theme: 'bootstrap', placeholder: 'Selecciona el municipio', dropdownParent: $('#CreateDistributorModal') });

    $('#towns_code_3').select2({ width: '100%', disabled: true, theme: 'bootstrap', placeholder: 'Selecciona el municipio', dropdownParent: $('#EditDistributorModal') });

    $('#towns_code_1,#towns_code_2,#towns_code_3').val(null).trigger('change');

    $('#shipping_way').select2({ width: '100%', theme: 'bootstrap', dropdownParent: $('#CreateDistributorModal'), placeholder: 'Selecciona la forma de envío' });

    $('#states_code_1').on('change', () => {
      if ($('#states_code_1').val() != '') {
        var currentState = $('#states_code_1').val();
        townsList.forEach(element => {
          if (currentState == element['code']) {
            this.towns = element['towns'];
          }
        });

        $('#towns_code_1').empty().trigger('change');

        this.towns.forEach((town: any) => {
          var newOption = new Option(town, town, false, false);
          $('#towns_code_1').append(newOption).trigger('change');
        });

        $('#towns_code_1').val(null).trigger('change');
        $('#towns_code_1').prop('disabled', false);

        this.createDistributorForm.controls['state'].setValue(currentState);
        this.createDistributorForm.controls['town'].setValue('');
      }
    });

    $('#states_code_2').on('change', () => {
      if ($('#states_code_2').val() != '') {
        var currentState = $('#states_code_2').val();
        townsList.forEach(element => {
          if (currentState == element['code']) {
            this.towns = element['towns'];
          }
        });

        $('#towns_code_2').empty().trigger('change');

        this.towns.forEach((town: any) => {
          var newOption = new Option(town, town, false, false);
          $('#towns_code_2').append(newOption).trigger('change');
        });

        $('#towns_code_2').val(null).trigger('change');
        $('#towns_code_2').prop('disabled', false);

        this.createDistributorForm.controls['shippingState'].setValue(currentState);
        this.createDistributorForm.controls['shippingTown'].setValue('');
      }
    });

    $('#states_code_3').on('change', () => {
      if ($('#states_code_3').val() != '') {
        var currentState = $('#states_code_3').val();
        townsList.forEach(element => {
          if (currentState == element['code']) {
            this.towns = element['towns'];
          }
        });

        $('#towns_code_3').empty().trigger('change');

        this.towns.forEach((town: any) => {
          var newOption = new Option(town, town, false, false);
          $('#towns_code_3').append(newOption).trigger('change');
        });

        $('#towns_code_3').val(null).trigger('change');
        $('#towns_code_3').prop('disabled', false);

        this.editDistributorForm.controls['state'].setValue(currentState);
        this.editDistributorForm.controls['town'].setValue('');
      }
    });

    $('#towns_code_1').on('change', () => {
      if ($('#towns_code_1').val() != '') {
        var currentTown = $('#towns_code_1').val();
        this.createDistributorForm.controls['town'].setValue(currentTown);
      }
    });

    $('#towns_code_2').on('change', () => {
      if ($('#towns_code_2').val() != '') {
        var currentTown = $('#towns_code_2').val();
        this.createDistributorForm.controls['shippingTown'].setValue(currentTown);
      }
    });

    $('#towns_code_3').on('change', () => {
      if ($('#towns_code_3').val() != '') {
        var currentTown = $('#towns_code_3').val();
        this.editDistributorForm.controls['town'].setValue(currentTown);
      }
    });

    $('#shipping_way').on('change', () => {
      if ($('#shipping_way').val() != '') {
        var currentShippingWay = $('#shipping_way').val();
        this.createDistributorForm.controls['shippingWay'].setValue(currentShippingWay);
      }
    });

    $('.datepicker').datepicker({ enableOnReadonly: true, todayHighlight: true, autoclose: true });

    $('#startDate').on('changeDate', function () {
      $('#startDate').val($('#startDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#startDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $('#endDate').datepicker('setStartDate', dtFormatted);
    });

    $('#endDate').on('changeDate', function () {
      $('#endDate').val($('#endDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#endDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $('#startDate').datepicker('setEndDate', dtFormatted);
    });

    this.distributorsTable = $('#distributors-table').DataTable({
      'ajax': {
        url: `${environment.apiUrl}/distributors`,
        data: function (data) {
          data.from = $('#initialDate').val();
          data.to = $('#finalDate').val();
          data.searchName = $('#search').val();
        }
      },
      'serverSide': true,
      'searching': false,
      'paging': true,
      'pageLength': 10,
      'lengthChange': false,
      'language': {
        'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json'
      },
      columns: [
        { data: 'id', visible: false },
        { data: 'code' },
        {
          data: 'name',
          render: (data, type, row, meta) => {
            return row['name'] + ' ' + row['paternalSurname'] + ' ' + row['maternalSurname'];
          }
        },
        { data: 'phone' },
        { data: 'state' },
        { data: 'email' },
        {
          data: null,
          orderable: false,
          searchable: false,
          defaultContent: '',
          createdCell: function (td, cellData, rowData, row, col) {
            var actions =
              `
              <div class="btn-group" role="group" aria-label="Acciones">
                <button type="button" class="btn btn-info distributor-show" title="Ver detalles">
                  <i class="mdi mdi-plus text-white"></i>
                </button>
                <button type="button" class="btn btn-warning distributor-edit" title="Editar">
                  <i class="mdi mdi-lead-pencil text-white"></i>
                </button>
                <button type="button" class="btn btn-danger distributor-delete" title="Eliminar">
                  <i class="mdi mdi-delete-forever"></i>
                </button>
            `;
            if (rowData['isApproved'] == "0") {
              actions +=
                `
                <button type="button" class="btn btn-facebook distributor-approve" title="Aprobar">
                  <i class="mdi mdi-check text-white"></i>
                </button>
                <button type="button" class="btn btn-dribbble distributor-reject" title="Rechazar">
                  <i class="mdi mdi-close text-white"></i>
                </button>
              `;
            }
            actions += `</div>`;
            $(td).prepend(actions);
          }
        }
      ],
      drawCallback: () => {
        $('.distributor-show').on('click', (element) => {
          let distributor: Distributor = this.distributorsTable.row($(element.currentTarget).closest('tr')).data();
          this.showDistributor(distributor);
        });

        $('.distributor-edit').on('click', (element) => {
          let distributor: Distributor = this.distributorsTable.row($(element.currentTarget).closest('tr')).data();
          this.editDistributor(distributor);
        });

        $('.distributor-delete').on('click', (element) => {
          let distributor: Distributor = this.distributorsTable.row($(element.currentTarget).closest('tr')).data();
          this.deleteDistributor(distributor);
        });

        $('.distributor-approve').on('click', (element) => {
          let distributor: Distributor = this.distributorsTable.row($(element.currentTarget).closest('tr')).data();
          this.approveDistributor(distributor);
        });

        $('.distributor-reject').on('click', (element) => {
          let distributor: Distributor = this.distributorsTable.row($(element.currentTarget).closest('tr')).data();
          this.rejectDistributor(distributor);
        });
      }
    });
  }

  /* Modal Functions */
  createDistributor() {
    this.cancelAllForms();
    $('#states_code_1,#towns_code_1,#shipping_way').val(null).trigger('change');
    $('#copy-checkbox').prop('checked', false);
    this.createModal.open(true);
  }

  editDistributor(distributor: Distributor) {
    this.fillEditDistributorForm(distributor, false);
    this.editModal.open(true);
  }

  showDistributor(distributor: Distributor) {
    this.fillEditDistributorForm(distributor, true);
    this.isShowingForm = true;
    this.editModal.open(false);
  }
  
  deleteDistributor(distributor: Distributor) {
    this.fillEditDistributorForm(distributor, false);
    this.deleteModal.open();
  }

  approveDistributor(distributor: Distributor) {
    this.fillEditDistributorForm(distributor, false);
    this.approveModal.open();
  }

  rejectDistributor(distributor: Distributor) {
    this.fillEditDistributorForm(distributor, false);
    this.rejectModal.open();
  }

  /* General functions */
  cancelAllForms() {
    this.loading = false;
    this.createModal.close();
    this.editModal.close();
    this.deleteModal.close();
    this.approveModal.close();
    this.rejectModal.close();
    this.createDistributorForm.reset();
    this.editDistributorForm.reset();
    this.submitted = false;
    this.isShowingForm = false;
    $('#state_control_1,#state_control_2,#state_control_3').find('.select2-selection').removeClass('select2-is-invalid');
    $('#town_control_1,#town_control_2,#town_control_3').find('.select2-selection').removeClass('select2-is-invalid');
    this.distributorsTable.ajax.reload();
  }

  fillEditDistributorForm(distributor: Distributor, disabled: Boolean) {
    this.cancelAllForms();
    this.api.get('users/getByEmail', distributor.email).subscribe((user: User) => {
      this.editDistributorForm.controls['username'].setValue(user.username);
    });
    Object.keys(distributor).forEach(key => {
      if (this.editDistributorForm.controls[key])
        this.editDistributorForm.controls[key].setValue(distributor[key]);
    });
    $('#states_code_3').val(distributor.state).trigger('change');
    $('#towns_code_3').val(distributor.town).trigger('change');
    $('#states_code_3').prop('disabled', disabled);
    $('#towns_code_3').prop('disabled', disabled);
    this.nameDistributor = this.editDistributorForm.controls['name'].value + ' ' + this.editDistributorForm.controls['paternalSurname'].value + ' ' + this.editDistributorForm.controls['maternalSurname'].value;
  }

  currentUserName() {
    if (this.createDistributorForm.controls['name'].value != null && this.createDistributorForm.controls['name'].value.length > 1 && this.createDistributorForm.controls['paternalSurname'].value != null && this.createDistributorForm.controls['paternalSurname'].value.length > 1 && this.createDistributorForm.controls['maternalSurname'].value != null && this.createDistributorForm.controls['maternalSurname'].value.length > 1) {
      var tmpCode = this.createDistributorForm.controls['name'].value.toString().toUpperCase() + '_' + this.createDistributorForm.controls['paternalSurname'].value.toString().toUpperCase() + '_' + this.createDistributorForm.controls['maternalSurname'].value.toString().toUpperCase();
      var normalizeTmpCode = tmpCode.normalize('NFD').replace(/[\u0300-\u036f\s]/g, '');
      this.createDistributorForm.controls['username'].setValue(normalizeTmpCode);
      return normalizeTmpCode;
    }
    else {
      return '';
    }
  }

  currentCode(): any {
    var date = new Date();
    //this.nowDate = new DatePipe('en-US').transform(date.toISOString().toLocaleString().slice(0, 16), 'dd-MM-yyyy hh:mm:ss', 'GTM-5');
    var nowTime = date.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' });
    var nowDate = date.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric'});

    if (this.createDistributorForm.controls['name'].value != null && this.createDistributorForm.controls['name'].value.length > 1 && this.createDistributorForm.controls['paternalSurname'].value != null && this.createDistributorForm.controls['paternalSurname'].value.length > 1 && this.createDistributorForm.controls['maternalSurname'].value != null && this.createDistributorForm.controls['maternalSurname'].value.length > 1) {
      var tmpCode = 'D' + this.createDistributorForm.controls['name'].value.toString().substr(0, 2).toUpperCase() + nowDate.substr(3, 2) + nowDate.substr(0, 2)  + nowTime.toString().substr(0, 2) + nowTime.toString().substr(3, 2) + nowTime.toString().substr(6, 2);
      this.createDistributorForm.controls['code'].setValue(tmpCode);
      return tmpCode;
    }
    else {
      return '';
    }
  }
  
  capitalizeSentence(string) {
    return string.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  
  refreshDistributorsTable() {
    this.distributorsTable.ajax.reload();
  }

  resetDistributorsTable() {
    $('#startDate,#endDate').data('datepicker').setDate(null);
    $('#initialDate,#finalDate,#search').val('');
    this.distributorsTable.ajax.reload();
  }

  get state() {
    return this.createDistributorForm.get('state');
  }

  get town() {
    return this.createDistributorForm.get('town');
  }
  
  copyData(evt) {
    this.copyAddress = evt.target.checked;
    if (this.copyAddress) {
      this.currentShippingAddress();
      this.currentShippingColony();
      this.currentShippingZip();
    }
    else {
      this.createDistributorForm.controls['invoiceAddress'].setValue('');
      this.createDistributorForm.controls['invoiceColony'].setValue('');
      this.createDistributorForm.controls['invoiceZip'].setValue('');
    }
  }

  currentShippingAddress() {
    if (this.copyAddress && this.createDistributorForm.controls['shippingAddress'].value != null && this.createDistributorForm.controls['shippingAddress'].value.length > 1) {
      this.createDistributorForm.controls['invoiceAddress'].setValue(this.createDistributorForm.controls['shippingAddress'].value.toString());
    }
  }

  currentShippingColony() {
    if (this.copyAddress  && this.createDistributorForm.controls['shippingColony'].value != null && this.createDistributorForm.controls['shippingColony'].value.length > 1) {
      this.createDistributorForm.controls['invoiceColony'].setValue(this.createDistributorForm.controls['shippingColony'].value.toString());
    }
  }

  currentShippingZip() {
    if (this.copyAddress && this.createDistributorForm.controls['shippingZip'].value != null && this.createDistributorForm.controls['shippingZip'].value.length > 1) {
      this.createDistributorForm.controls['invoiceZip'].setValue(this.createDistributorForm.controls['shippingZip'].value.toString());
    }
  }

  /* API Functions */
  onSubmitCreateDistributorForm() {
    if (this.createDistributorForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    this.createDistributorForm.controls['code'].setValue(this.currentCode());
    var distributor: Distributor = Object.assign({}, this.createDistributorForm.value);
    var formData: FormData = new FormData();
    formData.append('name', this.createDistributorForm.controls['name'].value + ' ' + this.createDistributorForm.controls['paternalSurname'].value + ' ' + this.createDistributorForm.controls['maternalSurname'].value);
    formData.append('username', this.createDistributorForm.controls['username'].value);
    formData.append('password', '');
    formData.append('isAdmin', 'true');
    formData.append('email', this.createDistributorForm.controls['email'].value);
    this.api.post('users/distributor', formData).pipe(finalize(() => { this.loading = false; })).subscribe((usersData: User[]) => {
      this.api.post('distributors', distributor).pipe(finalize(() => { this.loading = false; })).subscribe((distributors: Distributor[]) => {
        this.cancelAllForms();
        this.alertSuccess('El distribuidor ha sido guardado');
      }, (error) => {
        this.alertFailed(error.error.errors.join('; '));
      });
    }, (error) => {
      this.alertFailed(error.error.errors.join('; '));
    });
  }

  onSubmitEditDistributorForm() {
    if (this.editDistributorForm.invalid) {
      this.submitted = true;
      return;
    }
    if (this.editDistributorForm.controls['id'].value) {
      this.loading = true;
      var distributor: Distributor = Object.assign({}, this.editDistributorForm.value);
      this.api.patch('distributors', this.editDistributorForm.controls['id'].value, distributor).pipe(finalize(() => { this.loading = false; })).subscribe((distributors: Distributor[]) => {
        this.cancelAllForms();
        this.alertSuccess('El distribuidor ha sido actualizado');
      }, (error) => {
        this.alertFailed(error.error.errors.join('; '));
      });
    }
  }

  onSubmitDeleteDistributorForm() {
    this.loading = true;
    var distributor: Distributor = Object.assign({}, this.editDistributorForm.value);
    this.api.patch('distributors/delete', this.editDistributorForm.controls['id'].value, distributor).pipe(finalize(() => { this.loading = false; })).subscribe((distributors: Distributor[]) => {
      this.cancelAllForms();
      this.alertSuccess('El distribuidor ha sido eliminado');
    });
  }
  
  onSubmitApproveDistributorForm() {
    this.loading = true;
    var distributor: Distributor = Object.assign({}, this.editDistributorForm.value);
    this.api.patch('distributors/approve', this.editDistributorForm.controls['id'].value, distributor).pipe(finalize(() => { this.loading = false; })).subscribe((distributors: Distributor[]) => {
      this.cancelAllForms();
      this.alertSuccess('El distribuidor ha sido aprobado');
    }, (error) => {
      this.cancelAllForms();
      this.alertFailed(error.error.errors.join('; '));
    });
  }

  onSubmitRejectDistributorForm() {
    this.loading = true;
    var distributor: Distributor = Object.assign({}, this.editDistributorForm.value);
    this.api.patch('distributors/reject', this.editDistributorForm.controls['id'].value, distributor).pipe(finalize(() => { this.loading = false; })).subscribe((distributors: Distributor[]) => {
      this.cancelAllForms();
      this.alertSuccess('El distribuidor ha sido rechazado');
    }, (error) => {
      this.cancelAllForms();
      this.alertFailed(error.error.errors.join('; '));
    });
  }

  /* Alert Functions */
  alertSuccess(title) {
    $.toast({
      heading: 'Realizado',
      text: title,
      showHideTransition: 'slide',
      icon: 'success',
      loaderBg: '#f96868',
      position: 'bottom-left'
    });
  }

  alertFailed(title) {
    $.toast({
      heading: 'Error',
      text: title,
      showHideTransition: 'slide',
      icon: 'error',
      loaderBg: '#f2a654',
      position: 'bottom-left'
    });
  }
}