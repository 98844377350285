import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Alerts } from 'src/app/utils/alerts';
import { ApiService } from 'src/app/_api/api.service';
import { finalize } from 'rxjs/internal/operators/finalize';
declare var $: any;

@Component({
  selector: 'app-trash-boxes',
  templateUrl: './trash-boxes.component.html',
  styleUrls: ['./trash-boxes.component.sass']
})
export class TrashBoxesComponent implements OnInit, AfterViewInit {
  invalid = false;
  loading = false;

  constructor(private api: ApiService) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    $('#boxCode').on('keypress', (event) => {
      var code = (event.keyCode ? event.keyCode : event.which);
      if (code == 13) {
        this.search();
      }
    });
  }

  search(): void {
    var boxCode = $('#boxCode').val();
    if (!(/^C[0-9]{7}$/i.test(boxCode))) {
      this.invalid = true;
      return;
    }
    this.invalid = false;
    this.loading = true;
    this.api.get('trash-boxes/findBox', boxCode).pipe(finalize(() => {
      this.loading = false;
      this.reset();
    })).subscribe((response) => {
      Alerts.AlertSuccess('Registro de caja merma exitoso');
    }, (error) => {
      if (error.error.error) {
        Alerts.AlertFailed(error.error.message);
      } else {
        Alerts.AlertFailed(error.error.errors.join('; '));
      }
    });
  }

  reset(): void {
    this.invalid = false;
    this.loading = false;
    $('#boxCode').val('');
  }
}