import { Category } from './category';
import { User } from './user';

export class Product{
    id: any;
    code: string;
    title: string;
    grade: string;
    level: string;
    unit: string;
    stock: number;
    author: string;
    isbn: string;
    status: string;
    isMultiplied: boolean;
    boxOriginGrade: string;
    distributorActive: boolean;
    price: number;
    createdAt: string;
    updatedAt: string;
    idCategory: Category;
    idUser: User;
    idProduct: {
        code: string;
        title: string;
        author: string;
        createdAt: string;
        distributorActive: string;
        grade: string;
        id: string;
        isbn: string;
        level: string;
        price: number;
        relation: boolean;
        status: boolean;
        stock: number;
        unit: string;
        updatedAt: string;
    };
    //quitar este
    statusProductBox: any;
}