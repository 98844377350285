import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { Product } from 'src/app/models/products';
import { ModalReturnComponent } from 'src/app/components/modal-return/modal-return.component';
import { FormGroup, Validators, FormBuilder, FormArray, AbstractControl } from '@angular/forms';
import gradesList from 'src/assets/js/grade.json';
import { ApiService } from 'src/app/_api/api.service';
import { Category } from 'src/app/models/category';
import { DeleteModalComponent } from 'src/app/components/delete-modal/delete-modal.component';
import { finalize } from 'rxjs/operators';
import { ValidationUnit } from 'src/app/utils/validation-unit';
import { ValidationCategory } from 'src/app/utils/validation-category';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.sass']
})
export class ProductsComponent implements OnInit, AfterViewInit {
  @ViewChild(ModalReturnComponent)
  private createModal: ModalReturnComponent;
  @ViewChild(DeleteModalComponent)
  private deleteModal: DeleteModalComponent;
  @Input('title') titleModal: string;

  productsTable: any;
  isEditingProduct: boolean = false;
  isShowingProduct: boolean = false;
  submitted = false;
  productForm: FormGroup;
  title: string = "";
  grades: any;
  categories: Category[] = [];
  products: Product[] = [];
  isAssociatedProduct: Boolean = false;
  loading: boolean = false;

  constructor(private formBuilder: FormBuilder, private api: ApiService) { }

  ngOnInit() {
    this.productForm = this.formBuilder.group({
      id: [null],
      code: ['', Validators.required],
      title: ['', Validators.required],
      grade: [''],
      level: [''],
      unit: [null],
      stock: ['', [Validators.required, Validators.min(0), Validators.pattern(new RegExp("^[0-9]*$"))]],
      price: ['', Validators.required],
      author: [''],
      isbn: [''],
      distributorActive: [false],
      idCategory: [null],
      products: [null]
    }, {
      validators: [ValidationUnit.ValidateUnit, ValidationCategory.ValidateCategory]
    });
  }

  ngAfterViewInit(): void {
    this.api.getAll('categories/getAll').subscribe((categoriesData: Category[]) => {
      this.categories = categoriesData;
    });
    $('#products').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona los productos asociados',
      width: '100%',
      dropdownParent: $('#ProductModal'),
    });

    $('#level').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el nivel',
      width: '100%',
      dropdownParent: $('#ProductModal')
    });
    $('#level').val(null).trigger('change');

    $('#unit').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona la unidad',
      width: '100%',
      dropdownParent: $('#ProductModal')
    });
    $('#unit').val(null).trigger('change');

    $('#categories').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona la categoría',
      width: '100%',
      dropdownParent: $('#ProductModal')
    });
    $('#categories').val(null).trigger('change');

    $('#grade').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el grado',
      width: '100%',
      disabled: true,
      dropdownParent: $('#ProductModal')
    });
    $('#grade').val(null).trigger('change');

    $('#level').on("change", () => {
      if ($('#level').val() != '') {
        var level = $('#level').val();
        gradesList.forEach(element => {
          if (level == element['code']) {
            this.grades = element['grades'];
          }
        });
        $('#grade').empty().trigger("change");
        this.grades.forEach((grades: any) => {
          if (grades == '7') {
            grades = 'UNICO';
          }
          var newOption = new Option(grades, grades, false, false);
          $('#grade').append(newOption).trigger('change');

        });
        $('#grade').val(null).trigger("change");
        $("#grade").prop("disabled", false);
        this.productForm.controls['level'].setValue(level);
      }
    });

    $('#unit').on("change", () => {
      if ($('#unit').val() != '') {
        var unit = $('#unit').val();
        this.productForm.controls['unit'].setValue(unit);
      }
    });

    $('#categories').on("change", () => {
      var currentCategory: Category = this.categories.find((category: Category) => {
        return category.id == $('#categories').val();
      });
      this.productForm.controls['idCategory'].setValue(currentCategory);
    });

    $('#products').on("change", () => {
      this.productForm.controls['products'].setValue($('#products').val());
    });

    $('#grade').on("change", () => {
      if ($('#grade').val() != '') {
        var grade = $('#grade').val();
        this.productForm.controls['grade'].setValue(grade);
      }
    });

    $('.datepicker').datepicker({
      enableOnReadonly: true,
      todayHighlight: true,
      autoclose: true,
    });

    $('#initialDate').on('changeDate', function () {
      $('#initialDate').val($('#initialDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#initialDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $("#finalDate").datepicker("setStartDate", dtFormatted);
    });

    $('#finalDate').on('changeDate', function () {
      $('#finalDate').val($('#finalDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#finalDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $("#initialDate").datepicker("setEndDate", dtFormatted);
    });

    this.productsTable = $('#products-table').DataTable({
      'ajax': {
        url: `${environment.apiUrl}/products`,
        data: function (data) {
          data.from = $("#initialDate").val();
          data.to = $("#finalDate").val();
          data.searchData = $("#search").val();
        }

      },
      'serverSide': true,
      'searching': false,
      'paging': true,
      'lengthChange': false,
      'language': {
        'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json'
      },
      columns: [
        { data: 'id', visible: false },
        { data: 'code' },
        { data: 'level' },
        { data: 'grade' },
        { data: 'title' },
        { data: 'isbn' },
        { data: 'author' },
        { data: 'stock' },
        { data: 'price', render: $.fn.dataTable.render.number( ',', '.', 2, '$' ) },
        {
          data: null,
          defaultContent: '',
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).prepend(
              `
                  <div class="btn-group" role="group" aria-label="Acciones">
                  <button type="button" class="btn btn-info btn-success product-show" title="Ver detalles">
                  <i class="mdi mdi-plus text-white"></i>
                  </button>
                  <button type="button" class="btn btn-info btn-warning product-edit" title="Editar">
                  <i class="mdi mdi-lead-pencil text-white"></i>
                  </button>
                  <button type="button" class="btn btn-info btn-danger product-delete" title="Eliminar">
                  <i class="mdi mdi-delete-forever"></i></button>
                  </div>
                  `
            );
          },
          orderable: false,
          searchable: false
        }
      ],
      drawCallback: () => {
        $('.product-show').on('click', (element) => {
          let product: Product = this.productsTable.row($(element.currentTarget).closest('tr')).data();
          this.showProduct(product);
        });

        $('.product-edit').on('click', (element) => {
          let product: Product = this.productsTable.row($(element.currentTarget).closest('tr')).data();
          this.editProduct(product);
        });

        $('.product-delete').on('click', (element) => {
          let product: Product = this.productsTable.row($(element.currentTarget).closest('tr')).data();
          this.deleteProduct(product);
        });
      }
    });
  }

  //Modal functions
  createProduct() {
    this.resetModals();
    this.api.getAll('products/getProductsWithOutAsssociated').subscribe((productsData: Product[]) => {
      this.products = productsData;
      $('#products').empty().trigger("change");
      this.products.forEach((product: Product) => {
        var newOption = new Option(product.title, product.id, false, false);
        $('#products').append(newOption).trigger('change');
      });
      $("#level").prop("disabled", false);
      $("#unit").prop("disabled", false);
      $("#categories").prop("disabled", false);
      $("#products").prop("disabled", false);
      this.isEditingProduct = false;
      this.isShowingProduct = false;
      this.productForm.controls['distributorActive'].setValue(false);
      this.createModal.open(true);
    });
  }

  editProduct(product: Product) {
    this.resetModals();
    this.api.get('products/getProductsWithOutCurrentProduct', product.id).subscribe((productsData: Product[]) => {
      this.api.get('products/getProductsWithAsssociatedProducts', product.id).subscribe((productsAssociated: String[]) => {
        this.title = "Editar producto";
        this.isEditingProduct = true;
        this.isShowingProduct = false;
        this.products = productsData;
        $('#products').empty().trigger("change");
        this.products.forEach((product: Product) => {
          var newOption = new Option(product.title, product.id, false, false);
          $('#products').append(newOption).trigger('change');
        });
        $('#products').val(null).trigger('change');

        Object.keys(product).forEach(key => {
          if (this.productForm.controls[key])
            this.productForm.controls[key].setValue(product[key]);
        });
        this.productForm.controls['price'].setValue(product.price.toFixed(2));
        $('#level').val(product.level).trigger('change');
        $("#grade").prop("disabled", false);
        $("#level").prop("disabled", false);
        $("#categories").prop("disabled", false);
        $("#unit").prop("disabled", false);
        $('#grade').val(product.grade).trigger('change');
        $('#unit').val(product.unit).trigger('change');
        $('#categories').val(product.idCategory.id).trigger('change');
        $('#products').val(productsAssociated).trigger('change');
        this.isCheckedDistributorActive();
        this.api.get('products/isAssociatedProduct', product.id).subscribe((isAssociatedProduct: Boolean) => {
          this.isAssociatedProduct = isAssociatedProduct;
          if (this.isAssociatedProduct) {
            $("#products").prop("disabled", true);
          }
          else {
            $("#products").prop("disabled", false);
          }
        });
        this.createModal.open(false);
      });
    });
  }

  showProduct(product: Product) {
    this.api.get('products/getProductsWithOutCurrentProduct', product.id).subscribe((productsData: Product[]) => {
      this.api.get('products/getProductsWithAsssociatedProducts', product.id).subscribe((productsAssociated: String[]) => {
        this.products = productsData;
        $('#products').empty().trigger("change");
        this.products.forEach((product: Product) => {
          var newOption = new Option(product.title, product.id, false, false);
          $('#products').append(newOption).trigger('change');
        });
        $('#products').val(null).trigger('change');
        this.title = "Detalles de producto";
        this.isEditingProduct = false;
        this.isShowingProduct = true;
        Object.keys(product).forEach(key => {
          if (this.productForm.controls[key])
            this.productForm.controls[key].setValue(product[key]);
        });
        this.productForm.controls['price'].setValue(product.price.toFixed(2));
        $('#level').val(product.level).trigger('change');
        $('#grade').val(product.grade).trigger('change');
        $('#unit').val(product.unit).trigger('change');
        $('#categories').val(product.idCategory.id).trigger('change');
        $('#products').val(productsAssociated).trigger('change');
        $("#grade").prop("disabled", true);
        $("#level").prop("disabled", true);
        $("#unit").prop("disabled", true);
        $("#categories").prop("disabled", true);
        $("#products").prop("disabled", true);
        this.isCheckedDistributorActive();
        this.createModal.open(false);
      });
    });
  }

  deleteProduct(product: Product) {
    Object.keys(product).forEach(key => {
      if (this.productForm.controls[key])
        this.productForm.controls[key].setValue(product[key]);
    });
    this.deleteModal.open();
  }

  //General functions
  isCheckedDistributorActive() {
    if (this.productForm.controls['distributorActive'].value == true) {
      $('#distributorActive').prop('checked', true);
    } else {
      $('#distributorActive').prop('checked', false);
    }
  }

  cancelProductForm() {
    this.createModal.close();
    this.deleteModal.close();
    this.productForm.reset();
    this.submitted = false;
    this.productsTable.ajax.reload();
    $("#grade").prop("disabled", true);
    $('#unit').val(null).trigger('change');
    $('#level').val(null).trigger('change');
    $('#grade').val(null).trigger('change');
    $('#categories').val(null).trigger('change');
    $("#products").val(null).trigger('change');
    $('#distributorActive').prop('checked', false);
  }

  resetModals(){
    this.productForm.reset();
    $("#grade").prop("disabled", true);
    $('#unit').val(null).trigger('change');
    $('#level').val(null).trigger('change');
    $('#grade').val(null).trigger('change');
    $('#categories').val(null).trigger('change');
    $("#products").val(null).trigger('change');
  }

  refreshProductsTable() {
    this.productsTable.ajax.reload();
  }

  resetProductsTable() {
    $('#initialDate').data('datepicker').setDate(null);
    $('#finalDate').data('datepicker').setDate(null);
    $("#search").val('');
    this.productsTable.ajax.reload();
  }

  anyCategories() : boolean{
    if (this.categories.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  checkValueActive(event: any) {
    this.productForm.controls['distributorActive'].setValue(event.currentTarget.checked);
  }

  //Api functions
  onSubmitProductForm() {
    if (this.productForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    if (this.productForm.controls['id'].value) {
      var product: Product = Object.assign({}, this.productForm.value);
      this.api.patch('products', this.productForm.controls['id'].value, product).pipe(finalize(() => { this.loading = false; })).subscribe((products: Product[]) => {
        this.cancelProductForm();
        this.alertSucces('Producto actualizado con éxito');
      }, (error) => {
        this.alertFailed(error.error.errors.join('; '));
      });

    } else {
      var product: Product = Object.assign({}, this.productForm.value);     
      this.api.post('products', product).pipe(finalize(() => { this.loading = false; })).subscribe((products: Product[]) => {
        this.cancelProductForm();
        this.alertSucces('Producto guardado con éxito');
      }, (error) => {
        this.alertFailed(error.error.errors.join('; '));
      });
    }
  }

  onDeleteProductForm() {
    this.loading = true;
    var product: Product = Object.assign({}, this.productForm.value);
    this.api.patch('products/delete', this.productForm.controls['id'].value, product).pipe(finalize(() => { this.loading = false; })).subscribe((products: Product[]) => {
      this.cancelProductForm();
      this.productsTable.ajax.reload();
      this.alertSucces('Producto eliminado con éxito');
    }, (error) => {
      this.cancelProductForm();
      this.alertFailed(error.error.errors.join('; '));
    });
  }

  //Alert funtions

  alertSucces(title) {
    $.toast({
      heading: 'Realizado',
      text: title,
      showHideTransition: 'slide',
      icon: 'success',
      loaderBg: '#f96868',
      position: 'bottom-left'
    });
  }

  alertFailed(title) {
    $.toast({
      heading: 'Alerta',
      text: title,
      showHideTransition: 'slide',
      icon: 'error',
      loaderBg: '#f2a654',
      position: 'bottom-left'
    });
  }

  get unit() {
    return this.productForm.get('unit');
  }

  get idCategory() {
    return this.productForm.get('idCategory');
  }
}