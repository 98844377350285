import { AbstractControl } from '@angular/forms';

export class PasswordMatchValidator {
    static MatchPassword(AC: AbstractControl) {
        var password = AC.get('password').value;
        var confirmPassword = AC.get('confirmPassword').value;
        if (password != confirmPassword) {
            AC.get('confirmPassword').setErrors({ MatchPassword: true });
        } else {
            return null;
        }
    }
}
