import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { ApiService } from 'src/app/_api/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalReturnComponent } from 'src/app/components/modal-return/modal-return.component';
import { DeleteModalComponent } from 'src/app/components/delete-modal/delete-modal.component';
import { CodesProducts } from 'src/app/models/codes-products';
import { Alerts } from 'src/app/utils/alerts';
import { finalize } from 'rxjs/operators';
import { Season } from 'src/app/models/season';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-products-settings',
  templateUrl: './products-settings.component.html',
  styleUrls: ['./products-settings.component.sass']
})
export class ProductsSettingsComponent implements OnInit, AfterViewInit {
  //Vars
  @ViewChild(ModalReturnComponent)
  private createModal: ModalReturnComponent;
  @ViewChild(DeleteModalComponent)
  private deleteModal: DeleteModalComponent;
  @Input('title') titleModal: string;

  private productsCodesTable: any;
  submitted = false;
  productsCodesForm: FormGroup;
  loading: boolean = false;
  title: string = "";
  currentPrefix: string = "";
  seasons: Season[] = [];


  constructor(private api: ApiService, private formBuilder: FormBuilder) {
    this.api.getAll('seasons/getAllSeasons').subscribe((seasonsData : Season[]) => {
      this.seasons = seasonsData;
    });
  }


  ngOnInit() {
    this.productsCodesForm = this.formBuilder.group({
      id: [null],
      prefix: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      description: ['', Validators.required],
      level: [null, Validators.required],
      quantity: [0, [Validators.required, Validators.min(1)]], 
      price: [0, [Validators.required, Validators.min(1)]],
      idSeason: [null, Validators.required],
      physicalLabels: [false],
      onOrder: [false]
    });
  }

  ngAfterViewInit() {
    $('#idSeason').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona la temporada',
      width: '100%',
      dropdownParent: $('#ProductsCodesModal')
    });
    $('#idSeason').val(null).trigger('change');


    $('#level').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el nivel',
      width: '100%',
      dropdownParent: $('#ProductsCodesModal')
    });
    $('#level').val(null).trigger('change');


    $('#level').on("change", () => {
      if ($('#level').val() != '') {
        var level = $('#level').val();
        this.productsCodesForm.controls['level'].setValue(level);
      }
    });

    $('#idSeason').on('change', () => {
      if ($('#idSeason').val()) {
        var currentSeason: Season = this.seasons.find((season: Season) => {
          return season.id == $('#idSeason').val();
        });
        this.productsCodesForm.controls['idSeason'].setValue(currentSeason);
      }
    });


    $('.datepicker').datepicker({
      enableOnReadonly: true,
      todayHighlight: true,
      autoclose: true,
    });

    $('#initialDate').on('changeDate', function () {
      $('#initialDate').val($('#initialDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#initialDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $("#finalDate").datepicker("setStartDate", dtFormatted);
    });

    $('#finalDate').on('changeDate', function () {
      $('#finalDate').val($('#finalDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#finalDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $("#initialDate").datepicker("setEndDate", dtFormatted);
    });


    this.productsCodesTable = $('#products-codes-table').DataTable({
      'ajax': {
        url: `${environment.apiUrl}/productsCodes`,
        data: function (data) {
          data.from = $("#initialDate").val();
          data.to = $("#finalDate").val();
          data.searchData = $("#search").val();
        }

      },
      'serverSide': true,
      'searching': false,
      'paging': true,
      'lengthChange': false,
      'language': {
        'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json'
      },
      columns: [
        { data: 'prefix' },
        {
          data: 'idSeason',
          render: (data, type, row, meta) => {
            return row['idSeason']['name'];
          }
        },
        { data: 'description' },
        { data: 'level' },
        { data: 'quantity' },
        { data: 'price', render: $.fn.dataTable.render.number(',', '.', 2, '$') },
        {
          data: 'physicalLabels',
          render: function (data, type, row) {
            return data == true ? 'Sí' : 'No';
          }
        },
        {
          data: 'onOrder',
          render: function (data, type, row) {
            return data == true ? 'Sí' : 'No';
          }
        },
        {
          data: null,
          defaultContent: '',
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).prepend(
              `
                  <div class="btn-group" role="group" aria-label="Acciones">
                  <button type="button" class="btn btn-info btn-danger code-product-delete" title="Eliminar">
                  <i class="mdi mdi-delete-forever"></i></button>
                  </div>
                  `
            );
          },
          orderable: false,
          searchable: false
        }
      ],
      drawCallback: () => {
        $('.code-product-delete').on('click', (element) => {
          let codeProduct: CodesProducts = this.productsCodesTable.row($(element.currentTarget).closest('tr')).data();
          this.deleteProductCode(codeProduct);
        });
      }
    });
  }
  //Modal functions
  createProductCode() {
    this.cancelProductsCodes();
    this.productsCodesForm.controls['physicalLabels'].setValue(false);
    this.productsCodesForm.controls['onOrder'].setValue(false);
    this.createModal.open(true);
  }

  deleteProductCode(codeProduct: CodesProducts) {
    Object.keys(codeProduct).forEach(key => {
      if (this.productsCodesForm.controls[key]) 
        this.productsCodesForm.controls[key].setValue(codeProduct[key]);
    });
    this.currentPrefix = codeProduct.prefix;
    this.deleteModal.open();
  }


  //General functions
  cancelProductsCodes() {
    this.productsCodesForm.reset();
    $('#level').val(null).trigger('change');
    $('#idSeason').val(null).trigger('change');
    $('#PhysicalLabels').prop('checked', false);
    $('#OnOrder').prop('checked', false);
    this.submitted = false;
    this.createModal.close();
    this.deleteModal.close();
  }

  refreshProductsCodesTable() {
    this.productsCodesTable.ajax.reload();
  }

  resetProductsCodesTable() {
    $('#initialDate').data('datepicker').setDate(null);
    $('#finalDate').data('datepicker').setDate(null);
    $("#search").val('');
    this.productsCodesTable.ajax.reload();
  }

  onKey(event: any) { // without type info
    var value = event.target.value.toUpperCase();
    this.productsCodesForm.controls['prefix'].setValue(value);
  }

  checkValueOfPhysicalLabels(event: any) {
    this.productsCodesForm.controls['physicalLabels'].setValue(event.currentTarget.checked);
  }

  checkValueOfOnOrder(event: any) {
    this.productsCodesForm.controls['onOrder'].setValue(event.currentTarget.checked);
  }

  //Api functions
  onSubmitProductsCodesForm() {
    if (this.productsCodesForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    var code: CodesProducts = Object.assign({}, this.productsCodesForm.value);
    this.api.post('productsCodes', code).pipe(finalize(() => { this.loading = false; })).subscribe((codes: CodesProducts[]) => {
      this.cancelProductsCodes();
      this.productsCodesTable.ajax.reload();
      Alerts.AlertSuccess('Código guardado con éxito');
    }, (error) => {
      Alerts.AlertFailed(error.error.errors.join('; '));
    });
  }

  onDeleteProductsCodesForm() {
    this.loading = true;
    var codeProduct: CodesProducts = Object.assign({}, this.productsCodesForm.value);
    this.api.patch('productsCodes/delete', this.productsCodesForm.controls['id'].value, codeProduct).pipe(finalize(() => { this.loading = false; })).subscribe((productsCodes: CodesProducts[]) => {
      this.cancelProductsCodes();
      this.productsCodesTable.ajax.reload();
      Alerts.AlertSuccess('Producto eliminado con éxito');
    }, (error) => {
      this.cancelProductsCodes();
      Alerts.AlertFailed(error.error.errors.join('; '));
    });
  }
}
