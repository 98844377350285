import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Alerts } from 'src/app/utils/alerts';

@Injectable({
  providedIn: 'root'
})

export class DataService {
  private tokenUrl = '/token/generate';
  private apiUrl = 'https://api.eonwms.com/v1/client/shipment/bulk_data?date=';// https://apisandbox.eonwms.com/v1/client/shipment/bulk_data?date=
  private token: string | null = null;

  constructor(private http: HttpClient) { }

  // Método para obtener el token
  getToken(): void {
    const credentials = {
      email: 'api@bentho.com.mx',//'erika.reyna@bentho.com.mx', 
      password: 'somewordsareeasiertoguessthanothers:adeb45349d19bdb3e8a86763b9a4564397bad72599d6224fc4aceb28ad134d63'//'caminitodelaescuela:7da5c47525673fbe821aa93d08d1bfa912a39629a7882d0899ab734e2f58de9c'
    };

    //this.http.post<{ token: string }>(this.tokenUrl, credentials).subscribe( //en produccion 
    this.http.post<{ token: string }>('https://api.eonwms.com/token/generate', credentials).subscribe( 
      response => {
        this.token = response.token;
        //console.log('Token obtained:', this.token);
      },
      error => {
        console.error('Error fetching token:', error);
      }
    );
  }

  // Método para obtener los datos usando el token
  getJson(date: string): void {
    if (!this.token) {
      console.error('No token available. Please obtain the token first.');
      Alerts.AlertFailed('No hay token de acceso.');
      return;
    }

    const url = `${this.apiUrl}${date}`;
    //console.log("url:",url);
    //console.log("Token:",this.token);
    //const headers = new HttpHeaders().set('Content-Type','application/json').set('Accept','application/json').set('Authorization', `Bearer ${this.token}`);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${this.token}`,
      'X-Skip-Interceptor': 'true'
    });
    //console.log("Headers",headers);
    this.http.get(url, { headers }).subscribe(
      response => {
        Alerts.AlertSuccess('La orden esta en consola.');
        console.log("Orden:",response);
      },
      error => {
        console.error('Error fetching data:', error);
      }
    );
  }
}
