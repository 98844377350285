import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private userSource: BehaviorSubject<User>;
  user$: Observable<User>;

  constructor(
    private http: HttpClient,
    private router: Router
    ) {
    this.userSource = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('user')));
    this.user$ = this.userSource.asObservable();
  }

  login(username: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}/auth/login`, { usernameOrEmail: username, password: password })
      .pipe(map(response => {
        if (response.user && response.accessToken) {
          let user: User = {
            id: response.user.id,
            name: response.user.name,
            username: response.user.username,
            email: response.user.email,
            token: response.accessToken,
            role: response.user.role,
            idUser: response.user.idUser
          }
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          sessionStorage.setItem('user', JSON.stringify(user));
          this.userSource.next(user);
        }
        return response;
      }));
  }

  logout() {
    sessionStorage.removeItem('user');
    this.userSource.next(null);
    this.router.navigate(['/login']);
  }

  public get user(): User {
    return this.userSource.value;
  }
}