import { Distributor } from './distributor';
import { User } from './user';
import { Order } from './order';

export class Distribution{
    id: any;
    code: string;
    idOrder: Order;
    total: number;
    statusDistribution: string;
    status: boolean;
    createdAt: string;
    updatedAt: string;
}