import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Console } from '@angular/core/src/console';
import statesList from 'src/assets/js/statelist.json';
import townsList from 'src/assets/js/townlist.json';
import { Distributor } from 'src/app/models/distributor';
import { ApiService } from 'src/app/_api/api.service';
import { finalize } from 'rxjs/operators';
import { User } from 'src/app/models';
import { DatePipe } from '@angular/common';
import { PasswordMatchValidator } from 'src/app/utils/password-match-validator';
import { ValidationState } from 'src/app/utils/validation-state';
import { ValidationTown } from 'src/app/utils/validation-town';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.sass']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  states: any;
  towns: any;
  nowDate: any;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private router: Router
  ) {
    this.states = statesList;
    this.registerForm = this.formBuilder.group({
      code: [''],
      name: ['', Validators.required],
      paternalSurname: ['', Validators.required],
      maternalSurname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      businessName: ['', Validators.required],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      state: [null],
      town: [null],
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required]
    }, {
      validators: [PasswordMatchValidator.MatchPassword, ValidationState.ValidateState, ValidationTown.ValidateTown]
    })
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    $('#states_code').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el estado',
      width: '100%',
      dropdownParent: $('#RegisterModal')
    });
    $('#states_code').val(null).trigger('change');

    $('#towns_code').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el municipio',
      width: '100%',
      dropdownParent: $('#RegisterModal'),
      disabled: true
    });
    $('#towns_code').val(null).trigger('change');

    $('#states_code').on("change", () => {
      if ($('#states_code').val() != '') {
        var currentState = $('#states_code').val();
        townsList.forEach(element => {
          if (currentState == element['code']) {
            this.towns = element['towns'];
          }
        });
        $('#towns_code').empty().trigger("change");
        this.towns.forEach((town: any) => {
          var newOption = new Option(town, town, false, false);
          $('#towns_code').append(newOption).trigger('change');
        });
        $('#towns_code').val(null).trigger("change");
        $("#towns_code").prop("disabled", false);
        this.registerForm.controls['state'].setValue(currentState);
      }
    });

    $('#towns_code').on("change", () => {
      if ($('#towns_code').val() != '') {
        var currentTown = $('#towns_code').val();
        this.registerForm.controls['town'].setValue(currentTown);
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.loading = true;
    this.registerForm.controls['code'].setValue(this.currentCode());
    var distributor: Distributor = Object.assign({}, this.registerForm.value);
    var formData: FormData = new FormData();
    formData.append('name', this.registerForm.controls['name'].value + ' ' + this.registerForm.controls['paternalSurname'].value + ' ' + this.registerForm.controls['maternalSurname'].value);
    formData.append('username', this.registerForm.controls['username'].value);
    formData.append('password', this.registerForm.controls['password'].value);
    formData.append('isAdmin', "false");
    formData.append('email', this.registerForm.controls['email'].value);
    this.api.post('users/distributor', formData).pipe(finalize(() => {  })).subscribe((usersData: User[]) => {
      this.api.post('distributors/register', distributor).pipe(finalize(() => {  })).subscribe((distributors: Distributor[]) => {
        this.loading = false;
        this.cancelDistributorForm();
        Swal.fire({
          icon: 'success',
          title: '¡Registro creado exitosamente!',
          text: 'Tu registro estará siendo evaluado por el administrador del sitio. \n Recibirás un correo electrónico una vez que haya sido autorizado el registro.'
        }).then((result) => {
          if (result.value) {
            this.router.navigate(['']);
          }
        });
      }, (error) => {
        this.loading = false;
        this.alertFailed(error.error.errors.join('; '));
      });
    }, (error) => {
      this.loading = false;
      this.alertFailed(error.error.errors.join('; '));
    });
  }

  get state() {
    return this.registerForm.get('state');
  }

  get town() {
    return this.registerForm.get('town');
  }

  currentYear(): any {
    return new Date().getFullYear();
  }

  cancelDistributorForm() {
    this.registerForm.reset();
    this.submitted = false;
    $("#towns_code").prop("disabled", true);
    $('#towns_code').val(null).trigger('change');
    $('#states_code').val(null).trigger('change');
    $('#state_control').find(".select2-selection").removeClass("select2-is-invalid");
    $('#town_control').find(".select2-selection").removeClass("select2-is-invalid");
    $("#states_code").prop("disabled", false);
  }

  currentCode(): any {
    var date = new Date();
    this.nowDate = new DatePipe('en-US').transform(date.toISOString().toLocaleString().slice(0, 16), 'dd-MM-yyyy hh:mm:ss', 'GTM-6');

    if (this.registerForm.controls['name'].value != null && this.registerForm.controls['name'].value.length > 1 && this.registerForm.controls['paternalSurname'].value != null && this.registerForm.controls['paternalSurname'].value.length > 1 && this.registerForm.controls['maternalSurname'].value != null && this.registerForm.controls['maternalSurname'].value.length > 1) {
      var tmpCode = 'D' + this.registerForm.controls['name'].value.toString().substr(0, 2).toUpperCase() +
        this.nowDate.toString().substr(0, 2) + this.nowDate.toString().substr(3, 2) +
        ("0" + date.getHours()).slice(-2) + ("0" + date.getMinutes()).slice(-2) +
        ("0" + date.getSeconds()).slice(-2);
      this.registerForm.controls['code'].setValue(tmpCode);
      return tmpCode;
    }
    else {
      return "";
    }
  }

  currentUserName() {
    if (this.registerForm.controls['name'].value != null && this.registerForm.controls['name'].value.length > 1 && this.registerForm.controls['paternalSurname'].value != null && this.registerForm.controls['paternalSurname'].value.length > 1 && this.registerForm.controls['maternalSurname'].value != null && this.registerForm.controls['maternalSurname'].value.length > 1) {
      var tmpCode = this.registerForm.controls['name'].value.toString().toUpperCase() + '_' + this.registerForm.controls['paternalSurname'].value.toString().toUpperCase() + '_' + this.registerForm.controls['maternalSurname'].value.toString().toUpperCase();
      var normalizeTmpCode = tmpCode.normalize('NFD').replace(/[\u0300-\u036f\s]/g, '');
      this.registerForm.controls['username'].setValue(normalizeTmpCode);
      return normalizeTmpCode;
    }
    else {
      return "";
    }
  }

  alertSuccess(title) {
    $.toast({
      heading: 'Registro guardado con éxito',
      text: title,
      showHideTransition: 'slide',
      icon: 'success',
      loaderBg: '#f96868',
      position: 'bottom-left'
    });
  }

  alertFailed(title) {
    $.toast({
      heading: 'Error',
      text: title,
      showHideTransition: 'slide',
      icon: 'error',
      loaderBg: '#f2a654',
      position: 'bottom-left'
    });
  }

  capitalizeSentence(string) {
    return string.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
