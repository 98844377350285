import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/_api/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalReturnComponent } from 'src/app/components/modal-return/modal-return.component';
import { finalize } from 'rxjs/operators';
import { Address } from 'src/app/models/address';
import { DeleteModalComponent } from 'src/app/components/delete-modal/delete-modal.component';
import { environment } from 'src/environments/environment';
import statesList from 'src/assets/js/statelist.json';
import townsList from 'src/assets/js/townlist.json';
declare var $: any;

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.sass']
})

export class AddressesComponent implements OnInit, AfterViewInit {
  @ViewChild(ModalReturnComponent)
  private createModal: ModalReturnComponent;
  @ViewChild(DeleteModalComponent)
  private deleteModal: DeleteModalComponent;
  dataShippingAddress: Array<string> = [];
  dataGeneralAddress: Array<string> = [];
  dataInvoiceAddress: Array<string> = [];
  copyAddress: boolean = false;
  private addressesTable: any;
  addressForm: FormGroup;
  isShowingAddress = false;
  loading: boolean = false;
  submitted = false;
  states: any;
  towns: any;

  constructor(private formBuilder: FormBuilder, private api: ApiService) {
    this.states = statesList;
  }

  ngOnInit() {
    this.addressForm = this.formBuilder.group({
      id: [null],
      addressName: ['', Validators.required],
      freight: ['', Validators.required],
      freightName: ['', Validators.required],
      freightPaternalSurname: ['', Validators.required],
      freightMaternalSurname: ['', Validators.required],
      freightPhone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      shippingAddress: ['', Validators.required],
      shippingColony: ['', Validators.required],
      shippingZip: ['', Validators.required],
      shippingTown: [null, Validators.required],
      shippingState: [null, Validators.required],
      shippingPhone: [''],
      shippingWay: ['', Validators.required],
      invoiceAddress: ['', Validators.required],
      invoiceColony: ['', Validators.required],
      invoiceZip: ['', Validators.required],
      businessName: ['', Validators.required],
      rfc: ['', Validators.required]
    });
  }

  ngAfterViewInit(): void {
    $('#states_code').select2({ width: '100%', theme: 'bootstrap', dropdownParent: $('#AddressModal'), placeholder: 'Selecciona el estado' });

    $('#states_code').val(null).trigger('change');

    $('#towns_code').select2({ width: '100%', disabled: true, theme: "bootstrap", dropdownParent: $('#AddressModal'), placeholder: 'Selecciona el municipio' });

    $('#towns_code').val(null).trigger('change');

    $('#shipping_way').select2({ width: '100%', theme: 'bootstrap', dropdownParent: $('#AddressModal'), placeholder: 'Selecciona la forma de envío' });

    $('#states_code').on('change', () => {
      if ($('#states_code').val() != '') {
        var currentState = $('#states_code').val();
        townsList.forEach(element => {
          if (currentState == element['code']) {
            this.towns = element['towns'];
          }
        });

        $('#towns_code').empty().trigger('change');

        this.towns.forEach((town: any) => {
          var newOption = new Option(town, town, false, false);
          $('#towns_code').append(newOption).trigger('change');
        });

        $('#towns_code').val(null).trigger('change');
        $('#towns_code').prop('disabled', false);

        this.addressForm.controls['shippingState'].setValue(currentState);
        this.addressForm.controls['shippingTown'].setValue('');
      }
    });

    $('#towns_code').on('change', () => {
      if ($('#towns_code').val() != '') {
        var currentTown = $('#towns_code').val();
        this.addressForm.controls['shippingTown'].setValue(currentTown);
      }
    });

    $('#shipping_way').on('change', () => {
      if ($('#shipping_way').val() != '') {
        var currentShippingWay = $('#shipping_way').val();
        this.addressForm.controls['shippingWay'].setValue(currentShippingWay);
      }
    });

    this.addressesTable = $('#addresses-table').DataTable({
      'ajax': { url: `${environment.apiUrl}/addresses` },
      'serverSide': true,
      'searching': false,
      'paging': true,
      'lengthChange': false,
      'language': { 'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json' },
      columns: [{ data: 'id' }, { data: 'addressName' },
      {
        data: 'shippingAddress',
        render: (data, type, row, meta) => {
          return row['shippingAddress'] + ', ' + row['shippingColony'] + ', ' + row['shippingZip'] + ', ' + row['shippingTown'] + ', ' + row['shippingState'];
        }
      },
      {
        data: null,
        orderable: false,
        searchable: false,
        defaultContent: '',
        createdCell: function (td, cellData, rowData, row, col) {
          $(td).prepend(
            `<div class="btn-group" role="group" aria-label="Acciones">
                <button type="button" class="btn btn-warning address-edit" title="Editar">
                  <i class="mdi mdi-lead-pencil text-white"></i>
                </button>
                <button type="button" class="btn btn-danger address-delete" title="Eliminar">
                  <i class="mdi mdi-delete-forever"></i>
                </button>
              </div>`
          );
        }
      }
      ],
      columnDefs: [{ 'targets': [0], 'visible': false, 'searchable': false }],
      drawCallback: () => {
        $('.address-edit').on('click', (element) => {
          let address: Address = this.addressesTable.row($(element.currentTarget).closest('tr')).data();
          this.editAddress(address);
        });
        $('.address-delete').on('click', (element) => {
          let address: Address = this.addressesTable.row($(element.currentTarget).closest('tr')).data();
          this.deleteAddress(address);
        });
      }
    });
  }

  cancelAddressForm() {
    $('#states_code').val(null).trigger('change');
    $('#towns_code').val(null).trigger('change');
    $('#shipping_way').val(null).trigger('change');
    $('#copy-checkbox').prop('checked', false);
    this.createModal.close();
    this.deleteModal.close();
    this.addressForm.reset();
    this.addressesTable.ajax.reload();
    this.submitted = false;
  }

  createAddress() {
    this.createModal.open(true);
    this.isShowingAddress = false;
  }

  editAddress(address: Address) {
    this.isShowingAddress = true;
    Object.keys(address).forEach(key => {
      if (this.addressForm.controls[key]) {
        this.addressForm.controls[key].setValue(address[key]);
      }
    });
    $('#states_code').val(address.shippingState).trigger('change');
    $('#towns_code').val(address.shippingTown).trigger('change');
    $('#shipping_way').val(address.shippingWay).trigger('change');
    this.createModal.open(false);
  }

  deleteAddress(address: Address) {
    Object.keys(address).forEach(key => {
      if (this.addressForm.controls[key])
        this.addressForm.controls[key].setValue(address[key]);
    });
    this.deleteModal.open();
  }

  onSubmitAddressForm() {
    if (this.addressForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    var address: Address = Object.assign({}, this.addressForm.value);
    if (this.addressForm.controls['id'].value) {
      this.api.patch('addresses', this.addressForm.controls['id'].value, address).pipe(finalize(() => { this.loading = false; })).subscribe((addresses: Address[]) => {
        this.cancelAddressForm();
        this.alertSuccess('Dirección actualizada con éxito');
      }, (error) => {
        this.alertFailed(error.error.errors.join('; '));
      });
    } else {
      this.api.post('addresses', address).pipe(finalize(() => { this.loading = false; })).subscribe((addresses: Address[]) => {
        this.cancelAddressForm();
        this.alertSuccess('Dirección guardada con éxito');
      }, (error) => {
        this.alertFailed(error.error.errors.join('; '));
      });
    }
  }

  onDeleteAddressForm() {
    this.loading = true;
    var address: Address = Object.assign({}, this.addressForm.value);
    this.api.patch('addresses/delete', this.addressForm.controls['id'].value, address).pipe(finalize(() => { this.loading = false; })).subscribe((addresses: Address[]) => {
      this.cancelAddressForm();
      this.alertSuccess('Dirección eliminada con éxito');
    });
  }
  
  copyData(evt) {
    this.copyAddress = evt.target.checked;
    if (this.copyAddress) {
      this.currentShippingAddress();
      this.currentShippingColony();
      this.currentShippingZip();
    } else {
      this.addressForm.controls['invoiceAddress'].setValue('');
      this.addressForm.controls['invoiceColony'].setValue('');
      this.addressForm.controls['invoiceZip'].setValue('');
    }
  }

  currentShippingAddress() {
    if (this.copyAddress && this.addressForm.controls['shippingAddress'].value != null && this.addressForm.controls['shippingAddress'].value.length > 1) {
      this.addressForm.controls['invoiceAddress'].setValue(this.addressForm.controls['shippingAddress'].value.toString());
    }
  }

  currentShippingColony() {
    if (this.copyAddress && this.addressForm.controls['shippingColony'].value != null && this.addressForm.controls['shippingColony'].value.length > 1) {
      this.addressForm.controls['invoiceColony'].setValue(this.addressForm.controls['shippingColony'].value.toString());
    }
  }

  currentShippingZip() {
    if (this.copyAddress && this.addressForm.controls['shippingZip'].value != null && this.addressForm.controls['shippingZip'].value.length > 1) {
      this.addressForm.controls['invoiceZip'].setValue(this.addressForm.controls['shippingZip'].value.toString());
    }
  }


  alertSuccess(title) {
    $.toast({
      heading: 'Realizado',
      text: title,
      showHideTransition: 'slide',
      icon: 'success',
      loaderBg: '#f96868',
      position: 'bottom-left'
    });
  }

  alertFailed(title) {
    $.toast({
      heading: 'Alerta',
      text: title,
      showHideTransition: 'slide',
      icon: 'error',
      loaderBg: '#f2a654',
      position: 'bottom-left'
    });
  }

  capitalizeSentence(string) {
    return string.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
