import { AbstractControl } from '@angular/forms';

export class ValidationTown {
    static ValidateTown(AC: AbstractControl) {
      var state = AC.get('town').value;
      if (state == null) {
        AC.get('town').setErrors({ ValidateTown: true });
      } else {
        return null;
      }
    }
  }