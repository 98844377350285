import { Component, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef, Input } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.sass']
})
export class DeleteModalComponent implements OnInit {
  @ViewChild('modal', {read: ViewContainerRef}) modalContainer;
  @Output() onClose = new EventEmitter<void>();
  @Input() deletingTitle: string;
  @Input() loading = false;

  constructor() { }

  ngOnInit() {
  }

  open() {
    $(this.modalContainer.element.nativeElement).modal('show');
  }

  close() {
    $(this.modalContainer.element.nativeElement).modal('hide');
  }

  dismiss() {
    this.onClose.emit();
  }

}
