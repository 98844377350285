import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ModalReturnComponent } from 'src/app/components/modal-return/modal-return.component';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ApiService } from 'src/app/_api/api.service';
import { Distributor } from 'src/app/models/distributor';
import { Season } from 'src/app/models/season';
import { CodesProducts } from 'src/app/models/codes-products';
import { Product } from 'src/app/models/products';
import { Alerts } from 'src/app/utils/alerts';
import { ProductPrices } from 'src/app/models/product-prices';
import { finalize } from 'rxjs/internal/operators/finalize';
import { DeleteModalComponent } from 'src/app/components/delete-modal/delete-modal.component';
import statesList from 'src/assets/js/statelist.json';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-distributor-prices',
  templateUrl: './distributor-prices.component.html',
  styleUrls: ['./distributor-prices.component.sass']
})
export class DistributorPricesComponent implements OnInit, AfterViewInit {
  @ViewChild('create')
  private createModal: ModalReturnComponent;
  @ViewChild('delete')
  private deleteModal: DeleteModalComponent;
  productsCodes: CodesProducts[] = [];
  productsCodesFormArray: FormArray;
  distributors: Distributor[] = [];
  codeProductsPrices: any[] = [];
  private distributorsTable: any;
  productsFormArray: FormArray;
  productsPrices: any[] = [];
  products: Product[] = [];
  notReady2Submit = false;
  seasons: Season[] = [];
  deleteForm: FormGroup;
  pricesForm: FormGroup;
  index2Remove = null;
  createForm = false;
  selected_1 = false;
  selected_2 = false;
  submitted = false;
  deleteRoute = '';
  editForm = false;
  productName = '';
  showForm = false;
  modalTitle = '';
  loading = false;
  states: any;

  constructor(private changes: ChangeDetectorRef, private formBuilder: FormBuilder, private api: ApiService) {
    this.states = statesList;
    this.api.getAll('distributors/getAll').subscribe((data: Distributor[]) => {
      this.distributors = data;
    });
    this.api.getAll('seasons/getAll').subscribe((data: Season[]) => {
      this.seasons = data;
    });
    this.api.getAll('productsCodes/getAll').subscribe((data: CodesProducts[]) => {
      this.productsCodes = data;
    });
    this.api.getAll('products/getAll').subscribe((data: Product[]) => {
      this.products = data;
    });
  }

  ngOnInit(): void {
    this.pricesForm = this.formBuilder.group({
      id: [null],
      distributorId: [null, Validators.required],
      seasonId: [null, Validators.required],
      productsCodes: this.formBuilder.array([]),
      products: this.formBuilder.array([])
    });
    this.productsFormArray = this.pricesForm.get('products') as FormArray;
    this.productsCodesFormArray = this.pricesForm.get('productsCodes') as FormArray;
    this.deleteForm = this.formBuilder.group({
      distributorId: [null, Validators.required],
      seasonId: [null, Validators.required],
      productId: [null, Validators.required]
    });
  }

  ngAfterViewInit(): void {
    $('#distributorId').select2({ width: '100%', theme: 'bootstrap', dropdownParent: $('#PricesModal'), placeholder: 'Selecciona un distribuidor' });

    $('#distributorId').on('change', () => {
      if ($('#distributorId').val()) {
        var currentDistributor: Distributor = this.distributors.find((distributor: Distributor) => {
          this.pricesForm.controls['distributorId'].setValue(distributor.id);
          $('#distributorCode').val(distributor.code);
          return distributor.id == $('#distributorId').val();
        });
        if (this.createForm) {
          this.api.getAll('seasons/getAll').subscribe((data_1: Season[]) => {
            this.api.get('distributorsPrices/getSeasons', currentDistributor.id).pipe(finalize(() => {
              this.changes.detectChanges();
            })).subscribe((data_2: Season[]) => {
              this.seasons = data_1;
              data_2.forEach(element => {
                var index = this.seasons.findIndex(x => x.id === element.id);
                if (index != -1) {
                    this.seasons.splice(index, 1);
                }
              });
              this.pricesForm.controls['seasonId'].setValue(null);
            });
          });
        }
      }
    });

    $('#seasonId').select2({ width: '100%', theme: 'bootstrap', dropdownParent: $('#PricesModal'), placeholder: 'Selecciona una temporada' });

    $('#seasonId').on('change', () => {
      if ($('#seasonId').val()) {
        var currentSeason: Season = this.seasons.find((season: Season) => {
          this.pricesForm.controls['seasonId'].setValue(season.id);
          return season.id == $('#seasonId').val();
        });
      }
    });

    $('#productsCodes,#products').select2({ width: '100%', theme: 'bootstrap', dropdownParent: $('#PricesModal'), placeholder: 'Selecciona un producto' });

    $('#productsCodes').on('change', () => {
      if ($('#productsCodes').val()) {
        this.selected_1 = true;
      } else {
        this.selected_1 = false;
      }
    });

    $('#products').on('change', () => {
      if ($('#products').val()) {
        this.selected_2 = true;
      } else {
        this.selected_2 = false;
      }
    });

    this.distributorsTable = $('#distributors-table').DataTable({
      'ajax': {
        url: `${environment.apiUrl}/distributorsPrices`,
        data: function (data) {
          data.name = $('#name').val();
        }
      },
      'serverSide': true,
      'searching': false,
      'paging': true,
      'pageLength': 10,
      'lengthChange': false,
      'language': {
        'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json'
      },
      columns: [
        {
          data: 'id',
          orderable: false,
          defaultContent: '',
          className: 'details-control',
          render: function (data, type, row, meta) {
            return '';
          }
        },
        { data: 'id', visible: false },
        { data: 'code' },
        {
          data: 'name',
          render: (data, type, row, meta) => {
            return row['name'] + ' ' + row['paternalSurname'] + ' ' + row['maternalSurname'];
          }
        },
        {
          data: 'state',
          render: (data, type, row, meta) => {
            var state = this.states.find((element) => { return element.code == row['state']; });
            return state.name.replace(/\w\S*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
          }
        }
      ],
      drawCallback: () => {
        $('.distributor-add').on('click', (element) => {
        });
      }
    });

    $('#distributors-table tbody').on('click', 'td.details-control', (event) => {
      var table = $('#distributors-table').DataTable();
      var tr = $(event.currentTarget).closest('tr');
      var row = table.row(tr);
      if (row.child.isShown()) {
        row.child.hide();
        tr.removeClass('shown');
      } else {
        this.api.get('distributorsPrices/getSeasons', row.data().id).subscribe((seasons: Season[]) => {
          var newTable = `<table border="0" cellpadding="5" cellspacing="0" width="100%">`;
          for (let index = 0; index < seasons.length; index++) {
            var style = ``;
            if (index == 0) {
              style = `border-top: none;`;
            }
            var newTr =
              `<tr>
                <td style="` + style + ` text-align: left;">` + seasons[index].name + `</td>
                <td style="` + style + ` text-align: left;">
                  <input type="hidden" class="distributor-id" value="` + row.data().id + `">
                  <input type="hidden" class="season-id" value="` + seasons[index].id + `">
                  <div class="btn-group" role="group" aria-label="Acciones" style="float: right;" align="right">
                    <button type="button" class="btn btn-info prices-details" title="Ver detalles">
                      <i class="mdi mdi-plus text-white"></i>
                    </button>
                    <button type="button" class="btn btn-warning prices-edit" title="Editar">
                      <i class="mdi mdi-lead-pencil text-white"></i>
                    </button>
                  </div>
                </td>
              </tr>`;
            newTable += newTr;
          }
          newTable += `</table>`;
          row.child(newTable).show();
          tr.addClass('shown');
        });
      }
    });

    $(document).on('click', '.prices-details', (event) => {
      var distributor = $(event.currentTarget).closest('td').find('input.distributor-id').val();
      var season = $(event.currentTarget).closest('td').find('input.season-id').val();
      this.showPrices4Distributor(distributor, season);
      this.createModal.open(false);
    });

    $(document).on('click', '.prices-edit', (event) => {
      var distributor = $(event.currentTarget).closest('td').find('input.distributor-id').val();
      var season = $(event.currentTarget).closest('td').find('input.season-id').val();
      this.editPrices4Distributor(distributor, season);
      this.createModal.open(false);
    });
  }

  createPrices4Distributor(): void {
    this.createForm = true;
    this.createModal.open(true);
  }

  showPrices4Distributor(distributor: number, season: number): void {
    this.showForm = true;
    this.modalTitle = 'Precios por Distribuidor';
    $('#seasonId').val(season).trigger('change');
    $('#distributorId').val(distributor).trigger('change');
    this.api.get('distributorsPrices/getCodesProductsPrices/' + distributor, season).subscribe((productsCodes: ProductPrices[]) => {
      this.api.get('distributorsPrices/getProductsPrices/' + distributor, season).pipe(finalize(() => {
        this.changes.detectChanges();
      })).subscribe((products: ProductPrices[]) => {
        this.codeProductsPrices = productsCodes;
        this.productsPrices = products;
        this.codeProductsPrices.forEach(element => {
          this.productsCodesFormArray.push(this.formBuilder.group({
            id: element.id,
            product: element.product,
            minimum: '' + element.minimumPrice,
            maximum: '' + element.maximumPrice,
            counted: '' + element.countedPrice
          }));
        });
        this.productsPrices.forEach(element => {
          this.productsFormArray.push(this.formBuilder.group({
            id: element.id,
            product: element.product,
            minimum: '' + element.minimumPrice,
            maximum: '' + element.maximumPrice,
            counted: '' + element.countedPrice
          }));
        });
      });
    });
  }

  editPrices4Distributor(distributor: number, season: number): void {
    this.editForm = true;
    this.modalTitle = 'Editar Precios por Distribuidor';
    $('#seasonId').val(season).trigger('change');
    $('#distributorId').val(distributor).trigger('change');
    this.api.get('distributorsPrices/getCodesProductsPrices/' + distributor, season).subscribe((productsCodes: ProductPrices[]) => {
      this.api.get('distributorsPrices/getProductsPrices/' + distributor, season).pipe(finalize(() => {
        this.changes.detectChanges();
      })).subscribe((products: ProductPrices[]) => {
        this.codeProductsPrices = productsCodes;
        this.productsPrices = products;
        this.codeProductsPrices.forEach(element => {
          this.productsCodesFormArray.push(this.formBuilder.group({
            id: element.id,
            product: element.product,
            minimum: '' + element.minimumPrice,
            maximum: '' + element.maximumPrice,
            counted: '' + element.countedPrice
          }));
        });
        this.productsPrices.forEach(element => {
          this.productsFormArray.push(this.formBuilder.group({
            id: element.id,
            product: element.product,
            minimum: '' + element.minimumPrice,
            maximum: '' + element.maximumPrice,
            counted: '' + element.countedPrice
          }));
        });
      });
    });
  }

  /* Form Functions */
  onSubmitPricesForm(): void {
    if (this.pricesForm.invalid || (this.productsCodesFormArray.length <= 0 && this.productsFormArray.length <= 0)) {
      this.submitted = true;
      if (this.productsCodesFormArray.length <= 0 && this.productsFormArray.length <= 0) {
        this.notReady2Submit = true;
      }
      return;
    }
    this.loading = true;
    var productsCodes: any = [];
    var products: any = [];
    for (var i = 0; i < this.codeProductsPrices.length; i++) {
      productsCodes.push(Object.assign({}, this.productsCodesFormArray.at(i).value));
    }
    for (var i = 0; i < this.productsPrices.length; i++) {
      products.push(Object.assign({}, this.productsFormArray.at(i).value));
    }
    var formData: FormData = new FormData();
    formData.append('distributorId', this.pricesForm.controls['distributorId'].value);
    formData.append('seasonId', this.pricesForm.controls['seasonId'].value);
    formData.append('products', JSON.stringify(products));
    formData.append('productsCode', JSON.stringify(productsCodes));
    if (this.editForm) {
      this.api.post('distributorsPrices/updatePrices', formData).pipe(finalize(() => {
        this.loading = false;
      })).subscribe((_data: any) => {
        this.cancelForm();
        this.distributorsTable.ajax.reload();
        Alerts.AlertSuccess('Los precios han sido actualizados');
      }, (error) => {
        Alerts.AlertFailed(error.error.errors.join('; '));
      });
    } else {
      this.api.post('distributorsPrices/storePrices', formData).pipe(finalize(() => {
        this.loading = false;
      })).subscribe((_data: any) => {
        this.cancelForm();
        this.distributorsTable.ajax.reload();
        Alerts.AlertSuccess('Los precios han sido registrados');
      }, (error) => {
        Alerts.AlertFailed(error.error.errors.join('; '));
      });
    }
  }

  onSubmitDeletePricesForm(): void {
    this.loading = true;
    var formData: FormData = new FormData();
    formData.append('seasonId', this.deleteForm.controls['seasonId'].value);
    formData.append('productId', this.deleteForm.controls['productId'].value);
    formData.append('distributorId', this.deleteForm.controls['distributorId'].value);
    if (this.deleteRoute == 'productscodes') {
      this.api.post('distributorsPrices/deleteCodeProductPrices', formData).pipe(finalize(() => {
        this.loading = false;
        if (this.productsCodesFormArray.length <= 0 && this.productsFormArray.length <= 0) {
          this.cancelForm();
        }
        this.cancelDeleteForm();
        this.distributorsTable.ajax.reload();
      })).subscribe((_data: any) => {
        this.productsCodesFormArray.removeAt(this.index2Remove);
        this.codeProductsPrices.splice(this.index2Remove, 1);
        Alerts.AlertSuccess('Los precios han sido eliminados');
      }, (error) => {
        Alerts.AlertFailed(error.error.errors.join('; '));
      });
    } else if (this.deleteRoute == 'products') {
      this.api.post('distributorsPrices/deleteProductPrices', formData).pipe(finalize(() => {
        this.loading = false;
        if (this.productsCodesFormArray.length <= 0 && this.productsFormArray.length <= 0) {
          this.cancelForm();
        }
        this.cancelDeleteForm();
        this.distributorsTable.ajax.reload();
      })).subscribe((_data: any) => {
        this.productsFormArray.removeAt(this.index2Remove);
        this.productsPrices.splice(this.index2Remove, 1);
        Alerts.AlertSuccess('Los precios han sido eliminados');
      }, (error) => {
        Alerts.AlertFailed(error.error.errors.join('; '));
      });
    }
  }

  /* General Functions */
  cancelForm(): void {
    this.createModal.close();
    this.pricesForm.reset();
    this.editForm = false;
    this.productName = '';
    this.showForm = false;
    this.createForm = false;
    this.submitted = false;
    this.notReady2Submit = false;
    while (this.productsFormArray.length !== 0) {
      this.productsFormArray.removeAt(0);
    }
    this.productsPrices = [];
    while (this.productsCodesFormArray.length !== 0) {
      this.productsCodesFormArray.removeAt(0);
    }
    this.codeProductsPrices = [];
    this.api.getAll('seasons/getAll').subscribe((seasons: Season[]) => { this.seasons = seasons; });
    $('#distributorId,#seasonId,#productsCodes,#products').val(null).trigger('change');
    $('#distributorCode').val('');
    $('#collapse-1').collapse('hide');
    $('#collapse-2').collapse('hide');
    $('#collapse-3').collapse('hide');
    $('.modal-backdrop').remove();
  }

  cancelDeleteForm(): void {
    this.deleteModal.close();
    this.deleteForm.reset();
    this.deleteRoute = '';
    this.index2Remove = null;
  }

  addProductCode():void {
    var includes = false;
    var codeProductPrice = new ProductPrices();
    codeProductPrice.product = $('#productsCodes option:selected').text();
    this.codeProductsPrices.forEach((product: ProductPrices) => {
      if (codeProductPrice.product == product.product) {
        includes = true;
        Alerts.AlertFailed('El producto está incluido en la lista');
      }
    });
    if (!includes) {
      this.codeProductsPrices.push(codeProductPrice);
      this.productsCodesFormArray.push(this.formBuilder.group({
        id: parseInt($('#productsCodes').val(), 10),
        product: codeProductPrice.product,
        minimum: ['0.00', [Validators.required, Validators.min(0)]],
        maximum: ['0.00', [Validators.required, Validators.min(0)]],
        counted: ['0.00', [Validators.required, Validators.min(0)]]
      }));
      Alerts.AlertInfo('El producto fue agregado a la lista');
    }
    if (this.productsCodesFormArray.length <= 0 && this.productsFormArray.length <= 0) {
      this.notReady2Submit = true;
    } else {
      this.notReady2Submit = false;
    }
    $('#productsCodes').val(null).trigger('change');
  }

  addProduct(): void {
    var includes = false;
    var productPrice = new ProductPrices();
    productPrice.product = $('#products option:selected').text();
    this.productsPrices.forEach((product: ProductPrices) => {
      if (productPrice.product == product.product) {
        includes = true;
        Alerts.AlertFailed('El producto está incluido en la lista');
      }
    });
    if (!includes) {
      this.productsPrices.push(productPrice);
      this.productsFormArray.push(this.formBuilder.group({
        id: parseInt($('#products').val(), 10),
        product: productPrice.product,
        minimum: ['0.00', [Validators.required, Validators.min(0)]],
        maximum: ['0.00', [Validators.required, Validators.min(0)]],
        counted: ['0.00', [Validators.required, Validators.min(0)]]
      }));
      Alerts.AlertInfo('El producto fue agregado a la lista');
    }
    if (this.productsCodesFormArray.length <= 0 && this.productsFormArray.length <= 0) {
      this.notReady2Submit = true;
    } else {
      this.notReady2Submit = false;
    }
    $('#products').val(null).trigger('change');
  }

  removeProductCode(index: number): void {
    this.index2Remove = index;
    var season = this.pricesForm.controls['seasonId'].value;
    var distributor = this.pricesForm.controls['distributorId'].value;
    var product = this.pricesForm.controls['productsCodes'].value[index].id;
    this.api.get('distributorsPrices/findCodeProductPrices/' + distributor + '/' + season, product).pipe(finalize(() => {
      this.changes.detectChanges();
    })).subscribe((data: any) => {
      if (data && this.editForm) {
        this.deleteRoute = 'productscodes';
        this.deleteForm.controls['productId'].setValue(product);
        this.deleteForm.controls['seasonId'].setValue(parseInt(season, 10));
        this.deleteForm.controls['distributorId'].setValue(parseInt(distributor, 10));
        this.productName = '\'' + this.pricesForm.controls['productsCodes'].value[index].product + '\'';
        this.deleteModal.open();
      } else {
        this.productsCodesFormArray.removeAt(index);
        this.codeProductsPrices.splice(index, 1);
        if (this.productsCodesFormArray.length <= 0 && this.productsFormArray.length <= 0) {
          this.notReady2Submit = true;
        } else {
          this.notReady2Submit = false;
        }
      }
    });
  }

  removeProduct(index: number): void {
    this.index2Remove = index;
    var season = this.pricesForm.controls['seasonId'].value;
    var distributor = this.pricesForm.controls['distributorId'].value;
    var product = this.pricesForm.controls['products'].value[index].id;
    this.api.get('distributorsPrices/findProductPrices/' + distributor + '/' + season, product).pipe(finalize(() => {
      this.changes.detectChanges();
    })).subscribe((data: any) => {
      if (data && this.editForm) {
        this.deleteRoute = 'products';
        this.deleteForm.controls['productId'].setValue(product);
        this.deleteForm.controls['seasonId'].setValue(parseInt(season, 10));
        this.deleteForm.controls['distributorId'].setValue(parseInt(distributor, 10));
        this.productName = '\'' + this.pricesForm.controls['products'].value[index].product + '\'';
        this.deleteModal.open();
      } else {
        this.productsFormArray.removeAt(index);
        this.productsPrices.splice(index, 1);
        if (this.productsCodesFormArray.length <= 0 && this.productsFormArray.length <= 0) {
          this.notReady2Submit = true;
        } else {
          this.notReady2Submit = false;
        }
      }
    });
  }

  refreshTable(): void {
    this.distributorsTable.ajax.reload();
  }

  resetTable(): void {
    $('#name').val('');
    this.distributorsTable.ajax.reload();
  }
}