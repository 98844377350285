import { AbstractControl } from '@angular/forms';

export class ValidationState {
    static ValidateState(AC: AbstractControl) {
      var state = AC.get('state').value;
      if (state == null) {
        AC.get('state').setErrors({ ValidateState: true });
      } else {
        return null;
      }
    }
  }