import { Component, OnInit, ViewChild } from '@angular/core';
import { CreateModalComponent } from 'src/app/components/create-modal/create-modal.component';
import { DeleteModalComponent } from 'src/app/components/delete-modal/delete-modal.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/_api/api.service';
import { PaymentForm } from 'src/app/models/payment-forms';
import { finalize } from 'rxjs/internal/operators/finalize';
import { Alerts } from 'src/app/utils/alerts';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-payment-forms',
  templateUrl: './payment-forms.component.html',
  styleUrls: ['./payment-forms.component.sass']
})
export class PaymentFormsComponent implements OnInit {
  @ViewChild(CreateModalComponent)
  private createModal: CreateModalComponent;
  @ViewChild(DeleteModalComponent)
  private deleteModal: DeleteModalComponent;
  private paymentFormsTable: any;
  paymentForm: FormGroup;
  submitted = false;
  loading = false;

  constructor(private formBuilder: FormBuilder, private api: ApiService) { }

  ngOnInit() {
    this.paymentForm = this.formBuilder.group({
      id: [null],
      paymentForm: ['', Validators.required],
    });
  }

  ngAfterViewInit(): void {
    this.paymentFormsTable = $('#forms-table').DataTable({
      'ajax': {
        url: `${environment.apiUrl}/paymentForms`,
        data: function (data) {
          data.keywords = $('#keywords').val();
        }
      },
      'paging': true,
      'pageLength': 10,
      'searching': false,
      'serverSide': true,
      'lengthChange': false,
      'language': { 'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json' },
      columns: [
        { data: 'id' },
        { data: 'paymentForm' },
        {
          data: null,
          orderable: false,
          searchable: false,
          defaultContent: '',
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).prepend(
              `<div class="btn-group" role="group" aria-label="Acciones">
                <button type="button" class="btn btn-warning payment-edit" title="Editar">
                  <i class="mdi mdi-lead-pencil text-white"></i>
                </button>
                <button type="button" class="btn btn-danger payment-delete" title="Eliminar">
                  <i class="mdi mdi-delete-forever"></i>
                </button>
              </div>`
            );
          }
        }
      ],
      columnDefs: [
        {
          'targets': [0],
          'visible': false,
          'searchable': false
        }
      ],
      drawCallback: () => {
        $('.payment-edit').on('click', (element) => {
          let paymentForm: PaymentForm = this.paymentFormsTable.row($(element.currentTarget).closest('tr')).data();
          this.editPaymentForm(paymentForm);
        });

        $('.payment-delete').on('click', (element) => {
          let paymentForm: PaymentForm = this.paymentFormsTable.row($(element.currentTarget).closest('tr')).data();
          this.deletePaymentForm(paymentForm);
        });
      }
    });
  }

  createPaymentForm() {
    this.createModal.open(true);
  }

  editPaymentForm(paymentForm: PaymentForm) {
    Object.keys(paymentForm).forEach(key => {
      if (this.paymentForm.controls[key])
        this.paymentForm.controls[key].setValue(paymentForm[key]);
    });
    this.createModal.open(false);
  }

  deletePaymentForm(paymentForm: PaymentForm) {
    Object.keys(paymentForm).forEach(key => {
      if (this.paymentForm.controls[key])
        this.paymentForm.controls[key].setValue(paymentForm[key]);
    });
    this.deleteModal.open();
  }

  cancelAllForms() {
    this.createModal.close();
    this.deleteModal.close();
    this.paymentForm.reset();
    this.loading = false;
    this.submitted = false;
    this.paymentFormsTable.ajax.reload();
  }

  onSubmitPaymentForm() {
    if (this.paymentForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    var paymentForm: PaymentForm = Object.assign({}, this.paymentForm.value);
    if (this.paymentForm.controls['id'].value) {
      this.api.patch('paymentForms', this.paymentForm.controls['id'].value, paymentForm).pipe(finalize(() => {
        this.loading = false;
      })).subscribe((paymentForm: PaymentForm[]) => {
        this.cancelAllForms();
        Alerts.AlertSuccess('La forma de pago ha sido actualizada');
      }, (error) => {
        Alerts.AlertFailed(error.error.errors.join('; '));
      });
    } else {
      this.api.post('paymentForms', paymentForm).pipe(finalize(() => {
        this.loading = false;
      })).subscribe((paymentForm: PaymentForm[]) => {
        this.cancelAllForms();
        Alerts.AlertSuccess('La forma de pago ha sido guardada');
      }, (error) => {
        Alerts.AlertFailed(error.error.errors.join('; '));
      });
    }
  }

  onSubmitDeletePaymentForm() {
    this.loading = true;
    var paymentForm: PaymentForm = Object.assign({}, this.paymentForm.value);
    this.api.patch('paymentForms/delete', this.paymentForm.controls['id'].value, paymentForm).pipe(finalize(() => { 
      this.loading = false; 
    })).subscribe((paymentForm: PaymentForm[]) => {
      this.cancelAllForms();
      Alerts.AlertSuccess('La forma de pago ha sido eliminada');
    });
  }

  refreshTable() {
    this.paymentFormsTable.ajax.reload();
  }

  resetTable() {
    $('#keywords').val('');
    this.paymentFormsTable.ajax.reload();
  }

}
