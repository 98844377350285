import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService } from 'src/app/_api/api.service';
import { Role } from 'src/app/models/role';
import { environment } from 'src/environments/environment';
declare var $ : any;

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.sass']
})
export class LogComponent implements OnInit, AfterViewInit {
  logsTable : any;
  roles : Role[] = [];
  constructor(private api: ApiService) { }

  ngOnInit() {
    this.api.getAll('roles/getAll').subscribe((rolesData : Role[]) => {
      this.roles = rolesData;
    });
  }

  ngAfterViewInit(): void {
    $('#role').select2({
      theme: "bootstrap",
      width: '100%'
    });

    $('.datepicker').datepicker({
      enableOnReadonly: true,
      todayHighlight: true,
      autoclose: true,
    });

    $('#initialDate').on('changeDate', function () {
      $('#initialDate').val($('#initialDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#initialDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $("#finalDate").datepicker("setStartDate", dtFormatted);
    });

    $('#finalDate').on('changeDate', function () {
      $('#finalDate').val($('#finalDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#finalDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $("#initialDate").datepicker("setEndDate", dtFormatted);
    });

    this.logsTable = $('#logs-table').DataTable({
      'ajax': {
        url: `${environment.apiUrl}/logs`,
        data: function (data) {
          data.from = $("#initialDate").val();
          data.to = $("#finalDate").val();
          data.searchData = $("#search").val();
          data.idRole = $("#role").val();
        }

      },
      'serverSide': true,
      'searching': false,
      'paging': true,
      'lengthChange': false,
      'language': {
        'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json'
      },
      columns: [
        { 
          data: 'user', 
          render: (data, type, row, meta) => {
            return row['user']['name'];
          } 
        },
        { data: 'user',
        render: (data, type, row, meta) => {
          return row['user']['role']['description'];
        }},
        { data: 'action' },
        { data: 'assignedCatalog' },
        { data: 'eventDescription' },
        { data: 'createdAt' }
      ],
    });
  }

  refreshLogsTable() {
    this.logsTable.ajax.reload();
  }

  resetLogsTable() {
    $('#initialDate').data('datepicker').setDate(null);
    $('#finalDate').data('datepicker').setDate(null);
    $('#role').val(null).trigger('change');
    $("#search").val('');
    this.logsTable.ajax.reload();
  }
}
