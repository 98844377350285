import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ApiService } from 'src/app/_api/api.service';
import { Product } from 'src/app/models/products';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Alerts } from 'src/app/utils/alerts';
declare var $: any;

export function ValidateProductCode(control: AbstractControl) {
  if (control.value == null) {
    return { validProductCode: true };
  }
  return null;
}

export function ValidateProductTitle(control: AbstractControl) {
  if (control.value == null) {
    return { validProductTitle: true };
  }
  return null;
}

@Component({
  selector: 'app-product-supply',
  templateUrl: './product-supply.component.html',
  styleUrls: ['./product-supply.component.sass']
})
export class ProductSupplyComponent implements OnInit, AfterViewInit {
  productSupply: FormGroup;
  products: Product[] = [];
  loading = false;
  submitted = false;

  constructor(private api: ApiService, private formBuilder: FormBuilder, private router: Router) {
    this.productSupply = this.formBuilder.group({
      id: [null],
      product_code: [null, ValidateProductCode],
      product_title: [null, ValidateProductTitle],
      current_stock: ['', Validators.required],
      new_stock: ['', [Validators.required, Validators.min(1)]]
    })
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.api.getAll('products/getAll').subscribe((productsData: Product[]) => {
      this.products = productsData;

      $('#product_code').select2({
        theme: "bootstrap",
        width: '100%'
      });
      $('#product_title').select2({
        theme: "bootstrap",
        width: '100%'
      });
      var currentProductCode: Product = this.products.find((product: Product) => {
        return product.id == $('#product_code').val();
      });
      var currentProductTitle: Product = this.products.find((product: Product) => {
        return product.id == $('#product_title').val();
      });
      this.productSupply.controls['product_code'].setValue(currentProductCode);
      this.productSupply.controls['product_title'].setValue(currentProductTitle);

      $('#product_code').on("change", () => {
        if ($('#product_code').val() != '') {
          var currentProductCode: Product = this.products.find((product: Product) => {
            return product.id == $('#product_code').val();
          });
          if ($('#product_title').val() != $('#product_code').val()) {
            $('#product_title').val(currentProductCode.id).change();
          }
          this.productSupply.controls['product_code'].setValue(currentProductCode);
          this.productSupply.controls['product_title'].setValue(currentProductCode);
          this.productSupply.controls['current_stock'].setValue(currentProductCode.stock);
          this.productSupply.controls['id'].setValue(currentProductCode.id);
        }
      });

      $('#product_title').on("change", () => {
        if ($('#product_title').val() != '') {
          var currentProductTitle: Product = this.products.find((product: Product) => {
            return product.id == $('#product_title').val();
          });
          if ($('#product_title').val() != $('#product_code').val()) {
            $('#product_code').val(currentProductTitle.id).change();
          }
          this.productSupply.controls['product_title'].setValue(currentProductTitle);
          this.productSupply.controls['product_code'].setValue(currentProductTitle);
          this.productSupply.controls['current_stock'].setValue(currentProductTitle.stock);
          this.productSupply.controls['id'].setValue(currentProductTitle.id);
        }
      });
    });
  }

  onSubmit() {
    if (this.productSupply.invalid) {
      this.submitted = true;
      if(this.productSupply.controls['product_code'].value == null) {
        $('#product_code_control').find(".select2-selection").addClass("select2-is-invalid");
      }
      if(this.productSupply.controls['product_title'].value == null) {
        $('#product_title_control').find(".select2-selection").addClass("select2-is-invalid");
      }
      return;
    }
    this.loading = true;
    this.api.patch("products/addToStock", this.productSupply.controls['id'].value, this.productSupply.controls['new_stock'].value).pipe(finalize(() => { this.loading = false })).subscribe((productsData: Product[]) => {
      this.cancelSupplyProduct();
      Alerts.AlertSuccess('Se agregaron los productos al inventario');
    });
  }

  cancelSupplyProduct(){
    this.productSupply.reset();
    $('#product_code').val(null).trigger('change');
    $('#product_title').val(null).trigger('change');
    this.router.navigate(['/products']);
  }

  currentStock(): any {
    if (this.productSupply.controls['new_stock'].value) {
      var final = parseInt(this.productSupply.controls['new_stock'].value) + parseInt(this.productSupply.controls['current_stock'].value);
      return final;
    } else {
      return 0;
    }
  }

  get product_code() {
    return this.productSupply.get('product_code');
  }

  get product_title() {
    return this.productSupply.get('product_title');
  }
}
