import { Component, OnInit, ViewChild } from '@angular/core';
import { DeleteModalComponent } from 'src/app/components/delete-modal/delete-modal.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/_api/api.service';
import { finalize } from 'rxjs/operators';
import { Alerts } from 'src/app/utils/alerts';

declare var $: any;

@Component({
  selector: 'app-boxes-codes',
  templateUrl: './boxes-codes.component.html',
  styleUrls: ['./boxes-codes.component.sass']
})
export class BoxesCodesComponent implements OnInit {
  labelsForm: FormGroup;
  image: string = '';
  submitted = false;
  loading = false;
  success = false;

  constructor(private api: ApiService, private formBuilder: FormBuilder) {
    this.labelsForm = this.formBuilder.group({
      year: ['', [Validators.required, Validators.pattern(new RegExp('^[0-9]*$'))]],
      firstCode: ['', [Validators.required, Validators.pattern(new RegExp('^[C|c][0-9]{7}$'))]],
      finalCode: ['', [Validators.required, Validators.pattern(new RegExp('^[C|c][0-9]{7}$'))]],
      copies: ['', [Validators.required, Validators.pattern(new RegExp('^[0-9]*$')), Validators.min(1)]],
    });
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void { }

  submitForm() {
    if (this.labelsForm.invalid) {
      this.submitted = true;
      return;
    }
    var firstCode = this.labelsForm.controls['firstCode'].value;
    var finalCode = this.labelsForm.controls['finalCode'].value;
    if (parseInt(firstCode.substring(1), 10) <= parseInt(finalCode.substring(1), 10)) {
      this.loading = true;
      this.api.getAll('printers/findBoxesCodes', { firstCode: firstCode, finalCode: finalCode }).pipe(finalize(() => { this.loading = false; })).subscribe((response: any) => {
        if (response.Exists) {
          var form: FormData = new FormData();
          form.append('firstCode', firstCode);
          form.append('year', this.labelsForm.controls['year'].value);
          this.api.post('printers/generateBoxLabelPreview', form).pipe(finalize(() => {
            this.loading = false;
          })).subscribe((data: any) => {
            if (data.Error) {
              Alerts.AlertFailed(data.Error);
            } else {
              this.success = true;
              this.image = 'data:image/png;base64,' + data.image;
            }
          }, (error) => {
            Alerts.AlertFailed(error.error.message);
          });
        } else {
          Alerts.AlertFailed('El código de caja ' + response.Code + ' no existe');
        }
      });
    } else {
      Alerts.AlertFailed('El código inicial es mayor al código final');
      return false;
    }
  }

  cancelForm() {
    this.labelsForm.reset();
    this.submitted = false;
    this.loading = false;
    this.success = false;
    this.image = '';
  }

  printLabels() {
    if (this.labelsForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    var form: FormData = new FormData();
    Object.keys(this.labelsForm.value).forEach(key => {
      form.append(key, this.labelsForm.controls[key].value);
    });
    this.api.post('printers/printBoxesLabels', form).pipe(finalize(() => {
      this.loading = false;
    })).subscribe((data: any) => {
      if (data.Error) {
        Alerts.AlertFailed(data.Error);
      } else {
        this.cancelForm();
        if (data.ZPL) {
          Alerts.AlertSuccess('Impresión en curso, por favor espere un momento');
          this.openPrintWindow(data.ZPL);
        }
      }
    });
  }

  openPrintWindow(zpl: string) {
    var printWindow = window.open();
    printWindow.document.open('text/plain');
    printWindow.document.write(zpl);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  }
}
