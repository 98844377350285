import { AbstractControl } from '@angular/forms';

export class ValidationCategory {
    static ValidateCategory(AC: AbstractControl) {
      var idCategory = AC.get('idCategory').value;
      if (idCategory == null) {
        AC.get('idCategory').setErrors({ ValidateCategory: true });
      } else {
        return null;
      }
    }
  }