import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/_api/api.service';
import { finalize } from 'rxjs/operators';
import { Alerts } from 'src/app/utils/alerts';
import { forEach } from '@angular/router/src/utils/collection';
declare var $: any;

@Component({
  selector: 'app-importoutput',
  templateUrl: './importoutput.component.html',
  styleUrls: ['./importoutput.component.sass']
})
export class ImportoutputComponent implements OnInit, AfterViewInit {
  fileForm: FormGroup;
  loading: boolean = false;
  dropify: any;

  constructor(private api: ApiService, private builder: FormBuilder) { 
    this.fileForm = this.builder.group({
      json: ['', Validators.required]
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.dropify = $('.dropify').dropify({
      messages: {
        'remove': 'Eliminar el archivo',
        'error': 'Vaya, ha ocurrido un error inesperado.',
        'replace': 'Arrastra y suelta o haz clic para reemplazar el archivo',
        'default': 'Arrastra y suelta o haz clic para seleccionar el archivo'
      },
      error: {
        'fileSize': 'El archivo es demasiado grande (2MB MAX).',
        'fileExtension': 'La extensión del archivo no es la correcta (sólo archivos CSV).'
      }
    });

    this.dropify = this.dropify.data('dropify');

    const dropifyClearButton = $('.dropify-clear');

    // Agregar un listener de eventos al botón
    dropifyClearButton.on('click', () => {
      this.resetForm(); // Llamar a la función resetForm() cuando se hace clic en el botón
    });
  }

  resetForm(): void {
    this.fileForm.reset();
    this.dropify.resetPreview();
    this.dropify.clearElement();
  }

  onFileChange(event: any): void {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.fileForm.controls['json'].setValue(file);
    }
  }

  onSubmit(): void {
    if (this.fileForm.invalid) {
      Alerts.AlertFailed("No se ha seleccionado algún archivo.");
      return;
    }
    this.loading = true;
    var form: FormData = new FormData();
    form.append('fileData', this.fileForm.controls['json'].value);

    //Agregado-------------------------------------------------------------------------------------
    const file: File = this.fileForm.controls['json'].value;
  
    // Leer el contenido del archivo usando FileReader
    const reader: FileReader = new FileReader();
    reader.onload = (event: any) => {
      try {
        // Obtener el contenido del archivo como texto
        const fileContent: string = event.target.result;
        
        // Parsear el contenido del archivo como un objeto JSON y asignarlo a una variable
        const jsonData: any = JSON.parse(fileContent);
        
        // Aquí puedes hacer lo que necesites con la variable jsonData, que contiene el objeto JSON del archivo
        
        // Por ejemplo, puedes enviarlo al backend
        //console.log("El JSON:",jsonData);
        this.sendToBack(jsonData);
        // Reiniciar el formulario y la carga
        //this.resetForm();
        
      } catch (error) {
        // Manejar errores si el archivo no es un JSON válido
        Alerts.AlertFailed("El archivo no es un JSON válido.");
        this.resetForm();
        this.loading = false;
      }
    };
  
  // Leer el archivo como texto
  reader.readAsText(file);
  }

  sendToBack(fileData: any): void {  
    
    const data = fileData.data; // Solo enviar la parte 'data' del objeto 'fileData'
    const idOrder = data.attributes.uniqueOrderNumber.toString().split('_')[0];;
    const products = data.attributes.products;
    
    const codeProducts = products.reduce((acc: any[], curr: any) => {
      if (curr.data.attributes.batch) {
          acc.push(curr);
      }
      return acc;
    }, []);

    //Aqui si hay sku´s repetidos, suma las cantidades  para tener solo uno
    const additionalProducts = products.reduce((acc: any[], curr: any) => {
      if (!curr.data.attributes.batch) {
          const existingProduct = acc.find(p => p.data.attributes.sku === curr.data.attributes.sku);
          if (existingProduct) {
              existingProduct.data.attributes.quantity += curr.data.attributes.quantity;
          } else {
              acc.push(curr);
          }
      }
      return acc;
  }, []);

    const requestData = {
      ...data,
      idOrder: idOrder,
      codeProducts: codeProducts,
      additionalProducts: additionalProducts
    };

    //console.log("RequestData:",requestData);
    
    this.api.post('distributions/importOut', requestData).pipe(finalize(() => {
        this.resetForm();
        this.loading = false;
    })).subscribe((response: any) => {
      console.log(response);
      if (response.error) {
          Alerts.AlertFailed(response.error);
      } else {
          Alerts.AlertSuccess(response.success);
      }
    }, (error: any) => {
        if (error.error.errors) {
            if (error.error.message == 'Warning') {
                Alerts.AlertWarning(error.error.errors.join('. '));
            } else {
                Alerts.AlertFailed(error.error.errors.join('. '));
            }
        } else {
            Alerts.AlertFailed(error.message);
        }
    });
  }
}
