import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first, finalize } from 'rxjs/operators';
import { ApiService } from 'src/app/_api/api.service';
declare var $: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.sass']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
    private api: ApiService,
    private router: Router) {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.forgotPasswordForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    var formData: FormData = new FormData();
    formData.append('data', this.forgotPasswordForm.controls['email'].value);
    this.api.post('forgot-password', formData).pipe(finalize(() => { this.loading = false; })).subscribe((data: any) => {
      this.forgotPasswordForm.reset();
      this.showSuccessToast("Un correo electrónico te fue enviado para restablecer tu contraseña");
    }, (error) => {
      this.showDangerToast(error.error.errors.join('; '));
      this.loading = false;
    });
  }

  showDangerToast(title) {
    $.toast({
      heading: 'Alerta',
      text: title,
      showHideTransition: 'slide',
      icon: 'error',
      loaderBg: '#f2a654',
      position: 'bottom-left'
    })
  };

  showSuccessToast(title) {
    $.toast({
      heading: 'Realizado ',
      text: title,
      showHideTransition: 'slide',
      icon: 'success',
      loaderBg: '#f96868',
      position: 'bottom-left'
    });
  }

}
