import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { ApiService } from 'src/app/_api/api.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ModalReturnComponent } from 'src/app/components/modal-return/modal-return.component';
import { Alerts } from 'src/app/utils/alerts';
import { DeleteModalComponent } from 'src/app/components/delete-modal/delete-modal.component';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ValidationUnit } from 'src/app/utils/validation-unit';
import { ValidationCategory } from 'src/app/utils/validation-category';
import { Product } from 'src/app/models/products';
import { CodesProducts } from 'src/app/models/codes-products';
import { OrderProduct } from 'src/app/models/order-product';
import gradesList from 'src/assets/js/grade.json';

declare var $: any;

@Component({
  selector: 'app-products-to-order',
  templateUrl: './products-to-order.component.html',
  styleUrls: ['./products-to-order.component.sass']
})
export class ProductsToOrderComponent implements OnInit, AfterViewInit {
  @ViewChild("create")
  private createModal: ModalReturnComponent;
  @ViewChild("delete")
  private deleteModal: DeleteModalComponent;
  @Input('title') titleModal: string;

  products: Product[] = [];
  productsOrderTable: any;
  productOrdersForm: FormGroup;
  grades: any;
  levels: string[] = [];
  codes: CodesProducts[] = [];
  productCodes: CodesProducts[] = [];
  orderProducts: any[] = [];
  orderAdditionalProducts: any[] = [];
  additionalProductsFormArray: FormArray;
  productsFormArray: FormArray;
  currentProduct: OrderProduct = new OrderProduct();
  isEdit : boolean = false;
  isShow : boolean = false;
  includes: boolean = false;
  _grade: string = null;
  _productCode: CodesProducts = new CodesProducts();
  _level: string = null;
  _additionalProduct: Product = null;
  submitted: boolean = false;
  loading: boolean = false;
  idRelCodeProductProduct: number;
  idCodeProductProductEdit: number;
  idCodeProductProductDelete: number;
  gradeProductProductDelete: string;
  title: string = "";
  isMultipliedValue: boolean;
  selectedCheckboxIndex: number | null = null;

  constructor(private api: ApiService, private formBuilder: FormBuilder, private http: HttpClient) {
    this.productOrdersForm = this.formBuilder.group({
      id: [null],
      // idCodeProduct: [''],
      // idProduct: [''],
      // codeProduct: [''],
      // product: [''],
      grade: [''],
      quantity: [''],
      level: [''],
      productCode: [''],
      is_multiplied : [''],
      products: this.formBuilder.array([]),
      additionalProducts: this.formBuilder.array([]),
    });
    this.productsFormArray = this.productOrdersForm.get('products') as FormArray;
    this.additionalProductsFormArray = this.productOrdersForm.get('additionalProducts') as FormArray;
  }

  ngOnInit() {
    this.api.getAll('productsCodes/getAllProductsToShowOnOrders').subscribe((productsCodes: CodesProducts[]) => {
      //console.log(productsCodes);
      this.productCodes = productsCodes;
    });
    this.api.getAll('productsCodes/getAllLevels').subscribe((productsCodes: string[]) => {
      //console.log(productsCodes);
      this.levels = productsCodes;
    });
  }

  ngAfterViewInit(): void {
    $('#grade').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el grado',
      width: '100%',
      disabled: true,
      dropdownParent: $('#ProductModal')
    });
    $('#grade').val(null).trigger('change');

    $('#level').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el nivel',
      width: '100%',
      dropdownParent: $('#ProductModal')
    });
    $('#level').val(null).trigger('change');

    $('#product_code').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona los productos asociados',
      width: '100%',
      disabled: true,
      dropdownParent: $('#ProductModal'),
    });
    $('#product_code').val(null).trigger('change');

    $('#product').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el producto',
      width: '100%',
      dropdownParent: $('#ProductModal')
    });
    $('#product').val(null).trigger('change');

    $('#level').on("change", () => {
      if ($('#level').val() != '') {
        var level = $('#level').val();
        this.productOrdersForm.controls['level'].setValue(level);
        this.codes = [];

        gradesList.forEach(element => {
          if (level == element['code']) {
            this.grades = element['grades'];
          }
        });
        this.productCodes.forEach(element => {
          if (level == element['level']) {
            this.codes.push(element);
          }
        });
        $('#grade').empty().trigger("change");
        $('#product_code').empty().trigger("change");

        this.grades.forEach((grades: any) => {
          if (grades == '7') {
            grades = 'UNICO';
          }
          var newOption = new Option(grades, grades, false, false);
          $('#grade').append(newOption).trigger('change');

        });
        this.codes.forEach((element) => {
          var newOption = new Option(element.description + " - " + element.idSeason.name, element.id, false, false);
          $('#product_code').append(newOption).trigger('change');

        });
        $('#grade').val(null).trigger("change");
        $("#grade").prop("disabled", false);
        this.productOrdersForm.controls['level'].setValue(level);
        $('#grade').val(null).trigger("change");
        $("#grade").prop("disabled", false);
        $('#product_code').val(null).trigger("change");
        $("#product_code").prop("disabled", false);
        this._level = level;
      }
    });

    $('#product_code').on("change", () => {
      this._productCode = null;
      if ($('#product_code').val() != '') {
        var code = $('#product_code').val();
        this.productOrdersForm.controls['productCode'].setValue(code);
        this.productCodes.forEach(element => {
          if (code == element['id']) {
            this._productCode = element;
          }
        });
      }
    });

    $('#grade').on("change", () => {
      if ($('#grade').val() != '') {
        var grade = $('#grade').val();
        this._grade = grade;
        this.productOrdersForm.controls['grade'].setValue(grade);
      }
    });
    
    $('#products').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona los productos asociados',
      width: '100%',
      dropdownParent: $('#ProductModal'),
    });
    $('#products').on("change", () => {
      this.productOrdersForm.controls['products'].setValue($('#products').val());
    });

    $('#product').on("change", () => {
      if ($('#product').val() != '') {
        var code = $('#product').val();
        this.products.forEach(element => {
          if (code == element['id']) {
            this._additionalProduct = element;
          }
        });
      }
    });

    $('#is_multiplied').val(null).trigger('change');

    $('#is_multiplied').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona si se multiplica por caja',
      width: '100%',
      dropdownParent: $('#ProductModal')
    }).on('select2:select', (e) => {
      // Obtener el valor seleccionado
      const value = e.params.data.id === 'true';
      // Llamar al método de Angular para manejar cambios
      this.onChange(value);
    });;

    this.productsOrderTable = $('#products-order-table').DataTable({
      'ajax': {
        url: `${environment.apiUrl}/products/rel-code`,
        data: function (data) {
          data.name = $('#keywords').val();
        },
        //success: function(data) {console.log('JSON recibido desde la URL:', data);},
      },
      'serverSide': false,
      'searching': false,
      'paging': true,
      'lengthChange': false,
      'language': { 'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json' },
      columns: [
        { data: 'id', visible: false },
        { data: 'description' },
        { data: 'prefix' },
        { data: 'seasonId' },
        { data: 'productLevel' },
        { data: 'grade' },
        {
          data: null,
          orderable: false,
          searchable: false,
          defaultContent: '',
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).prepend(

              /*
                <button type="button" class="btn btn-warning product-edit" title="Editar">
                  <i class="mdi mdi-lead-pencil text-white"></i>
                </button>
              */

              `<div class="btn-group" role="group" aria-label="Acciones">
                <button type="button" class="btn btn-info btn-success product-show" title="Ver detalles">
                  <i class="mdi mdi-plus text-white"></i>
                </button>
                
                <button type="button" class="btn btn-danger product-delete" title="Eliminar">
                  <i class="mdi mdi-delete-forever"></i>
                </button>
              </div>`
            );
          }
        }
      ],
      drawCallback: () => {
        $('.product-show').on('click', (element) => {
          let product: Product = this.productsOrderTable.row($(element.currentTarget).closest('tr')).data();
          this.showProduct(product);
        });

        $('.product-edit').on('click', (element) => {
          let product: Product = this.productsOrderTable.row($(element.currentTarget).closest('tr')).data();
          this.editProduct(product);
        });

        $('.product-delete').on('click', (element) => {
          let product: Product = this.productsOrderTable.row($(element.currentTarget).closest('tr')).data();
          this.deleteProduct(product);
        });
      }
    });

    $('#keywords').on('keyup', function() {
      var table = $('#products-order-table').DataTable();
      table.search(this.value).draw(); // Usa .draw() en lugar de .ajax.reload() para procesar la búsqueda
    });

  }

  refreshOrderTable() {
    this.productsOrderTable.ajax.reload();
  }

  resetOrderTable() {
    $("#keywords").val('');
    this.productsOrderTable.ajax.reload();
  }

  refreshProduct() {
    $('#product').val(null).trigger('change');
    $('#is_multiplied').val(null).trigger('change');
  }
  
  createProductOrder() {

    const additionalProductsFormArray = this.productOrdersForm.get('additionalProducts') as FormArray;
    while (additionalProductsFormArray.length !== 0) {
      additionalProductsFormArray.removeAt(0);
    }

    this.api.getAll('products/getProductsToShowInOrders').subscribe((productsData: Product[]) => {
      this.products = productsData;

      if (this._grade) {
        this.products = this.products.filter(product => product.grade === this._grade || product.grade === null);
      }

      $('#products').empty().trigger("change");
      this.products.forEach((product: Product) => {
        var newOption = new Option(product.title, product.id, false, false);
        $('#products').append(newOption).trigger('change');
      });

      this.isEdit = false;
      this.isShow = false;
      this.selectedCheckboxIndex = null;
      this.createModal.open(true);
    });
  }

  cancelProductForm() {
    this.createModal.close();
    this.deleteModal.close();
    
    // this.productForm.reset();
    // this.submitted = false;
    // this.productsTable.ajax.reload();
    $("#grade").prop("disabled", true);
    $('#level').prop("disabled", false);
    $('#level').val(null).trigger('change');
    $('#grade').val(null).trigger('change');
    $("#products").val(null).trigger('change');
    $('#product_code').prop("disabled", true);
    $('#product_code').val(null).trigger('change');
    $('#product').val(null).trigger('change');
    $('#is_multiplied').val(null).trigger('change');
  }

  addProduct() {
    if (this._level != null && this._grade != null && this._productCode != null) {
      this.includes = false;

      var id = this._productCode.id;
      var grade = this._grade;

      this.api.getAdditionalProducts(`products/checkIfProductExists`,id,grade).subscribe(
        exists => {
          if (exists) {
            this.orderAdditionalProducts = [];
            while (this.additionalProductsFormArray.length !== 0) {
              this.additionalProductsFormArray.removeAt(0);
            }
            Alerts.AlertFailed('Ya existe una relación para el producto.');
          } else {
            var orderProduct = new OrderProduct();
            orderProduct.grade = this._grade;
            orderProduct.level = this._level;
            orderProduct.description = `${this._productCode.description} ${orderProduct.grade} de ${orderProduct.level} - ${this._productCode.idSeason.name}`;
  
            this.orderProducts.push(orderProduct);
  
            $("#level").prop("disabled", true);
            $("#product_code").prop("disabled", true);
            $('#grade').prop("disabled", true);
            $('#product').val(null).trigger('change');
            $('#is_multiplied').val(null).trigger('change');
            this.createProductOrder();
          }
        },
        error => {
          console.log(error);
          Alerts.AlertFailed('Error al verificar la existencia del producto. Por favor, intenta de nuevo.');
        }
      );
    }
  }

  onChange(value: boolean){
    this.isMultipliedValue = value;
    if (this._additionalProduct) {
        this._additionalProduct.isMultiplied = this.isMultipliedValue;
    }
  }

  addAdditionalProducts() {
    const level = this.productOrdersForm.get('level').value;
    const productCode = this.productOrdersForm.get('productCode').value;
    const grade = this.productOrdersForm.get('grade').value;
    if (!level || !productCode || !grade) {
      Alerts.AlertFailed('Se deben agregar los Datos Generales primero');
      return;
    }
    if (this._additionalProduct != null) {
      this.includes = false;

      this.orderAdditionalProducts.forEach((product: Product) => {

        //console.log(this._additionalProduct, product);

        if (this._additionalProduct.id == product.id) {
          this.includes = true;
          // this.refreshProduct();
          Alerts.AlertFailed('El producto ya existe en productos asociados');
        }
      });

      if (!this.includes) {
        this.orderAdditionalProducts.push(this._additionalProduct);
        this.additionalProductsFormArray.push(this.formBuilder.group({
          id: this._additionalProduct.id,
          code: this._additionalProduct.code,
          title: this._additionalProduct.title,
          quantity: [1, [Validators.required, Validators.min(1)]],
          is_multiplied: [this._additionalProduct.isMultiplied || false, Validators.required],
          box_reference: [false]
        }));
        this.refreshProduct();
        Alerts.AlertSuccess('El producto ha sido agregado con éxito');
      }
    }
  }

  removeOrderAdditionalProduct(index: number) {
    (this.productOrdersForm.get('additionalProducts') as FormArray).removeAt(index);
    this.orderAdditionalProducts.splice(index, 1);
    Alerts.AlertSuccess('Producto eliminado con éxito');
  }
  
  onSubmitOrderForm() {
    this.loading = true;
    if (this.productOrdersForm.invalid) {
      this.submitted = true;
      this.loading = false;
      return;
    }
    var formValue = this.productOrdersForm.value;
    var payload = {
      grade: formValue.grade,
      level: formValue.level,
      productCode: formValue.productCode,
      isMultiplied: formValue.isMultiplied,
      additionalProducts: formValue.additionalProducts.map((p, i) => this.additionalProductsFormArray.at(i).value),
    };
    if (!this.isEdit) {
      this.api.post("products/createRelationCodeProductProduct", payload)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        response => {
          $('#products-order-table').DataTable().ajax.reload(null, false);
          this.cancelProductForm();
          this._additionalProduct = null;
          this.orderAdditionalProducts = [];
          Alerts.AlertSuccess('La relación de productos ha sido creada exitosamente.');
        },
        error => {
          Alerts.AlertFailed('Ha ocurrido un error al crear la relación de productos.');
        }
      );
    } else {
      this.loading = false;
      this.idCodeProductProductEdit = formValue.productCode;
      this.api.put(`products/updateRelationCodeProductProduct`, this.idCodeProductProductEdit, payload)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        response => {
          $('#products-order-table').DataTable().ajax.reload(null, false);
          this.cancelProductForm();
          Alerts.AlertSuccess('La relación de productos ha sido actualizada exitosamente.');
        },
        error => {
          Alerts.AlertFailed('Ha ocurrido un error al actualizar la relación de productos.');
          console.log(error);
        }
      );
    }
  }

  onCheckboxChange(index: number) {
    const additionalProductsFormArray = this.productOrdersForm.get('additionalProducts') as FormArray;
    const productGroup = additionalProductsFormArray.at(index) as FormGroup;
    const isBoxReference = productGroup.get('box_reference');

    if (this.selectedCheckboxIndex === index) {
      // Si el checkbox ya está seleccionado, deseleccionarlo
      this.selectedCheckboxIndex = null;
      isBoxReference.patchValue(false); // Desmarcar la propiedad "box_reference"
    } else {
      // Si se selecciona un nuevo checkbox, actualizar el índice y marcar "box_reference"
      this.selectedCheckboxIndex = index;
      isBoxReference.patchValue(true); // Marcar la propiedad "box_reference"
    }
  }

  showProduct(product) {
    this.api.getAll('products/getProductsToShowInOrders').subscribe((productsData: Product[]) => {
      this.products = productsData;
      $('#products').empty().trigger("change");
      this.products.forEach((product: Product) => {
        var newOption = new Option(product.title, product.id, false, false);
        $('#products').append(newOption).trigger('change');
      });
    });

    this.productOrdersForm.reset();
    while (this.additionalProductsFormArray.length !== 0) {
      this.additionalProductsFormArray.removeAt(0);
    }

    this.productOrdersForm.patchValue({
      id: product.id,
      grade: product.grade,
      quantity: product.quantity,
      level: product.productLevel,
      product_code: product.description + '-' + product.seasonId,
    });

    $('#level').val(product.productLevel).trigger('change').prop('disabled', true);

    // $('#product_code').val(product.description + ' - ' + product.seasonId).trigger('change'); // O la propiedad que tenga el valor deseado
    var textoBuscar = product.description + ' - ' + product.seasonId;
    // Encuentra la opción por texto y establece el valor en el select
    $("#product_code option").each(function() {
      if ($(this).text() === textoBuscar) {
        // Establece el valor del select al value de la opción encontrada
        $('#product_code').val($(this).val()).trigger('change').prop('disabled', true);
      }
    });

    $('#grade').val(product.grade).trigger('change').prop('disabled', true);

    product.products.forEach(p => {
      this.additionalProductsFormArray.push(this.formBuilder.group({
        id: p.product_id,
        code: p.catProduct.split(' ')[0],
        title: p.catProduct.split(' ').slice(1).join(' '),
        quantity: p.quantity,
        is_multiplied: p.is_multiplied
      }));
    });
    this.productOrdersForm.disable();
    this.isEdit = false;
    this.isShow = true;
    this.title = "Detalles de productos para pedidos";
    this.createModal.open(false);
  }

  editProduct(product) {

    this.api.getAll('products/getProductsToShowInOrders').subscribe((productsData: Product[]) => {
      this.products = productsData;
      $('#products').empty().trigger("change");
      this.products.forEach((product: Product) => {
        var newOption = new Option(product.title, product.id, false, false);
        $('#products').append(newOption).trigger('change');
      });
    });

    this.productOrdersForm.reset();
    while (this.additionalProductsFormArray.length !== 0) {
      this.additionalProductsFormArray.removeAt(0);
    }

    this.productOrdersForm.patchValue({
      id: product.id,
      grade: product.grade,
      quantity: product.quantity,
      level: product.productLevel,
      product_code: product.description + '-' + product.seasonId,
    });

    $('#level').val(product.productLevel).trigger('change').prop('disabled', true);

    // $('#product_code').val(product.description + ' - ' + product.seasonId).trigger('change'); // O la propiedad que tenga el valor deseado
    var textoBuscar = product.description + ' - ' + product.seasonId;
    // Encuentra la opción por texto y establece el valor en el select
    $("#product_code option").each(function() {
      if ($(this).text() === textoBuscar) {
        // Establece el valor del select al value de la opción encontrada
        $('#product_code').val($(this).val()).trigger('change').prop('disabled', true);
      }
    });

    $('#grade').val(product.grade).trigger('change').prop('disabled', true);

    product.products.forEach(p => {
      this.additionalProductsFormArray.push(this.formBuilder.group({
        id: p.product_id,
        code: p.catProduct.split(' ')[0],
        title: p.catProduct.split(' ').slice(1).join(' '),
        quantity: p.quantity,
        is_multiplied: p.is_multiplied
      }));
    });
    this.isEdit = true;
    this.isShow = false;
    this.title = "Editar Productos para pedidos";
    this.createModal.open(false);
  }

  deleteProduct(product) {
    // console.log(product);
    this.idRelCodeProductProduct = product.id;
    this.api.get(`products/getRelationByProductId`, this.idRelCodeProductProduct).subscribe(
      response => {
        // console.log(response);
        this.idCodeProductProductDelete = response['codeProductId'];
        this.gradeProductProductDelete = product.grade;  // Guardamos el grado
      },
      error => {
        // console.log(error);
      }
    );
    this.deleteModal.open();
  }

  onDeleteProductForm() {
    this.loading = true;
    if (this.idRelCodeProductProduct && this.gradeProductProductDelete) {
      this.api.deleteRelation(`products/deleteByCodeProductId`, this.idCodeProductProductDelete, this.gradeProductProductDelete).subscribe(
        response => {
          Alerts.AlertSuccess('El producto ha sido eliminado con éxito');
          this.loading = false;
          this.deleteModal.close();
          this.productsOrderTable.ajax.reload(null, false); // Recarga la tabla sin resetear la paginación
        },
        error => {
          Alerts.AlertFailed('Hubo un error');
        }
      );
    }
  }

  isCheckedDistributorActive() {
    if (this.productOrdersForm.controls['distributorActive'].value == true) {
      $('#distributorActive').prop('checked', true);
    } else {
      $('#distributorActive').prop('checked', false);
    }
  }

}
