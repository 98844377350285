import { HttpInterceptor } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../models';
import { Injectable } from '@angular/core';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private auth: AuthenticationService) {}

    intercept(req: import("@angular/common/http").HttpRequest<any>, next: import("@angular/common/http").HttpHandler): import("rxjs").Observable<import("@angular/common/http").HttpEvent<any>> {
        // Check for the custom header
        if (req.headers.has('X-Skip-Interceptor')) {
            const headers = req.headers.delete('X-Skip-Interceptor');
            const modifiedReq = req.clone({ headers });
            return next.handle(modifiedReq);
        }


        let user: User | null = this.auth.user;
        if(user && user.token) {
            req = req.clone({
                setHeaders: {
                    'Accepts': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
        }
        return next.handle(req);
    }
}
