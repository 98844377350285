import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ApiService } from 'src/app/_api/api.service';
import { ModalReturnComponent } from 'src/app/components/modal-return/modal-return.component';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { DeleteModalComponent } from 'src/app/components/delete-modal/delete-modal.component';
import { PaymentForm } from 'src/app/models/payment-forms';
import { finalize } from 'rxjs/internal/operators/finalize';
import { Alerts } from 'src/app/utils/alerts';
import statesList from 'src/assets/js/statelist.json';
import { Payment } from 'src/app/models/payment';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.sass']
})
export class PaymentsComponent implements OnInit {
  @ViewChild('deletePaymentDiscount')
  deletePaymentDiscountModal: DeleteModalComponent;
  @ViewChild('paymentDiscount')
  addPaymentDiscountModal: DeleteModalComponent;
  @ViewChild('showRecord')
  showRecordModal: ModalReturnComponent;
  paymentForms: PaymentForm[] = [];
  paymentDiscountForm: FormGroup;
  paymentsFormArray: FormArray;
  distributorsTable: any;
  discountModal = false;
  recordForm: FormGroup;
  paymentModal = false;
  deleteModal = false;
  index2Remove = null;
  percentage = false;
  submitted = false;
  loading = false;
  modalTitle = '';
  quantity = true;
  formatter: any;
  states: any;
  
  constructor(private api: ApiService, private changes: ChangeDetectorRef, private formBuilder: FormBuilder) {
    this.states = statesList;
    this.formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    this.api.getAll('paymentForms/getAll').subscribe((paymentForms: any) => {
      this.paymentForms = paymentForms;
    });
  }

  ngOnInit() {
    this.recordForm = this.formBuilder.group({
      seasonId: null,
      accountId: null,
      seasonName: null,
      accountDebt: null,
      distributorId: null,
      distributorCode: null,
      distributorName: null,
      distributorTown: null,
      distributorState: null,
      payments: this.formBuilder.array([])
    });
    this.paymentsFormArray = this.recordForm.get('payments') as FormArray;
    this.paymentDiscountForm = this.formBuilder.group({
      date: [null, Validators.required],
      type: [null, Validators.required],
      comment: [null, Validators.required],
      quantity: [null, Validators.required],
      accountId: [null, Validators.required],
      distributorId: [null, Validators.required],
      paymentFormId: [null, Validators.required]
    });
  }

  ngAfterViewInit(): void {
    $('#datepicker-1').datepicker({ format: 'dd/mm/yyyy', enableOnReadonly: false, todayHighlight: true, autoclose: true });

    $('#paymentForms').select2({ width: '100%', theme: 'bootstrap', dropdownParent: $('#PaymentDiscountModal'), placeholder: 'Selecciona una forma de pago' });

    this.distributorsTable = $('#distributors-table').DataTable({
      'ajax': {
        url: `${environment.apiUrl}/payments`,
        data: function (data) {
          data.name = $('#name').val();
        }
      },
      'paging': true,
      'pageLength': 10,
      'searching': false,
      'serverSide': true,
      'lengthChange': false,
      'language': { 'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json' },
      columns: [
        {
          data: 'id',
          orderable: false,
          defaultContent: '',
          className: 'details-control',
          render: function (data, type, row, meta) {
            return '';
          }
        },
        {
          data: 'id',
          visible: false,
          searchable: false
        },
        { data: 'code' },
        {
          data: 'name',
          render: (data, type, row, meta) => {
            return row['name'] + ' ' + row['paternalSurname'] + ' ' + row['maternalSurname'];
          }
        },
        {
          data: 'state',
          render: (data, type, row, meta) => {
            return this.getStateName(row['state']);
          }
        },
        {
          data: 'town'
        }
      ],
      drawCallback: () => {

      }
    });

    $('#distributors-table tbody').on('click', 'td.details-control', (event) => {
      var table = $('#distributors-table').DataTable();
      var tr = $(event.currentTarget).closest('tr');
      var row = table.row(tr);
      if (row.child.isShown()) {
        row.child.hide();
        tr.removeClass('shown');
      } else {
        this.api.get('payments/getDistributorSeasons', row.data().id).subscribe((seasons: any) => {
          var newTable = `<table border="0" cellpadding="5" cellspacing="0" width="100%">`;
          for (let index = 0; index < seasons.length; index++) {
            var style = ``;
            if (index == 0) {
              style = `border-top: none;`;
            }
            var newTr =
              `<tr>
                <td style="` + style + `">` + seasons[index].name + `</td>
                <td class="text-dark font-weight-medium" style="` + style + `">Saldo</td>
                <td style="` + style + `">` + this.formatter.format(seasons[index].debt).slice(0, 1) + ' ' + this.formatter.format(seasons[index].debt).slice(1) + `</td>
                <td class="text-dark font-weight-medium" style="` + style + `">Abono</td>
                <td style="` + style + `">` + this.formatter.format(seasons[index].paid).slice(0, 1) + ' ' + this.formatter.format(seasons[index].paid).slice(1) + `</td>
                <td class="text-dark font-weight-medium" style="` + style + `">Pedidos</td>
                <td style="` + style + `">` + seasons[index].orders + `</td>
                <td style="` + style + ` text-align: right;">
                  <input type="hidden" class="season-id" value="` + seasons[index].id + `">
                  <input type="hidden" class="season-name" value="` + seasons[index].name + `">
                  <input type="hidden" class="account-debt" value="` + seasons[index].debt + `">
                  <div class="btn-group" role="group" aria-label="Acciones" style="float: right;" align="right">
                    <button type="button" class="btn btn-info payments-details" title="Ver detalles">
                      <i class="mdi mdi-plus text-white"></i>
                    </button>
                  </div>
                </td>
              </tr>`;
            newTable += newTr;
          }
          newTable += `</table>`;
          row.child(newTable).show();
          tr.addClass('shown');
        });
      }
    });

    $('input:radio[name="radios"]').on('change', () => {
      var value = $('input:radio[name="radios"]:checked').val();
      if (value == 1) {
        this.quantity = true;
        this.percentage = false;
      } else if (value == 2) {
        this.quantity = false;
        this.percentage = true;
      }
      $('#percentage').val('').trigger('change');
    });

    $('#percentage').on('change', () => {
      var value = $('#percentage').val().replace(/[^\d.]/g, '');
      if (value) {
        var percentage = parseFloat(value);
        if (percentage <= 100.0) {
          var debt = parseFloat(this.recordForm.controls['accountDebt'].value.replace(/[^\d.]/g, ''));
          var quantity = percentage * debt / 100.0;
          this.paymentDiscountForm.controls['quantity'].setValue(quantity);
        } else {
          $('#percentage').val('').trigger('change');
        }
      } else {
        this.paymentDiscountForm.controls['quantity'].setValue(null);
      }
    });

    $('#paymentForms').on('change', () => {
      if ($('#paymentForms').val()) {
        this.paymentForms.find((paymentForm: PaymentForm) => {
          this.paymentDiscountForm.controls['paymentFormId'].setValue(parseInt(paymentForm.id, 10));
          return paymentForm.id == $('#paymentForms').val();
        });
        $('#select-control').find('.select2-selection').removeClass('select2-is-invalid');
      }
    });

    $('#date').on('change', () => {
      var value = $('#date').val().split('/').reverse().join('-');
      this.paymentDiscountForm.controls['date'].setValue(value);
    });

    $(document).on('click', '.payments-details', (event) => {
      var table = $('#distributors-table').DataTable();
      var tr = $(event.currentTarget).closest('table').closest('tr').prev();
      var distributor = table.row(tr).data();
      var seasonId = $(event.currentTarget).closest('td').find('input.season-id').val();
      var seasonName = $(event.currentTarget).closest('td').find('input.season-name').val();
      var accountDebt = $(event.currentTarget).closest('td').find('input.account-debt').val();
      this.loadRecordModal(distributor, { id: seasonId, name: seasonName, accountDebt: accountDebt });
      this.showRecordModal.open(true);
    });
  }

  loadRecordModal(distributor: any, season: any) {
    this.recordForm.controls['seasonId'].setValue(season.id);
    this.recordForm.controls['seasonName'].setValue(season.name);
    this.recordForm.controls['distributorId'].setValue(distributor.id);
    this.recordForm.controls['distributorCode'].setValue(distributor.code);
    this.recordForm.controls['distributorTown'].setValue(distributor.town);
    this.recordForm.controls['distributorState'].setValue(this.getStateName(distributor.state));
    this.recordForm.controls['distributorName'].setValue(distributor.name + ' ' + distributor.paternalSurname + ' ' + distributor.maternalSurname);
    this.recordForm.controls['accountDebt'].setValue(this.formatter.format(season.accountDebt).slice(0, 1) + ' ' + this.formatter.format(season.accountDebt).slice(1));
    this.api.get('payments/getDistributorAccountBySeason/' + distributor.id, season.id).subscribe((account: any) => {
      this.api.get('payments/getDistributorPaymentsByAccount/' + distributor.id, account.id).pipe(finalize(() => {
        this.changes.detectChanges();
      })).subscribe((payments: Payment[]) => {
        this.recordForm.controls['accountId'].setValue(account.id);
        payments.forEach(element => {
          this.paymentsFormArray.push(this.formBuilder.group({
            id: element.id,
            type: element.type ? 'Abono' : 'Descuento',
            paymentFormId: element.paymentFormId.paymentForm,
            date: element.date.split('-').reverse().join('/'),
            quantity: this.formatter.format(element.quantity).slice(0, 1) + ' ' + this.formatter.format(element.quantity).slice(1)
          }));
        });
      });
    });
  }

  reloadRecordModal(account: any) {
    while (this.paymentsFormArray.length !== 0) {
      this.paymentsFormArray.removeAt(0);
    }
    this.recordForm.controls['accountDebt'].setValue(this.formatter.format(account.quantity).slice(0, 1) + ' ' + this.formatter.format(account.quantity).slice(1));
    this.api.get('payments/getDistributorPaymentsByAccount/' + account.distributorId.id, account.id).pipe(finalize(() => {
      this.changes.detectChanges();
    })).subscribe((payments: Payment[]) => {
      payments.forEach(element => {
        this.paymentsFormArray.push(this.formBuilder.group({
          id: element.id,
          type: element.type ? 'Abono' : 'Descuento',
          paymentFormId: element.paymentFormId.paymentForm,
          date: element.date.split('-').reverse().join('/'),
          quantity: this.formatter.format(element.quantity).slice(0, 1) + ' ' + this.formatter.format(element.quantity).slice(1)
        }));
      });
    });
  }

  cancelRecordForm() {
    this.showRecordModal.close();
    this.recordForm.reset();
    while (this.paymentsFormArray.length !== 0) {
      this.paymentsFormArray.removeAt(0);
    }
    $('.modal-backdrop').remove();
  }

  openPDModal(type: boolean) {
    if (type) {
      this.paymentModal = true;
      this.modalTitle = 'Registrar Pago';
      this.paymentDiscountForm.controls['type'].setValue(1);
      this.paymentDiscountForm.controls['comment'].setValue('N/A');
    } else {
      this.discountModal = true;
      this.modalTitle = 'Registrar Descuento';
      this.paymentDiscountForm.controls['type'].setValue(0);
      this.paymentDiscountForm.controls['paymentFormId'].setValue(1);
    }
    this.paymentDiscountForm.controls['accountId'].setValue(parseInt(this.recordForm.controls['accountId'].value, 10));
    this.paymentDiscountForm.controls['distributorId'].setValue(parseInt(this.recordForm.controls['distributorId'].value, 10));
    this.addPaymentDiscountModal.open();
  }
  
  cancelPDForm() {
    this.addPaymentDiscountModal.close();
    this.paymentDiscountForm.reset();
    this.discountModal = false;
    this.paymentModal = false;
    this.percentage = false;
    this.submitted = false;
    this.quantity = true;
    $('#percentage').val('').trigger('change');
    $('#paymentForms').val(null).trigger('change');
    $('input:radio[name="radios"][value="1"]').click();
    $('#datepicker-1').data('datepicker').setDate(null);
    $('#select-control').find('.select2-selection').removeClass('select2-is-invalid');
  }

  submitPDForm() {
    if (this.paymentDiscountForm.invalid) {
      this.submitted = true;
      if (this.paymentDiscountForm.controls['paymentFormId'].value == null) {
        $('#select-control').find('.select2-selection').addClass('select2-is-invalid');
      }
      return;
    }
    this.loading = true;
    var payment: any = Object.assign({}, this.paymentDiscountForm.value);
    this.api.post('payments/store', payment).pipe(finalize(() => {
      this.distributorsTable.ajax.reload();
      this.loading = false;
      this.cancelPDForm();
    })).subscribe((data: any) => {
      this.reloadRecordModal(data);
      if (this.paymentModal) {
        Alerts.AlertSuccess('El pago ha sido registrado');
      } else {
        Alerts.AlertSuccess('El descuento ha sido registrado');
      }
    }, (error) => {
      Alerts.AlertFailed(error.error.errors.join('; '));
    });
  }

  openDeletePDModal(i) {
    this.index2Remove = i;
    this.deleteModal = true;
    this.deletePaymentDiscountModal.open();
  }

  cancelDeletePDForm() {
    this.deleteModal = false;
    this.index2Remove = null;
    this.deletePaymentDiscountModal.close();
  }

  submitDeletePDForm() {
    var type = this.paymentsFormArray.value[this.index2Remove].type;
    this.loading = true;
    var form: FormData = new FormData();
    form.append('paymentId', this.paymentsFormArray.value[this.index2Remove].id);
    this.api.post('payments/delete', form).pipe(finalize(() => {
      this.distributorsTable.ajax.reload();
      this.cancelDeletePDForm();
      this.loading = false;
    })).subscribe((data: any) => {
      this.reloadRecordModal(data);
      if (type == 'Abono') {
        Alerts.AlertSuccess('El pago ha sido eliminado');
      } else {
        Alerts.AlertSuccess('El descuento ha sido eliminado');
      }
    }, (error) => {
      Alerts.AlertFailed(error.error.errors.join('; '));
    });
  }

  refreshTable() {
    this.distributorsTable.ajax.reload();
  }

  resetTable() {
    $('#name').val('');
    this.distributorsTable.ajax.reload();
  }

  getStateName(code: any) {
    var state = this.states.find((element) => { return element.code == code; });
    return state.name.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
