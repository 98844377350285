import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { CreateModalComponent } from 'src/app/components/create-modal/create-modal.component';
import { DeleteModalComponent } from 'src/app/components/delete-modal/delete-modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/_api/api.service';
import { Category } from 'src/app/models/category';
import { DatePipe } from '@angular/common';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.sass']
})

export class CategoriesComponent implements OnInit, AfterViewInit 
{
  @ViewChild(CreateModalComponent)
  private createModal: CreateModalComponent;
  
  @ViewChild(DeleteModalComponent)
  private deleteModal: DeleteModalComponent;
  
  @Input('title') titleModal: string;
  private categoriesTable: any;
  
  isEditingCategory: boolean = false;
  submitted = false;
  loading = false;
  categoryForm: FormGroup;
  nowDate: string;

  constructor(private formBuilder: FormBuilder, private api: ApiService) {
    this.nowDate = new DatePipe('en-US').transform(new Date().toISOString().toLocaleString().slice(0, 16), 'dd:MM:yyyy hh:mm:ss', 'GMT-10');
  }

  ngOnInit() {
    this.categoryForm = this.formBuilder.group({
      id: [null],
      name: ['', Validators.required],
      description: [''],
    });
  }

  ngAfterViewInit(): void {
    this.categoriesTable = $('#categories-table').DataTable({
      'ajax': {
        url: `${environment.apiUrl}/categories`,
        data: function (data) {
          data.from = $("#initialDate").val();
          data.to = $("#finalDate").val();
          data.searchName = $("#search").val();
        }
      },
      'serverSide': true,
      'searching': false,
      'paging': true,
      'lengthChange': false,
      'language': { 'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json' },
      columns: [
        { data: 'id' },
        { data: 'name' },
        { data: 'description' },
        {
          data: null,
          defaultContent: '',
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).prepend(
              `
                <div class="btn-group" role="group" aria-label="Acciones">
                  <button type="button" class="btn btn-warning category-edit" title="Editar">
                    <i class="mdi mdi-lead-pencil text-white"></i>
                  </button>
                  <button type="button" class="btn btn-danger category-delete" title="Eliminar">
                    <i class="mdi mdi-delete-forever"></i>
                  </button>
                </div>
              `
            );
          },
          orderable: false,
          searchable: false
        }
      ],
      columnDefs: [
        {
          "targets": [0],
          "visible": false,
          "searchable": false
        }
      ],
      drawCallback: () => {
        $('.category-edit').on('click', (element) => {
          let category: Category = this.categoriesTable.row($(element.currentTarget).closest('tr')).data();
          this.editCategory(category);
        });

        $('.category-delete').on('click', (element) => {
          let category: Category = this.categoriesTable.row($(element.currentTarget).closest('tr')).data();
          this.deleteCategory(category);
        });
      }
    });

    $('.datepicker').datepicker({
      enableOnReadonly: true,
      todayHighlight: true,
      autoclose: true,
    });

    $('#initialDate').on('changeDate', function () {
      $('#initialDate').val($('#initialDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#initialDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $("#finalDate").datepicker("setStartDate", dtFormatted);
    });

    $('#finalDate').on('changeDate', function () {
      $('#finalDate').val($('#finalDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#finalDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $("#initialDate").datepicker("setEndDate", dtFormatted);
    });
  }

  createCategory() {
    this.createModal.open(true);
    this.isEditingCategory = false;
  }

  editCategory(category: Category) {
    this.isEditingCategory = true;
    Object.keys(category).forEach(key => {
      if (this.categoryForm.controls[key])
        this.categoryForm.controls[key].setValue(category[key]);
    });
    this.createModal.open(false);
  }

  deleteCategory(category: Category) {
    Object.keys(category).forEach(key => {
      if (this.categoryForm.controls[key])
        this.categoryForm.controls[key].setValue(category[key]);
    });
    this.deleteModal.open();
  }

  cancelCategoryForm() {
    this.createModal.close();
    this.deleteModal.close();
    this.categoryForm.reset();
    this.categoriesTable.ajax.reload();
    this.submitted = false;
  }

  onSubmitCategoryForm() {
    if (this.categoryForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    if (this.categoryForm.controls['id'].value) {
      var category: Category = Object.assign({}, this.categoryForm.value);
      this.api.patch('categories', this.categoryForm.controls['id'].value, category).pipe(finalize(() => { this.loading = false; })).subscribe((categories: Category[]) => {
        this.cancelCategoryForm();
        this.alertSuccess("Categoría actualizada con éxito");
      }, (error) => {
        this.alertFailed(error.error.errors.join('; '));
      });
    } else {
      var category: Category = Object.assign({}, this.categoryForm.value);
      this.api.post('categories', category).pipe(finalize(() => { this.loading = false; })).subscribe((categories: Category[]) => {
        this.cancelCategoryForm();
        this.alertSuccess('Categoría guardada con éxito');
      }, (error) => {
        this.alertFailed(error.error.errors.join('; '));
      });
    }
  }

  onDeleteCategoryForm() {
    this.loading = true;
    var category: Category = Object.assign({}, this.categoryForm.value);
    this.api.patch('categories/delete', this.categoryForm.controls['id'].value, category).pipe(finalize(() => { this.loading = false; })).subscribe((categories: Category[]) => {
      this.cancelCategoryForm();
      this.alertSuccess('Categoría eliminada con éxito');
    });
  }

  refreshCategoriesTable() {
    this.categoriesTable.ajax.reload();
  }

  resetCategoriesTable() {
    $('#initialDate').data('datepicker').setDate(null);
    $('#finalDate').data('datepicker').setDate(null);
    $("#search").val('');
    this.categoriesTable.ajax.reload();
  }

  alertSuccess(title) {
    $.toast({
      heading: 'Realizado',
      text: title,
      showHideTransition: 'slide',
      icon: 'success',
      loaderBg: '#f96868',
      position: 'bottom-left'
    });
  }

  alertFailed(title) {
    $.toast({
      heading: 'Alerta',
      text: title,
      showHideTransition: 'slide',
      icon: 'error',
      loaderBg: '#f2a654',
      position: 'bottom-left'
    });
  }
}
