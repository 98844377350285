export class ProductPrices 
{
    id: any;

    product: String;
    
    minimumPrice: string;

    maximumPrice: string;

    countedPrice: string;
}