import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/_api/api.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ModalReturnComponent } from 'src/app/components/modal-return/modal-return.component';
import { Alerts } from 'src/app/utils/alerts';
import { DeleteModalComponent } from 'src/app/components/delete-modal/delete-modal.component';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-returns',
  templateUrl: './returns.component.html',
  styleUrls: ['./returns.component.sass']
})
export class ReturnsComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmModal')
  confirmModal: DeleteModalComponent;
  @ViewChild('deleteModal')
  deleteModal: DeleteModalComponent;
  @ViewChild('returnModal')
  returnModal: ModalReturnComponent;
  boxesFormArray: FormArray;
  notReady2Submit = false;
  returnForm: FormGroup;
  products: any[] = [];
  readOnlyMode = false;
  grades: any[] = [];
  loadByCode = true;
  loadByCSV = false;
  returnsTable: any;
  submitted = false;
  unlabeled = false;
  loadDropify: any;
  loadFile = false;
  searchBx = false;
  loading = false;
  boxCode = '';

  constructor(private api: ApiService, private formBuilder: FormBuilder) {
    this.api.getAll('productsCodes/getUnlabeledProducts').subscribe((products: any[]) => {
      this.products = products;
    });
  }

  ngOnInit() {
    this.returnForm = this.formBuilder.group({
      id: [null],
      box: [null],
      csv: [null],
      code: [null],
      returnComment: [null],
      date: [null, Validators.required],
      boxes: this.formBuilder.array([], Validators.required)
    });
    this.boxesFormArray = this.returnForm.get('boxes') as FormArray;
  }

  ngAfterViewInit(): void {
    $('#startDate,#endDate').datepicker({ autoclose: true, todayHighlight: true, enableOnReadonly: false });

    $('#date').datepicker({ autoclose: true, format: 'dd/mm/yyyy', todayHighlight: true, enableOnReadonly: false });

    $('.select2').select2({ width: '100%', theme: 'bootstrap' });

    this.loadDropify = $('.dropify').dropify({
      messages: {
        'remove': 'Eliminar el archivo',
        'error': 'Vaya, ha ocurrido un error inesperado.',
        'replace': 'Arrastra y suelta o haz clic para reemplazar el archivo',
        'default': 'Arrastra y suelta o haz clic para seleccionar el archivo'
      },
      error: {
        'fileSize': 'El archivo es demasiado grande (2MB MAX).',
        'fileExtension': 'La extensión del archivo no es la correcta (sólo archivos CSV).'
      }
    });

    this.loadDropify = this.loadDropify.data('dropify');

    this.returnsTable = $('#returns-table').DataTable({
      'ajax': {
        url: `${environment.apiUrl}/returns`,
        data: function (data) {
          data.keywords = $('#keywords').val();
          data.to = $('#final').val();
          data.from = $('#initial').val();
        }
      },
      'paging': true,
      'pageLength': 10,
      'searching': false,
      'serverSide': true,
      'lengthChange': false,
      'language': { 'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json' },
      columns: [
        { data: 'code' },
        { data: 'createdAt' },
        {
          data: 'status',
          render: (data, type, row) => {
            var label = '';
            if (row.status == 'Por Aprobar') {
              label = '<label class="badge badge-warning">Por aprobar</label>';
            } else if (row.status == 'Aprobada') {
              label = '<label class="badge badge-success">Aprobada</label>';
            } else if (row.status == 'Rechazada') {
              label = '<label class="badge" style="color: #fff; background: #e51e6b;">Rechazada</label>';
            }
            return label;
          }
        },
        {
          data: null,
          orderable: false,
          searchable: false,
          defaultContent: '',
          createdCell: function (td, cellData, rowData, row, col) {
            var content =
              `<div class="btn-group" role="group" aria-label="Acciones">
                <button type="button" class="btn btn-info return-details" title="Ver detalles">
                  <i class="mdi mdi-plus"></i>
                </button>
              `;
            if (rowData.status == 'Por Aprobar') {
              content +=
                `
                  <button type="button" class="btn btn-danger return-delete" title="Eliminar">
                    <i class="mdi mdi-delete-forever"></i>
                  </button>
                `;
            }
            content += `</div>`;
            $(td).prepend(content);
          }
        }
      ],
      drawCallback: () => {
        $('.return-details').on('click', (element) => {
          let data: any = this.returnsTable.row($(element.currentTarget).closest('tr')).data();
          this.openReturnModalInReadOnlyMode(data);
        });

        $('.return-delete').on('click', (element) => {
          let data: any = this.returnsTable.row($(element.currentTarget).closest('tr')).data();
          this.openDeleteModal(data);
        });
      }
    });

    $('#startDate').on('changeDate', function () {
      $('#startDate').val($('#startDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#startDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $('#endDate').datepicker('setStartDate', dtFormatted);
    });

    $('#endDate').on('changeDate', function () {
      $('#endDate').val($('#endDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#endDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $('#startDate').datepicker('setEndDate', dtFormatted);
    });

    $('input:radio[name="radios"]').on('change', () => {
      var value = $('input:radio[name="radios"]:checked').val();
      if (value == 1) {
        this.loadByCode = true;
        this.loadByCSV = false;
        this.unlabeled = false;
      } else if (value == 2) {
        this.loadByCode = false;
        this.loadByCSV = true;
        this.unlabeled = false;
      } else if (value == 3) {
        this.loadByCode = false;
        this.loadByCSV = false;
        this.unlabeled = true;
      }
      this.loadFile = false;
      this.searchBx = false;
      $('#boxCode-input').val('');
      this.loadDropify.clearElement();
      this.loadDropify.resetPreview();
      $('#code-select').val(null).change();
    });

    $('#boxCode-input').on('keyup', (element) => {
      this.returnForm.controls['box'].setValue(null);
      if (/^C[0-9]{7}$/i.test(element.currentTarget.value)) {
        this.searchBx = true;
        this.returnForm.controls['box'].setValue(element.currentTarget.value);
      } else {
        this.searchBx = false;
      }
    });

    $('#dropify-input').on('change', (element) => {
      this.loadFile = false;
      this.returnForm.controls['csv'].setValue(null);
      if (element.currentTarget.files.length > 0) {
        var size = element.currentTarget.files[0].size;
        var extension = element.currentTarget.value.match(/\.([^\.]+)$/)[1].toLowerCase();
        if (size <= 2000000 && extension == 'csv') {
          this.loadFile = true;
          this.returnForm.controls['csv'].setValue(element.currentTarget.files[0]);
        }
      }
    });

    $('#code-select').on('change', () => {
      var value = $('#code-select').val();
      this.grades = [];
      $('#quantity-input').val('');
      $('#grade-select').val(null).change();
      if (value) {
        var selected: any = this.products.find((product: any) => {
          return product.id == value;
        });
        if (selected.level == 'Preescolar' || selected.level == 'Secundaria') {
          this.grades = [7, 1, 2, 3];
        } else if (selected.level == 'Primaria') {
          this.grades = [7, 1, 2, 3, 4, 5, 6];
        }
      }
    });

    $('#quantity-input').on('keyup', (element) => {
      var value = element.currentTarget.value;
      $(element.currentTarget).val(value.replace(/\D/g, ''));
    });
  }

  get isUnlabeledBoxValid(): boolean {
    if ($('#code-select').val() && $('#grade-select').val() && $('#quantity-input').val())
      return true;
    return false;
  }

  openReturnModal() {
    this.returnModal.open(true);
    $('input:radio[name="radios"][value="1"]').click();
    $('#date').datepicker().datepicker('setDate', new Date());
    this.returnForm.controls['date'].setValue($('#dateInput').val().split('/').reverse().join('-'));
  }

  openReturnModalInReadOnlyMode(data: any) {
    Object.keys(data).forEach(key => {
      if (this.returnForm.controls[key]) {
        this.returnForm.controls[key].setValue(data[key]);
      }
    });
    this.readOnlyMode = true;
    $('#dateInput').val(data.createdAt);
    this.api.get('returns/findBoxes', this.returnForm.controls['id'].value).pipe(finalize(() => {
      this.returnModal.open(false);
    })).subscribe((data: any) => {
      data.forEach(element => {
        this.boxesFormArray.push(this.formBuilder.group({
          code: element.code,
          grade: element.grade,
          level: element.level,
        }));
      });
    });
  }

  closeReturnModal() {
    this.returnModal.close();
    this.returnForm.reset();
    while (this.boxesFormArray.length !== 0) {
      this.boxesFormArray.removeAt(0);
    }
    this.loadFile = false;
    this.searchBx = false;
    this.loadByCode = true;
    this.loadByCSV = false;
    this.submitted = false;
    this.readOnlyMode = false;
    this.notReady2Submit = false;
    this.loadDropify.clearElement();
    this.loadDropify.resetPreview();
    $('#boxCode-input').val('');
    $('.modal-backdrop').remove();
    $('#date').datepicker().datepicker('setDate', null);
  }

  submitReturnForm() {
    this.confirmModal.close();
    this.loading = true;
    var form: FormData = new FormData();
    form.append('_boxes', JSON.stringify(this.boxesFormArray.value));
    form.append('comment', this.returnForm.controls['returnComment'].value);
    this.api.post('returns/store', form).pipe(finalize(() => {
      this.loading = false;
      this.resetTable();
    })).subscribe((data: any) => {
      this.closeReturnModal();
      Alerts.AlertSuccess('La solicitud de devolución ha sido registrada');
    }, (error) => {
      var errors = '';
      if (error.error.error) {
        errors = error.error.message;
      } else {
        errors = error.error.errors.join('; ');
      }
      Alerts.AlertFailed(errors);
    });
  }

  openConfirmModal() {
    if (this.returnForm.invalid || this.boxesFormArray.length <= 0) {
      this.submitted = true;
      if (this.boxesFormArray.length <= 0) {
        this.notReady2Submit = true;
      }
      return;
    }
    this.confirmModal.open();
  }

  closeConfirmModal() {
    this.confirmModal.close();
  }

  openDeleteModal(data: any) {
    this.returnForm.controls['id'].setValue(parseInt(data.id, 10));
    this.boxCode = data.code;
    this.deleteModal.open();
  }

  closeDeleteModal() {
    this.returnForm.controls['id'].setValue(null);
    this.deleteModal.close();
    this.boxCode = '';
  }

  submitDeleteForm() {
    this.loading = true;
    var form: FormData = new FormData();
    form.append('id', this.returnForm.controls['id'].value);
    this.api.post('returns/delete', form).pipe(finalize(() => {
      this.loading = false;
      this.resetTable();
    })).subscribe((data: any) => {
      this.closeDeleteModal();
      Alerts.AlertSuccess('La solicitud de devolución ha sido eliminada');
    }, (error) => {
      var errors = '';
      if (error.error.error) {
        errors = error.error.message;
      } else {
        errors = error.error.errors.join('; ');
      }
      Alerts.AlertFailed(errors);
    });
  }

  searchBox(oneByOne: boolean) {
    var box = this.returnForm.controls['box'].value.toUpperCase();
    for (let index = 0; index < this.boxesFormArray.length; index++) {
      if (this.boxesFormArray.value[index].code == box) {
        Alerts.AlertFailed('La caja ' + box + ' ya fue agregada previamente');
        $('#boxCode-input').val('').trigger('keyup');
        return;
      }
    }
    this.loading = true;
    this.api.get('returns/findBox', box).pipe(finalize(() => {
      $('#boxCode-input').val('').trigger('keyup');
      if (this.boxesFormArray.length <= 0) {
        this.notReady2Submit = true;
      } else {
        this.notReady2Submit = false;
      }
      this.loading = false;
    })).subscribe((data: any) => {
      this.boxesFormArray.push(this.formBuilder.group({
        id: data.id,
        code: data.code,
        grade: data.grade,
        level: data.level,
        distributionId: data.distributionId
      }));
      if (oneByOne) {
        Alerts.AlertInfo('La caja ha sido agregada');
      }
    }, (error) => {
      var errors = '';
      if (error.error.error) {
        errors = error.error.message;
      } else {
        errors = error.error.errors.join('; ');
      }
      Alerts.AlertFailed(errors);
    });
  }

  searchBoxes() {
    this.loading = true;
    var form: FormData = new FormData();
    form.append('file', this.returnForm.controls['csv'].value);
    form.append('_boxes', JSON.stringify(this.boxesFormArray.value));
    this.api.post('returns/findBoxes', form).pipe(finalize(() => {
      this.returnForm.controls['csv'].setValue(null);
      this.loadDropify.clearElement();
      this.loadDropify.resetPreview();
      this.loadFile = false;
      this.loading = false;
    })).subscribe((data: any) => {
      data.forEach(element => {
        this.returnForm.controls['box'].setValue(element);
        this.searchBox(false);
      });
      Alerts.AlertInfo(data.length + ' cajas han sidos agregadas');
    }, (error) => {
      var errors = '';
      if (error.error.error) {
        errors = error.error.message;
      } else {
        errors = error.error.errors.join('; ');
      }
      Alerts.AlertFailed(error);
    });
  }

  validateBoxes() {
    var quantity = $('#quantity-input').val();
    var form: FormData = new FormData();
    form.append('product', $('#code-select').val());
    form.append('grade', $('#grade-select').val());
    this.api.post('returns/validateBoxes', form).subscribe((data: any[]) => {
      let boxesInReturn = this.boxesFormArray.value;
      let newBoxes: any[] = [];

      for (const [key, value] of Object.entries(data))
        if (!boxesInReturn.some(box => box.code === value.code))
          newBoxes.push(value);

      if (newBoxes.length <= 0) {
        Alerts.AlertFailed('No puede devolver cajas de este producto.');
      } else if (newBoxes.length < quantity) {
        Alerts.AlertFailed('Sólo puede devolver ' + newBoxes.length + ' cajas de este producto.');
      } else {
        for (let i = 0; i < quantity; i++) {
          this.boxesFormArray.push(this.formBuilder.group({
            id: newBoxes[i].id,
            code: newBoxes[i].code,
            grade: newBoxes[i].grade,
            level: newBoxes[i].level,
            distributionId: newBoxes[i].distributionId
          }));
        }

        $('#code-select').val(null).change();

        Alerts.AlertInfo(quantity + ' cajas han sidos agregadas');
      }
    }, (error) => {
      var errors = '';
      if (error.error.error) {
        errors = error.error.message;
      } else {
        errors = error.error.errors.join('; ');
      }
      Alerts.AlertFailed(errors);
    });
  }

  removeBox(index: number) {
    this.boxesFormArray.removeAt(index);
    if (this.boxesFormArray.length <= 0) {
      this.notReady2Submit = true;
    } else {
      this.notReady2Submit = false;
    }
  }

  refreshTable() {
    this.returnsTable.ajax.reload();
  }

  resetTable() {
    $('#keywords').val('');
    $('#final').val('');
    $('#initial').val('');
    this.returnsTable.ajax.reload();
  }
}