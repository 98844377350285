declare var $: any;

export class Alerts{
  static AlertSuccess(title) {
    $.toast({
      heading: 'Realizado',
      text: title,
      showHideTransition: 'slide',
      icon: 'success',
      loaderBg: '#f96868',
      position: 'bottom-left'
    });
  }

  static AlertFailed(title) {
    $.toast({
      heading: 'Error',
      text: title,
      showHideTransition: 'slide',
      icon: 'error',
      loaderBg: '#f2a654',
      position: 'bottom-left'
    });
  }

  static AlertInfo(title) {
    $.toast({
      heading: 'Importante',
      text: title,
      showHideTransition: 'slide',
      icon: 'info',
      loaderBg: '#02abfe',
      position: 'bottom-left'
    });
  }

  static AlertWarning(title) {
    $.toast({
      heading: 'Advertencia',
      text: title,
      showHideTransition: 'slide',
      icon: 'warning',
      loaderBg: '#fbbc06',
      position: 'bottom-left',
      hideAfter: 10000,
    });
  }

}