import { Component, OnInit, AfterViewInit, ViewChild, Input, ElementRef } from '@angular/core';
import { Distribution } from 'src/app/models/distribution';
import { ModalReturnComponent } from 'src/app/components/modal-return/modal-return.component';
import { DeleteModalComponent } from 'src/app/components/delete-modal/delete-modal.component';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/_api/api.service';
import { Product } from 'src/app/models/products';
import { DeleteModalReturnComponent } from 'src/app/components/delete-modal-return/delete-modal-return.component';
import { finalize } from 'rxjs/operators';
import { DistributionProducts } from 'src/app/models/distribution-products';
import { Order } from 'src/app/models/order';
import { formatDate } from '@angular/common';
import { OrderAdditionalProducts } from 'src/app/models/order-additional-products';
import { OrderProducts } from 'src/app/models/order-products';
import { OrderProductsToSupply } from 'src/app/models/order-products-to-supply';
import { Box } from 'src/app/models/box';
import { BookBox } from 'src/app/models/book-box';
import { DistributionBox } from 'src/app/models/distribution-box';
import { OrderAdditionalProductsToSupply } from 'src/app/models/order-additional-products-to-supply';
import { Alerts } from 'src/app/utils/alerts';
import { Season } from 'src/app/models/season';
import { Tracking } from 'src/app/models/trackings';
import { DistributionBoxWithoutLabels } from 'src/app/models/distribution-box-without-labels';
import { CodesProducts } from 'src/app/models/codes-products';
import { environment } from 'src/environments/environment';
declare var $: any;
import { saveAs } from 'file-saver';
import { DataService } from 'src/app/services/data.service';
import { User } from 'src/app/models';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-distributions',
  templateUrl: './distributions.component.html',
  styleUrls: ['./distributions.component.sass']
})
export class DistributionsComponent implements OnInit, AfterViewInit {
  @ViewChild('addToStock')
  private addToStockModal: DeleteModalReturnComponent;
  @ViewChild('addTracking')
  private addTrackingModal: ModalReturnComponent;
  @ViewChild('addProductWithOutLabelToStock')
  private addProductWithOutLabelToStock: ModalReturnComponent;
  @ViewChild('create')
  private createModal: ModalReturnComponent;
  @ViewChild('delete')
  private deleteModal: DeleteModalComponent;
  @ViewChild('show')
  private showModal: ModalReturnComponent;
  @Input('title') titleModal: string;

  @ViewChild('jsonDateInput') jsonDateInput!: ElementRef;

  // Forms
  distributionDeleteForm: FormGroup;
  distributionForm: FormGroup;
  addTrackingForm: FormGroup;

  // Variables for Order data
  showDistributorMunicipality: string = '';
  showDistributorState: string = '';
  showShippingAddress: string = '';
  showDistributorName: string = '';
  showPaymentMethod: string = '';
  showOrderStatus: string = '';
  showOrderType: string = '';
  showCodes: boolean = false;
  createdAt: string = '';

  // Arrays for Order products
  additionalProductsFormArray: FormArray;
  productsOfOrderFormArray: FormArray;
  otherProductsFormArray: FormArray;
  productsFormArray: FormArray;

  // Array for Tracking
  trackingsFormArray: FormArray;


  distributionsTable: any;
  isShowingDistribution: boolean = false;
  isConfirmedDistribution: boolean = false;
  isConfirmedCancelDistribution: boolean = false;
  loading: boolean = false;
  distributionProducts: any[] = [];

  distributionProductsWithOutLabels: any[] = [];

  orderProducts: OrderProductsToSupply[] = [];
  productsOfOrder: OrderProducts[] = [];

  orderAdditionalProducts: OrderAdditionalProductsToSupply[] = [];
  additionalProductsOfDistribution: OrderAdditionalProducts[] = [];

  distributionOtherProducts: any[] = [];
  trackings: Tracking[] = [];

  orders: Order[] = [];
  products: Product[] = [];
  currentOrder: Order = new Order();
  currentSeason: Season = new Season();
  currentProduct: Product = new Product();
  currentTracking: Tracking = new Tracking();
  currentBox: Box = new Box();
  currentDistribution: Distribution = new Distribution();
  requestQuantity: number = 0;
  currentStockOfProductWithOutLabel: number = 0;
  currentStockOfProduct: number = 0;
  requestIndex: number = 0;
  isAddStockToAdditionalProducts = false;
  isAddStockToProductsWithOutLabels = false;
  shippingDate: string = '';

  isDownloadPDF: boolean = false;
  isAdminTest: boolean = false;
  currentUser: User;
  showOrder: string = '';

  submitted = false;
  includes: boolean = false;
  continueDistribution: boolean = true;
  showBoxes: BookBox[] = [];
  showAdditionalProducts: DistributionProducts[] = [];
  showOtherProducts: DistributionProducts[] = [];

  constructor(private api: ApiService, private builder: FormBuilder, private deleteBuilder: FormBuilder, private trackingBuilder: FormBuilder,private dataService: DataService, private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.distributionForm = this.builder.group({
      id: [null],
      products: this.builder.array([]),
      idOrder: ['', Validators.required],
      otherProducts: this.builder.array([]),
      productsOfOrder: this.builder.array([]),
      additionalProducts: this.builder.array([]),
    });

    this.productsFormArray = this.distributionForm.get('products') as FormArray;
    this.otherProductsFormArray = this.distributionForm.get('otherProducts') as FormArray;
    this.productsOfOrderFormArray = this.distributionForm.get('productsOfOrder') as FormArray;
    this.additionalProductsFormArray = this.distributionForm.get('additionalProducts') as FormArray;

    this.distributionDeleteForm = this.deleteBuilder.group({
      id: [null]
    });

    this.addTrackingForm = this.trackingBuilder.group({
      id: [null],
      trackings: this.trackingBuilder.array([], Validators.required)
    });

    this.trackingsFormArray = this.addTrackingForm.get('trackings') as FormArray;

    this.api.getAll('orders/getAll').subscribe((orderData: Order[]) => {
      this.orders = orderData;
    });

    this.api.getAll('products/getAll').subscribe((productsData: Product[]) => {
      this.products = productsData;
    });
    this.dataService.getToken();

    this.currentUser = this.auth.user;
    if(this.currentUser.id == "13")
      this.isAdminTest = true;
  }

  ngAfterViewInit(): void {
    $('#initialDate, #finalDate').datepicker({ enableOnReadonly: true, todayHighlight: true, autoclose: true });

    $('#jsonDate').datepicker({ enableOnReadonly: true, todayHighlight: true, autoclose: true });

    $('#shippingDate').datepicker({ enableOnReadonly: true, todayHighlight: true, format: 'dd/mm/yyyy', autoclose: true, endDate: '+0d' });

    $('#order').select2({ width: '100%', theme: 'bootstrap', placeholder: 'Selecciona el pedido', dropdownParent: $('#DistributionModal') });

    $('#product').select2({ width: '100%', theme: 'bootstrap', placeholder: 'Selecciona el producto', dropdownParent: $('#DistributionModal') });

    this.distributionsTable = $('#distributions-table').DataTable({
      'ajax': {
        url: `${environment.apiUrl}/distributions`,
        data: function (data) {
          data.to = $('#finalDate').val();
          data.from = $('#initialDate').val();
          data.searchData = $('#search').val();
        }

      },
      'paging': true,
      'searching': false,
      'serverSide': true,
      'lengthChange': false,
      'language': { 'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json' },
      columns: [
        { data: 'id' },
        {
          data: 'idOrder',
          render: (data, type, row, meta) => {
            return row['idOrder']['id'];
          }
        },
        {
          data: 'idOrder',
          render: (data, type, row, meta) => {
            return row['idOrder']['idDistributor']['name'] + ' ' + row['idOrder']['idDistributor']['paternalSurname'] + ' ' + row['idOrder']['idDistributor']['maternalSurname'];
          }
        },
        { data: 'createdAt' },
        {
          data: null,
          orderable: false,
          searchable: false,
          defaultContent: '',
          createdCell: function (td, cellData, rowData, row, col) {
            var actions = ``;
            if (rowData['statusDistribution'] == 'Parcial') {
              actions += `<label class='badge badge-warning text-white'>Parcial</label>`;
            } else {
              actions += `<label class='badge badge-success'>Completo</label>`;
            }
            $(td).prepend(actions);
          }
        },
        {
          data: null,
          orderable: false,
          searchable: false,
          defaultContent: '',
          createdCell: function (td, cellData, rowData, row, col) {
            var actions =
              `<div class='btn-group' role='group' aria-label='Acciones'>
              <button type='button' class='btn btn-info distribution-show' title='Ver Detalles'>
                <i class='mdi mdi-plus text-white'></i>
              </button>
              <button type='button' class='btn btn-danger distribution-delete' title='Eliminar'>
                <i class='mdi mdi-delete-forever'></i>
              </button>
              <button type='button' class='btn btn-primary distribution-trackings' title='Guías de Envío'>
                <i class='mdi mdi-truck text-white'></i>
              </button>
              <button type='button' class='btn btn-secondary distribution-download' title='Descargar PDF'>
                <i class='mdi mdi-file-pdf text-white'></i>
              </button>
              <button type='button' class='btn btn-warning distribution-send' title='Enviar correo'>
              <i class="mdi   mdi-email-outline  text-white"></i>
            </button>
            </div>`;
            $(td).prepend(actions);
          }
        }
      ],
      drawCallback: () => {
        $('.distribution-show').on('click', (element) => {
          let distribution: Distribution = this.distributionsTable.row($(element.currentTarget).closest('tr')).data();
          this.showDistribution(distribution);
        });

        $('.distribution-delete').on('click', (element) => {
          let distribution: Distribution = this.distributionsTable.row($(element.currentTarget).closest('tr')).data();
          this.deleteDistribution(distribution);
        });

        $('.distribution-download').on('click', (element) => {
          let distribution: Distribution = this.distributionsTable.row($(element.currentTarget).closest('tr')).data();
          this.downloadDistribution(distribution);
        });
        
        $('.distribution-send').on('click', (element) => {
          let distribution: Distribution = this.distributionsTable.row($(element.currentTarget).closest('tr')).data();
          this.sendDistribution(distribution);
        });

        $('.distribution-trackings').on('click', (element) => {
          this.currentDistribution = this.distributionsTable.row($(element.currentTarget).closest('tr')).data();
          this.addTracking(this.currentDistribution);
        });
      }
    });

    $('#initialDate').on('changeDate', function () {
      $('#initialDate').val($('#initialDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#initialDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $('#finalDate').datepicker('setStartDate', dtFormatted);
    });

    $('#finalDate').on('changeDate', function () {
      $('#finalDate').val($('#finalDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#finalDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $('#initialDate').datepicker('setEndDate', dtFormatted);
    });

    $('#shippingDate').on('change', () => {
      this.shippingDate = $('#shippingDate').val().split('/').reverse().join('-');
    });

    $('#product').on('change', () => {
      if ($('#product').val() != '') {
        var code = $('#product').val();
        this.products.forEach(element => {
          if (code == element['id']) {
            this.currentProduct = element;
          }
        });
      }
    });

    $('#order').on('change', () => {
      if ($('#order').val()) {
        this.currentOrder = this.orders.find((order: Order) => {
          return order.id == $('#order').val();
        });

        if (this.currentOrder.idCodeProduct != null) {
          this.currentSeason = this.currentOrder.idCodeProduct.idSeason;
        } else {
          this.currentSeason = null;
        }

        this.distributionForm.controls['idOrder'].setValue(this.currentOrder.id);

        this.showShippingAddress = this.currentOrder.shippingAddress + ', ' + this.currentOrder.shippingColony + ', ' + this.currentOrder.shippingTown + ', ' + this.currentOrder.shippingState;
        this.showDistributorName = this.currentOrder.idDistributor.name + ' ' + this.currentOrder.idDistributor.paternalSurname + ' ' + this.currentOrder.idDistributor.maternalSurname;
        this.showDistributorMunicipality = this.currentOrder.idDistributor.town;
        this.showDistributorState = this.currentOrder.idDistributor.state;
        this.showPaymentMethod = this.currentOrder.paymentMethod;
        this.showOrderStatus = this.currentOrder.orderStatus;
        this.showOrderType = this.currentOrder.type;

        this.resetFormArray();

        this.api.get('orders/getProductsToSupplyOfOrder', this.currentOrder.id).subscribe((productsOrderData: OrderProducts[]) => {
          this.api.get('orders/additionalProductsOfOrder', this.currentOrder.id).subscribe((additionalProductsOrderData: OrderAdditionalProducts[]) => {
            this.api.get('distributions/productsOfDistributions', this.currentOrder.id).subscribe((productsDistributionData: BookBox[]) => {
              this.api.get('distributions/additionalProductsOfDistributions', this.currentOrder.id).subscribe((additionalProductsDistributionData: DistributionProducts[]) => {
                this.additionalProductsOfDistribution = additionalProductsOrderData;
                this.productsOfOrder = productsOrderData;

                this.productsOfOrder.forEach((product: OrderProducts) => {
                  var orderProduct = new OrderProductsToSupply();
                  orderProduct.idProduct = product.idOrder.idCodeProduct;
                  orderProduct.description = product.idOrder.idCodeProduct.description + ' ~ ' + product.idOrder.idCodeProduct.idSeason.name;
                  orderProduct.level = product.idOrder.level;
                  orderProduct.quantity = product.quantity;
                  if (product.grade == '7')
                    product.grade = 'UNICO';
                  orderProduct.grade = product.grade;
                  orderProduct.quantitySupplied = 0;

                  this.orderProducts.push(orderProduct);

                  this.productsOfOrderFormArray.push(this.builder.group({
                    grade: product.grade,
                    description: orderProduct.description,
                    quantity: [0, [Validators.required, Validators.min(0)]]
                  }));
                });

                this.additionalProductsOfDistribution.forEach((product: OrderAdditionalProducts) => {
                  var orderAdditionalProduct = new OrderAdditionalProductsToSupply();
                  orderAdditionalProduct.idProduct = product.idProduct;
                  orderAdditionalProduct.quantity = product.quantity;
                  orderAdditionalProduct.quantitySupplied = 0;

                  this.orderAdditionalProducts.push(orderAdditionalProduct);

                  this.additionalProductsFormArray.push(this.builder.group({
                    id: product.idProduct.id,
                    quantity: [0, [Validators.required, Validators.min(0)]],
                  }));
                });

                productsDistributionData.forEach((box: BookBox) => {
                  var item = this.orderProducts.find(element => element.level == box.idBook.level && element.grade == box.idBook.grade);
                  if (item) {
                    var index = this.orderProducts.findIndex(element => element.level == box.idBook.level && element.grade == box.idBook.grade);
                    if (this.currentOrder.idCodeProduct.physicalLabels) {
                      this.orderProducts[index].quantity = this.orderProducts[index].quantity + 1;
                      this.orderProducts[index].quantitySupplied = this.orderProducts[index].quantitySupplied + 1;
                    }
                  }
                });

                additionalProductsDistributionData.forEach((product: DistributionProducts) => {
                  var item = this.orderAdditionalProducts.find(element => element.idProduct.id == product.idProduct.id);
                  if (item) {
                    var index = this.orderAdditionalProducts.findIndex(element => element.idProduct.id == product.idProduct.id);
                    this.orderAdditionalProducts[index].quantitySupplied = this.orderAdditionalProducts[index].quantitySupplied + product.quantity;
                  }
                });
              });
            });
          });
        });
      }
    });

    $('#order').val(null).trigger('change');

    $('#product').val(null).trigger('change');
  }

  onKeydown(event): void {
	
    this.continueDistribution = false;
    var code = event.target.value;
    console.log(code);
    if (event.key === 'Enter' && /^C[0-9]{7}$/i.test(code)) {
      this.api.get('boxes', code).subscribe((distributionBox: Box) => {
        this.api.get('boxes/getLevelAndGradeOfBox', code).subscribe((bookBox: BookBox) => {
          if (distributionBox.status == 'Distribuido' || distributionBox.status == 'Merma') {
            Alerts.AlertFailed('La caja ' + code + ' ya pertenece a otra distribución');
          } else if (this.currentSeason.id != bookBox.idBox.idSeason.id) {
            Alerts.AlertFailed('La caja ' + code + ' pertenece a una temporada diferente a la del pedido');
          } else if (!this.orderProducts.some(element => element.level == bookBox.idBook.level && element.grade == bookBox.idBook.grade)) {
            Alerts.AlertFailed('El nivel o grado de la caja ' + code + ' no corresponde al pedido');
          } else if (this.orderProducts.some(element => element.level == bookBox.idBook.level && element.grade == bookBox.idBook.grade && element.quantity == element.quantitySupplied)) {
            Alerts.AlertFailed('Las cajas de ' + bookBox.idBook.grade + '° de ' + bookBox.idBook.level + ' se han completado');
          } else {
            this.continueDistribution = true;
          }
          if (this.continueDistribution) {
            this.addToDistribution(code);
          }
          $('#box-code').val('');
        });
      }, () => {
        Alerts.AlertFailed('La caja con código ' + code + ' no fue encontrada');
        $('#box-code').val('');
      });
    } else {
		 if(event.key ==='Enter' && code.includes("CT")){
			this.api.get('boxes',code).subscribe((distributionBox: Box) =>{
				if(distributionBox == null){
			        Alerts.AlertFailed('La caja con código ' + code + ' no fue encontrada');
	        		$('#box-code').val('');
	        		return;
				}
				console.log(distributionBox.code);
				var distributionBoxCode = distributionBox.code;
				this.api.get('boxes/getLevelAndGradeOfBox',distributionBoxCode).subscribe((bookBox: BookBox) =>{
					if (distributionBox.status == 'Distribuido' || distributionBox.status == 'Merma') {
				    	Alerts.AlertFailed('La caja ' + code + ' ya pertenece a otra distribución');
				    } else if (this.currentSeason.id != bookBox.idBox.idSeason.id) {
				        Alerts.AlertFailed('La caja ' + code + ' pertenece a una temporada diferente a la del pedido');
		            } else if (!this.orderProducts.some(element => element.level == bookBox.idBook.level && element.grade == bookBox.idBook.grade)) {
			            Alerts.AlertFailed('El nivel o grado de la caja ' + code + ' no corresponde al pedido');
  		            } else if (this.orderProducts.some(element => element.level == bookBox.idBook.level && element.grade == bookBox.idBook.grade && element.quantity == element.quantitySupplied)) {
				        Alerts.AlertFailed('Las cajas de ' + bookBox.idBook.grade + '° de ' + bookBox.idBook.level + ' se han completado');
				    } else {
				        this.continueDistribution = true;
				    }
				    if (this.continueDistribution) {
				        this.addToDistribution(distributionBoxCode);
				    }
				    $('#box-code').val('');
				});
			}, () => {
	        Alerts.AlertFailed('La caja con código ' + code + ' no fue encontrada');
	        $('#box-code').val('');
	      });
        }else{
			console.log("No es enter");
			return;
        }
     /*if (event.key === 'Enter' && !(/^C[0-9]{7}$/i.test(code))) {
      }else{
        Alerts.AlertFailed('Ingresa un código de caja válido');
        $('#box-code').val('');
      }*/
    }
  }

  onQuantityChange(event, index: number): void {
    this.api.get('products', this.orderAdditionalProducts[index].idProduct.id).subscribe((product: Product) => {
      //this.currentProduct = this.orderAdditionalProducts[index].idProduct;
      this.currentProduct = product;
      let itemIndex = this.distributionOtherProducts.findIndex(item => item.id == this.currentProduct.id);
      if (itemIndex != -1) {
        this.currentStockOfProduct = this.currentProduct.stock - this.otherProductsFormArray.at(itemIndex).get('quantity').value;
        if (this.currentStockOfProduct < 0)
          this.currentStockOfProduct = 0;
      } else {
        this.currentStockOfProduct = this.currentProduct.stock;
      }

      if (!event.target.value) {
        this.additionalProductsFormArray.at(index).get('quantity').setValue('0');
      }
      else {
        if (event.target.value < 1) {
          this.additionalProductsFormArray.at(index).get('quantity').setValue('0');
        }
        if (+event.target.value > (this.orderAdditionalProducts[index].quantity - this.orderAdditionalProducts[index].quantitySupplied)) {
          this.additionalProductsFormArray.at(index).get('quantity').setValue(this.orderAdditionalProducts[index].quantity - this.orderAdditionalProducts[index].quantitySupplied);
          Alerts.AlertFailed('Sólo puede surtir un máximo de ' + (this.orderAdditionalProducts[index].quantity - this.orderAdditionalProducts[index].quantitySupplied) + ' productos');
        }
        if (+this.currentStockOfProduct < +event.target.value) {
          this.isAddStockToAdditionalProducts = true;
          this.requestQuantity = event.target.value;
          this.requestIndex = index;
          this.addToStockModal.open();
        }
      }
    });
  }

  onQuantityProductWithOutLabelChange(event, index: number): void {
    var isNew = true;
    if (!event.target.value) {
      this.productsOfOrderFormArray.at(index).get('quantity').setValue('0');
    }
    else {
      this.api.getAll('boxes/getStockOfProduct', { code_product_id: this.orderProducts[index].idProduct.id, grade: this.orderProducts[index].grade }).subscribe((stock: number) => {
        this.currentStockOfProductWithOutLabel = stock;
        if (event.target.value < 1) {
          this.productsOfOrderFormArray.at(index).get('quantity').setValue('0');
        }
        if (+event.target.value > this.orderProducts[index].quantity) {
          this.productsOfOrderFormArray.at(index).get('quantity').setValue(this.orderProducts[index].quantity);
          Alerts.AlertFailed('Sólo puede surtir un máximo de ' + this.orderProducts[index].quantity + ' cajas');

          //add products to array 
          var boxDistribution = new DistributionBoxWithoutLabels();
          boxDistribution.idProduct = this.orderProducts[index].idProduct.id;
          boxDistribution.grade = this.orderProducts[index].grade;
          boxDistribution.level = this.orderProducts[index].level;
          boxDistribution.quantity = this.productsOfOrderFormArray.at(index).get('quantity').value;

          this.distributionProductsWithOutLabels.forEach((box: DistributionBoxWithoutLabels) => {
            if (this.orderProducts[index].idProduct.id == box.idProduct && this.orderProducts[index].grade == box.grade) {
              let itemIndex = this.distributionProductsWithOutLabels.findIndex(item => item.idProduct == boxDistribution.idProduct && item.grade == boxDistribution.grade);
              this.distributionProductsWithOutLabels[itemIndex] = boxDistribution;
              this.orderProducts[index].quantitySupplied = boxDistribution.quantity;
              isNew = false;
            }
          });
          if (isNew == true) {
            this.distributionProductsWithOutLabels.push(boxDistribution);
            this.orderProducts[index].quantitySupplied = boxDistribution.quantity;
          }
        }
        if (+this.currentStockOfProductWithOutLabel < +event.target.value) {
          this.isAddStockToProductsWithOutLabels = true;
          this.requestQuantity = event.target.value;
          this.requestIndex = index;
          this.addProductWithOutLabelToStock.open(true);
        }
        else {
          //add products to array 
          var boxDistribution = new DistributionBoxWithoutLabels();
          boxDistribution.idProduct = this.orderProducts[index].idProduct.id;
          boxDistribution.grade = this.orderProducts[index].grade;
          boxDistribution.level = this.orderProducts[index].level;
          boxDistribution.quantity = this.productsOfOrderFormArray.at(index).get('quantity').value;

          this.distributionProductsWithOutLabels.forEach((box: DistributionBoxWithoutLabels) => {
            if (this.orderProducts[index].idProduct.id == box.idProduct && this.orderProducts[index].grade == box.grade) {
              let itemIndex = this.distributionProductsWithOutLabels.findIndex(item => item.idProduct == boxDistribution.idProduct && item.grade == boxDistribution.grade);
              this.distributionProductsWithOutLabels[itemIndex] = boxDistribution;
              this.orderProducts[index].quantitySupplied = boxDistribution.quantity;
              isNew = false;
            }
          });

          if (isNew == true) {
            this.distributionProductsWithOutLabels.push(boxDistribution);
            this.orderProducts[index].quantitySupplied = boxDistribution.quantity;
          }
        }
      });
    }
  }

  onQuantityOtherProductChange(event, index: number): void {
    this.api.get('products', this.distributionOtherProducts[index].id).subscribe((product: Product) => {
      this.currentProduct = product;
      //this.currentProduct = this.distributionOtherProducts[index];
      let itemIndex = this.orderAdditionalProducts.findIndex(item => item.idProduct.id == this.currentProduct.id);

      if (itemIndex != -1) {
        this.currentStockOfProduct = this.currentProduct.stock - this.additionalProductsFormArray.at(itemIndex).get('quantity').value;
        if (this.currentStockOfProduct < 0)
          this.currentStockOfProduct = 0;
      } else {
        this.currentStockOfProduct = this.currentProduct.stock;
      }

      if (!event.target.value) {
        this.otherProductsFormArray.at(index).get('quantity').setValue('0');
      }
      else {
        if (event.target.value < 1) {
          this.otherProductsFormArray.at(index).get('quantity').setValue('0');
        }
        if (+this.currentStockOfProduct < +event.target.value) {
          this.isAddStockToAdditionalProducts = false;
          this.requestQuantity = event.target.value;
          this.requestIndex = index;
          this.addToStockModal.open();
        }
      }

    });
  }

  anyBox(): boolean {
    if (this.orderProducts.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  anyShowBox(): boolean {
    if (this.showBoxes.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  anyAdditionalProducts(): boolean {
    if (this.orderAdditionalProducts.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  anyShowAdditionalProducts(): boolean {
    if (this.showAdditionalProducts.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  anyProducts(): boolean {
    if (this.products.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  anyShowOtherProducts(): boolean {
    if (this.showOtherProducts.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  anyTracking(): boolean {
    var trackingNumber = $('#trackingNumber').val();
    if (this.shippingDate != '' && trackingNumber != '') {
      return true;
    } else {
      return false;
    }
  }

  isInValidForm(): boolean {
    if (this.distributionForm.valid && ((this.productsFormArray.valid && this.productsFormArray.length > 0) || (this.additionalProductsFormArray.valid && this.additionalProductsFormArray.length > 0) || this.distributionProductsWithOutLabels.length > 0)) {
      return false;
    } else {
      return true;
    }
    /*
    if (this.distributionForm.valid && this.productsFormArray.length > 0 && this.additionalProductsFormArray.length > 0) {
      return false;
    } else if (this.distributionForm.valid && this.productsFormArray.length < 1 && this.additionalProductsFormArray.length > 0) {
      for (var i = 0; i < this.additionalProductsOfDistribution.length; i++) {
        if (this.additionalProductsFormArray.at(i).get('quantity').value == 0) {
          return true;
        }
      }
    } else if (this.distributionForm.valid && this.productsFormArray.length > 0 && this.additionalProductsFormArray.length < 1) {
      return false;
    } else {
      return true;
    }*/
  }

  resetFormArray(): void {
    while (this.productsFormArray.length !== 0) {
      this.productsFormArray.removeAt(0)
    }
    this.distributionProducts = [];

    while (this.productsOfOrderFormArray.length !== 0) {
      this.productsOfOrderFormArray.removeAt(0)
    }
    this.orderProducts = [];

    while (this.additionalProductsFormArray.length !== 0) {
      this.additionalProductsFormArray.removeAt(0)
    }
    this.orderAdditionalProducts = [];

    while (this.otherProductsFormArray.length !== 0) {
      this.otherProductsFormArray.removeAt(0)
    }

    this.productsOfOrder = [];
    this.additionalProductsOfDistribution = [];
    this.distributionOtherProducts = [];
    this.distributionProductsWithOutLabels = [];
  }

  // New Distribution
  createDistribution(): void {
    this.api.getAll('orders/getAll').subscribe((orders: Order[]) => {
      this.orders = orders;
      this.createModal.open(true);
      this.cancelDistributionForm();
      this.isShowingDistribution = false;
      this.createdAt = formatDate(new Date(), 'dd/MM/yyyy', 'en');
    });
  }

  confirmDistribution(): void {
    this.isConfirmedDistribution = true;
  }

  cancelDistributionForm(): void {
    this.resetFormArray();
    this.showOrderType = '';
    this.createModal.close();
    this.showOrderStatus = '';
    $('#products-code').val('');
    this.showPaymentMethod = '';
    this.distributionForm.reset();
    this.showDistributorName = '';
    this.showShippingAddress = '';
    this.showDistributorState = '';
    this.currentSeason = new Season();
    this.isConfirmedDistribution = false;
    this.isConfirmedCancelDistribution = false;
    this.showDistributorMunicipality = '';
    $('#order').val(null).trigger('change');
    $('#product').val(null).trigger('change');
    this.showCodes = false;
    this.currentStockOfProduct = 0;
  }

  cancelConfirmDistribution(): void {
    this.isConfirmedDistribution = false;
  }

  cancelConfirmationOfCancelDistribution(): void {
    this.isConfirmedCancelDistribution = false;
  }

  cancelDistribution(): void {
    this.isConfirmedCancelDistribution = true;
  }


  // Stock
  AddToStock(): void {
    this.loading = true;
    var currentStock = this.requestQuantity - this.currentStockOfProduct;
    this.api.patch('products/addToStockOnDistribution', this.currentProduct.id, currentStock).subscribe((productsData: Product[]) => {
      Alerts.AlertSuccess('Se agregaron los productos al stock');
      this.addToStockModal.close();
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  cancelAddToStock(): void {
    this.addToStockModal.close();
    this.currentStockOfProduct = 0;
    if (this.isAddStockToAdditionalProducts) {
      this.additionalProductsFormArray.at(this.requestIndex).get('quantity').setValue('0');
    }
    else if (this.isAddStockToProductsWithOutLabels) {
      this.addProductWithOutLabelToStock.close();
      this.productsOfOrderFormArray.at(this.requestIndex).get('quantity').setValue('0');
    }
    else {
      this.otherProductsFormArray.at(this.requestIndex).get('quantity').setValue('0');
    }
  }

  // Stock
  AddProductWithOutLabelToStock(): void {
    this.loading = true;
    var isNew = true;
    var currentStock = this.requestQuantity - this.currentStockOfProductWithOutLabel;
    var formData: FormData = new FormData();

    formData.append('product', this.orderProducts[this.requestIndex].idProduct.id);
    formData.append('grade', this.orderProducts[this.requestIndex].grade);
    formData.append('new_stock', currentStock.toString());

    this.api.post("boxes/addCodeProductsToStock", formData).pipe(finalize(() => {this.loading = false;})).subscribe((productsData: any[]) => {
      //add products to array 
      var boxDistribution = new DistributionBoxWithoutLabels();
      boxDistribution.idProduct = this.orderProducts[this.requestIndex].idProduct.id;
      boxDistribution.grade = this.orderProducts[this.requestIndex].grade;
      boxDistribution.level = this.orderProducts[this.requestIndex].level;
      boxDistribution.quantity = this.productsOfOrderFormArray.at(this.requestIndex).get('quantity').value;

      this.distributionProductsWithOutLabels.forEach((box: DistributionBoxWithoutLabels) => {
        if (this.orderProducts[this.requestIndex].idProduct.id == box.idProduct && this.orderProducts[this.requestIndex].grade == box.grade) {
          let itemIndex = this.distributionProductsWithOutLabels.findIndex(item => item.idProduct == boxDistribution.idProduct && item.grade == boxDistribution.grade);
          this.distributionProductsWithOutLabels[itemIndex] = boxDistribution;
          this.orderProducts[this.requestIndex].quantitySupplied = boxDistribution.quantity;
          isNew = false;
        }
      });

      if (isNew == true) {
        this.distributionProductsWithOutLabels.push(boxDistribution);
        this.orderProducts[this.requestIndex].quantitySupplied = boxDistribution.quantity;
      }
      this.addProductWithOutLabelToStock.close();
      Alerts.AlertSuccess('Se agregaron los productos al stock');
    });
  }

  // Show Distribution
  showDistribution(distribution: Distribution): void {
    this.cancelDistributionForm();

    this.showShippingAddress = distribution.idOrder.shippingAddress + ', ' + distribution.idOrder.shippingColony + ', ' + distribution.idOrder.shippingTown + ', ' + distribution.idOrder.shippingState;
    this.showDistributorName = distribution.idOrder.idDistributor.name + ' ' + distribution.idOrder.idDistributor.paternalSurname + ' ' + distribution.idOrder.idDistributor.maternalSurname;
    this.showDistributorMunicipality = distribution.idOrder.idDistributor.town;
    this.showDistributorState = distribution.idOrder.idDistributor.state;
    this.showPaymentMethod = distribution.idOrder.paymentMethod;
    this.showOrderStatus = distribution.idOrder.orderStatus;
    this.showOrderType = distribution.idOrder.type;
    this.showOrder = distribution.idOrder.id;
    this.createdAt = distribution.createdAt;
    if (distribution.idOrder.idCodeProduct) {
      this.showCodes = distribution.idOrder.idCodeProduct.physicalLabels
    } else {
      this.showCodes = false;
    }

    this.api.get('distributions/boxesOfDistribution', distribution.id).subscribe((boxesData: BookBox[]) => {
      this.api.get('distributions/additionalProductsOfDistribution', distribution.id).subscribe((additionalProducts: DistributionProducts[]) => {
        this.api.get('distributions/otherProductsOfDistribution', distribution.id).subscribe((otherProducts: DistributionProducts[]) => {
          this.showAdditionalProducts = additionalProducts;
          this.showOtherProducts = otherProducts;
          this.isShowingDistribution = true;
          this.showBoxes = boxesData;
          this.showModal.open(false);
        });
      });
    });
  }

  closeShowForm(): void {
    this.showDistributorMunicipality = '';
    this.showAdditionalProducts = [];
    this.showDistributorState = '';
    this.showShippingAddress = '';
    this.showDistributorName = '';
    this.showPaymentMethod = '';
    this.showOtherProducts = [];
    this.showOrderStatus = '';
    this.showOrderType = '';
    this.showOrder = '';
    this.showBoxes = [];

    this.showModal.close();
  }

  // Delete Distribution
  deleteDistribution(distribution: Distribution): void {
    Object.keys(distribution).forEach(key => {
      if (this.distributionDeleteForm.controls[key])
        this.distributionDeleteForm.controls[key].setValue(distribution[key]);
    });
    this.deleteModal.open();
  }

  cancelDeleteDistributionForm(): void {
    this.deleteModal.close();
    this.distributionDeleteForm.reset();
  }

  // Download PDF
  downloadDistribution(distribution: Distribution): void {
    this.loading = true;
    this.isDownloadPDF = true;
    this.api.get('distributions/generateDistributionPDF', distribution.id).pipe(finalize(() => {
      this.loading = false;
      this.isDownloadPDF = false;
    })).subscribe((pdf: any) => {
      const mediaType = 'application/pdf';
      var binary = atob(pdf.pdf.replace(/\s/g, ''));
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }
      const blob = new Blob([view], { type: mediaType });
      saveAs(blob, 'salida-' + distribution.id + '.pdf');
    });
  }
    // sendDistribution
    sendDistribution(distribution: Distribution): void {
      this.api.get('distributions/sendDistribution', distribution.id).pipe(finalize(() => {
        this.loading = false;
        this.alertSuccess('El correo se enviará en breve');
      
      })).subscribe((response: any) => {
   
    
      });
    }

  // Tracking
  addTracking(distribution: Distribution): void {
    this.api.get('trackings/getTrackingsOfDistribution', distribution.id).subscribe((trackingsData: Tracking[]) => {
      this.trackings = trackingsData;

      this.trackings.forEach((tracking: Tracking) => {
        this.trackingsFormArray.push(this.builder.group({
          trackingNumber: tracking.trackingNumber,
          date: tracking.date
        }));
      });

      Object.keys(distribution).forEach(key => {
        if (this.addTrackingForm.controls[key])
          this.addTrackingForm.controls[key].setValue(distribution[key]);
      });

      this.addTrackingModal.open(true);
    });
  }

  cancelAddTrackingForm(): void {
    this.shippingDate = '';
    this.addTrackingModal.close();
    this.currentTracking = new Tracking();
    while (this.trackingsFormArray.length !== 0) {
      this.trackingsFormArray.removeAt(0);
    }
    this.trackings = [];
  }

  // Create Modal Functions
  addToDistribution(code): void {
    this.includes = false;
    this.api.get('boxes', code).subscribe((distributionBox: Box) => {
      this.api.get('boxes/getLevelAndGradeOfBox', code).subscribe((bookBox: BookBox) => {
        this.distributionProducts.forEach((box: DistributionBox) => {
          if (distributionBox.id == box.idBox.id) {
            this.includes = true;
            $('#box-code').val('');
          }
        });
        var boxDistribution = new DistributionBox();
        boxDistribution.idBox = distributionBox;
        boxDistribution.level = bookBox.idBook.level;
        boxDistribution.grade = bookBox.idBook.grade;

        if (!this.includes) {
          this.distributionProducts.push(boxDistribution);
          this.productsFormArray.push(this.builder.group({
            quantity: [1, [Validators.required, Validators.min(1)]],
            id: distributionBox.id
          }));

          // Add to supply quantity
          var index = this.orderProducts.findIndex(element => element.level == boxDistribution.level && element.grade == boxDistribution.grade);
          this.orderProducts[index].quantitySupplied = this.orderProducts[index].quantitySupplied + 1;

          $('#box-code').val('');
          Alerts.AlertSuccess('La caja ha sido agregado con éxito a la salida');
        }
      });
    });
  }

  removeDistributionBox(index: number) {
    this.currentBox = this.distributionProducts[index];
    var formData: FormData = new FormData();
    formData.append('idDistribution', this.currentDistribution.id);
    formData.append('idProduct', this.currentProduct.id);

    // Add to quantity supply
    var _index = this.orderProducts.findIndex(element => element.level == this.distributionProducts[index].level && element.grade == this.distributionProducts[index].grade);
    this.orderProducts[_index].quantitySupplied = this.orderProducts[_index].quantitySupplied - 1;

    this.productsFormArray.removeAt(index);
    this.distributionProducts.splice(index, 1);
  }

  addOtherProducts(): void {
    if (this.currentProduct != null) {
      this.includes = false;

      this.distributionOtherProducts.forEach((product: Product) => {
        if (this.currentProduct.id == product.id) {
          this.includes = true;
          $('#product').val(null).trigger('change');
          Alerts.AlertFailed('El producto ya fue agregado a la salida');
        }
      });

      if (!this.includes) {
        this.distributionOtherProducts.push(this.currentProduct);
        this.otherProductsFormArray.push(this.builder.group({
          id: this.currentProduct.id,
          quantity: [0, [Validators.required, Validators.min(1)]],
        }));
        $('#product').val(null).trigger('change');
        Alerts.AlertSuccess('El producto ha sido agregado a la salida');
      }
    }
  }

  removeOtherProduct(index: number): void {
    this.currentProduct = this.distributionOtherProducts[index];
    var formData: FormData = new FormData();
    formData.append('idDistribution', this.currentDistribution.id);
    formData.append('idProduct', this.currentProduct.id);

    this.otherProductsFormArray.removeAt(index);
    this.distributionOtherProducts.splice(index, 1);
  }

  // Tracking Modal Functions
  addTrackings() {
    var trackingNumber = $('#trackingNumber').val();
    this.includes = false;

    this.currentTracking = new Tracking();
    this.currentTracking.idDistribution = this.currentDistribution;
    this.currentTracking.trackingNumber = trackingNumber;
    this.currentTracking.date = this.shippingDate;

    this.trackings.forEach((tracking: Tracking) => {
      if (this.currentTracking.trackingNumber == tracking.trackingNumber) {
        this.includes = true;
        $('#trackingNumber').val('');
        $('#shippingDate').data('datepicker').setDate(null);
        Alerts.AlertFailed('El número de guía ya fue registrado');
      }
    });

    if (!this.includes) {
      this.trackings.push(this.currentTracking);
      this.trackingsFormArray.push(this.builder.group({
        trackingNumber: this.currentTracking.trackingNumber,
        date: this.currentTracking.date
      }));
      $('#trackingNumber').val('');
      $('#shippingDate').data('datepicker').setDate(null);
      Alerts.AlertSuccess('El número de guía se agregó con éxito');
    }
  }

  removeTracking(index: number) {
    this.currentTracking = this.trackings[index];

    this.trackingsFormArray.removeAt(index);
    this.trackings.splice(index, 1);
  }

  // API functions
  onSubmitDistributionForm(): void {
    this.loading = true;
    var formData: FormData = new FormData();
    var products: any = [];
    var productsWithOutLabels: any = [];
    var additionalProducts: any = [];
    var otherProducts: any = [];

    for (var i = 0; i < this.distributionProducts.length; i++) {
      products.push(Object.assign({}, this.productsFormArray.at(i).value));
    }

    for (var i = 0; i < this.distributionProductsWithOutLabels.length; i++) {
      productsWithOutLabels.push(Object.assign({}, this.distributionProductsWithOutLabels[i]));
    }

    for (var i = 0; i < this.additionalProductsOfDistribution.length; i++) {
      additionalProducts.push(Object.assign({}, this.additionalProductsFormArray.at(i).value));
    }

    for (var i = 0; i < this.distributionOtherProducts.length; i++) {
      otherProducts.push(Object.assign({}, this.otherProductsFormArray.at(i).value));
    }

    formData.append('idOrder', this.distributionForm.controls['idOrder'].value);
    formData.append('products', JSON.stringify(products));
    formData.append('productsWithoutLabels', JSON.stringify(productsWithOutLabels));
    formData.append('additionalProducts', JSON.stringify(additionalProducts));
    formData.append('otherProducts', JSON.stringify(otherProducts));

    this.api.post('distributions', formData).pipe(finalize(() => { this.loading = false })).subscribe((data: any) => {
      this.distributionsTable.ajax.reload();
      this.cancelDistributionForm();
      Alerts.AlertSuccess('Distribución registrada con éxito');
    }, (error) => {
      Alerts.AlertFailed(error.error.errors.join('; '));
    });
  }

  onSubmitDeleteDistributionForm(): void {
    if (this.distributionDeleteForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    this.api.delete('distributions/delete', this.distributionDeleteForm.controls['id'].value).pipe(finalize(() => { this.loading = false })).subscribe(() => {
      this.cancelDeleteDistributionForm();
      this.distributionsTable.ajax.reload();
      Alerts.AlertSuccess('Salida eliminada con éxito');
    }, (error) => {
      this.cancelDeleteDistributionForm();
      Alerts.AlertFailed(error.error.errors.join('; '));
    });
  }

  onSubmitAddTrackingForm(): void {
    if (this.addTrackingForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    var formData: FormData = new FormData();
    var trackingsData: any = [];

    for (var i = 0; i < this.trackings.length; i++) {
      trackingsData.push(Object.assign({}, this.trackingsFormArray.at(i).value));
    }
    formData.append('trackings', JSON.stringify(trackingsData));
    this.api.putFormData('trackings', this.addTrackingForm.controls['id'].value, formData).pipe(finalize(() => { this.loading = false; })).subscribe((distributions: Distribution[]) => {
      this.cancelAddTrackingForm();
      this.distributionsTable.ajax.reload();
      Alerts.AlertSuccess('Número de guía registrado con éxito');
    }, (error) => {
      Alerts.AlertFailed(error.error.errors.join('; '));
      this.cancelAddTrackingForm();
    });
  }

  refreshDistributionsTable(): void {
    this.distributionsTable.ajax.reload();
  }

  resetDistributionsTable(): void {
    $('#search').val('');
    $('#finalDate').data('datepicker').setDate(null);
    $('#initialDate').data('datepicker').setDate(null);
    this.distributionsTable.ajax.reload();
  }
  
  alertSuccess(title) {
    $.toast({
      heading: 'Realizado',
      text: title,
      showHideTransition: 'slide',
      icon: 'success',
      loaderBg: '#f96868',
      position: 'bottom-left'
    });}

    getJson(): void{
      //var date = '2024-05-23';// y tambien  2918
      var date =  this.jsonDateInput.nativeElement.value;
      if (!date) {
        console.log('La fecha está vacía');
        Alerts.AlertFailed("No se ha seleccionado alguna fecha.");
        return;
      }
      const convertedDate = this.convertDateFormat(date);
      //console.log(convertedDate);
      this.dataService.getJson(convertedDate);
    }

    convertDateFormat(date: string): string {
      // Divide la fecha en día, mes y año
      const [month, day, year] = date.split('/');
      // Retorna la fecha en el formato yy/mm/dd
      return `${year}/${month}/${day}`;
    }
  }