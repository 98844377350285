import { User } from './user';

export class Season 
{
    id: any; 

    name: string; 

    description : string; 

    initialDate : string; 

    finalDate : string; 
    
    activated : string; 

    status : string; 

    user: User; 

    createdAt : string; 

    updatedAt : string; 
}