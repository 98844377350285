import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { ApiService } from 'src/app/_api/api.service';
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { ModalReturnComponent } from 'src/app/components/modal-return/modal-return.component';
import { DeleteModalComponent } from 'src/app/components/delete-modal/delete-modal.component';
import gradesList from 'src/assets/js/grade.json';
import { OrderProduct } from 'src/app/models/order-product';
import { Distribution } from 'src/app/models/distribution';
import { Distributor } from 'src/app/models/distributor';
import { finalize } from 'rxjs/operators';
import { Order } from 'src/app/models/order';
import { OrderProducts } from 'src/app/models/order-products';
import { CodesProducts } from 'src/app/models/codes-products';
import { Address } from 'src/app/models/address';
import { Alerts } from 'src/app/utils/alerts';
import statesList from 'src/assets/js/statelist.json';
import townsList from 'src/assets/js/townlist.json';
import { formatDate } from '@angular/common';
import { Product } from 'src/app/models/products';
import { OrderAdditionalProducts } from 'src/app/models/order-additional-products';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models';
import { AuthenticationService } from 'src/app/services/authentication.service';
declare var $: any;

//select validation
export function ValidateType(control: AbstractControl) {
  if (control.value == null) {
    return { validType: true };
  }
  return null;
}

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.sass']
})
export class OrderComponent implements OnInit, AfterViewInit {
  @ViewChild("create")
  private createModal: ModalReturnComponent;

  @ViewChild("show")
  private showModal: ModalReturnComponent;

  @ViewChild("delete")
  private deleteModal: DeleteModalComponent;

  @Input('title') titleModal: string;

  orderForm: FormGroup;
  orderDeleteForm: FormGroup;
  productsFormArray: FormArray;
  additionalProductsFormArray: FormArray;
  states: any;
  towns: any;
  orderProducts: any[] = [];
  orderAdditionalProducts: any[] = [];
  grades: any;
  levels: string[] = [];
  codes: CodesProducts[] = [];
  addresses: Address[] = [];
  products: Product[] = [];
  currentProduct: OrderProduct = new OrderProduct();
  currentDistribution: Distribution = new Distribution();
  productsOfOrder: OrderProducts[] = [];
  additionalProductsOfOrder: OrderAdditionalProducts[] = [];
  productCodes: CodesProducts[] = [];
  productsShowTable: any;
  orderTable: any;
  isShowingOrder: boolean = false;
  submitted: boolean = false;
  loading: boolean = false;
  includes: boolean = false;
  copyAddress: boolean = false;
  isDownloadPDF: boolean = false;
  isOrderConfirmed: boolean = false;
  confirmDelete: boolean = false;
  createdAt: string = "";
  _level: string = null;
  _grade: string = null;
  _productCode: CodesProducts = new CodesProducts();
  _additionalProduct: Product = null;
  currentUser: User;


  //var to show
  showTypeOrder: string = "";
  showPaymentMethod: string = "";
  showCreatedAtOrder: string = "";
  showShippingWay: string = "";
  showFreight: string = "";
  showFreightName: string = "";
  showFreightPaternalSurname: string = "";
  showFreightMaternalSurname: string = "";
  showFreightPhone: string = "";
  showShippingAddress: string = "";
  showShippingColony: string = "";
  showShippingZip: string = "";
  showShippingPhone: string = "";
  showShippingState: string = "";
  showShippingTown: string = "";
  showInvoiceAddress: string = "";
  showInvoiceColony: string = "";
  showInvoiceZip: string = "";
  showRFC: string = "";
  showBusinessName: string = "";

  constructor(private api: ApiService, private formBuilder: FormBuilder, private formDeleteBuilder: FormBuilder, private auth: AuthenticationService) {
    this.loading = false;
    this.states = statesList;
    this.orderForm = this.formBuilder.group({
      id: [null],
      type: [null, Validators.required],
      idDistributor: [''],
      level: [''],
      productCode: [''],
      products: this.formBuilder.array([]),
      additionalProducts: this.formBuilder.array([]),
      addressName: ['', Validators.required],
      paymentMethod: ['', Validators.required],
      shippingWay: ['', Validators.required],
      freight: ['', Validators.required],
      freightName: ['', Validators.required],
      freightPaternalSurname: ['', Validators.required],
      freightMaternalSurname: ['', Validators.required],
      freightPhone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      shippingAddress: ['', Validators.required],
      shippingColony: ['', Validators.required],
      shippingZip: ['', Validators.required],
      shippingPhone: [''],
      invoiceAddress: ['', Validators.required],
      invoiceColony: ['', Validators.required],
      invoiceZip: ['', Validators.required],
      businessName: ['', Validators.required],
      rfc: ['', Validators.required],
      shippingState: [null, Validators.required],
      shippingTown: [null, Validators.required],
      idUser: [null],
      relation: [''],
      orderAdditionalProducts: this.formBuilder.array([])
    });
    this.productsFormArray = this.orderForm.get('products') as FormArray;
    this.additionalProductsFormArray = this.orderForm.get('additionalProducts') as FormArray;

    this.orderDeleteForm = this.formDeleteBuilder.group({
      id: [null],
      comment: ['', Validators.required]
    });

    this.api.getAll('productsCodes/getAllProductsToShowOnOrders').subscribe((productsCodes: CodesProducts[]) => {
      this.productCodes = productsCodes;
    });

    this.api.getAll('productsCodes/getAllLevels').subscribe((productsCodes: string[]) => {
      this.levels = productsCodes;
    });

    this.api.getAll('products/getProductsToShowInOrders').subscribe((productsData: Product[]) => {
      this.products = productsData.filter(product => product.distributorActive);
      // this.products = productsData;
    });

    this.api.getAll('addresses/getAllByDistributor').subscribe((addressesData: Address[]) => {
      this.addresses = addressesData;
    });
  }

  ngAfterViewInit() {
    this.loading = false;
    this.orderTable = $('#orders-table').DataTable({
      'ajax': {
        url: `${environment.apiUrl}/orders/getOrdersByDistributor`,
        data: function (data) {
          data.from = $("#initialDate").val();
          data.to = $("#finalDate").val();
          data.code = $("#search").val();
          data.orderStatus = $("input[name='status-filter']:checked").val();
        }

      },
      'serverSide': true,
      'searching': false,
      'paging': true,
      'lengthChange': false,
      'language': {
        'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json'
      },
      columns: [
        { data: 'id' },
        { data: 'createdAt' },
        { data: 'type' },
        {
          data: null,
          defaultContent: '',
          createdCell: function (td, cellData, rowData, row, col) {
            var actions = ``;

            if (rowData['status'] == "0") {
              actions += `<label class="badge badge-danger">Cancelado</label>`;
            } 
            else if (rowData['orderStatus'] == "Por aprobar") {
              actions += `<label class="badge badge-warning text-white">Por aprobar</label>`;
            }
            else if (rowData['orderStatus'] == "Por surtir") {
              actions += `<label class="badge badge-primary">Por surtir</label>`;
            }
            else if (rowData['orderStatus'] == "Surtido") {
              actions += `<label class="badge badge-success">Surtido</label>`;
            } 
            else if (rowData['orderStatus'] == "Surtido parcial"){
              actions += `<label class="badge" style="background-color: orange; color: white;" >Surtido Parcial</label>`;
            } 
            else {
              actions += `<label class="badge badge-danger">Rechazado</label>`;
            }
            $(td).prepend(actions);
          },
          orderable: false,
          searchable: false
        },
        { data: 'invoiceNumber' },
        {
          data: null,
          defaultContent: '',
          createdCell: function (td, cellData, rowData, row, col) {
            var actions =
              `
                  <div class="btn-group" role="group" aria-label="Acciones">
                  <button type="button" class="btn btn-success order-show" title="Ver detalles">
                  <i class="mdi mdi-plus text-white"></i>
                  </button>
                  `;

            // if (rowData['orderStatus'] == "Por aprobar") {
            //   actions += `<button type="button" class="btn btn-warning order-edit" title="Editar">
            //   <i class="mdi mdi-lead-pencil text-white"></i>
            //   </button>`
            // }
            if (rowData['status'] != "0"){
              if(rowData['orderStatus'] == "Surtido"){
                actions +=
                `
                  <button type="button" class="btn btn-info order-download" title="Descargar PDF">
                  <i class="mdi mdi-file-pdf  text-white"></i>
                  </button>
                `;
              }else{
                actions +=
                `
                  <button type="button" class="btn btn-danger order-delete" title="Eliminar">
                  <i class="mdi mdi-delete-forever"></i></button>
                  <button type="button" class="btn btn-info order-download" title="Descargar PDF">
                  <i class="mdi mdi-file-pdf  text-white"></i>
                  </button>
                `;
              }
            }
            actions += ` </div>`;
            $(td).prepend(actions);
          },
          orderable: false,
          searchable: false
        }
      ],
      drawCallback: () => {
        $('.order-show').on('click', (element) => {
          let order: Order = this.orderTable.row($(element.currentTarget).closest('tr')).data();
          this.showOrder(order);
        });

        $('.order-edit').on('click', (element) => {
          let order: Order = this.orderTable.row($(element.currentTarget).closest('tr')).data();
          this.editOrder(order);
        });

        $('.order-delete').on('click', (element) => {
          let order: Order = this.orderTable.row($(element.currentTarget).closest('tr')).data();
          this.cancelOrder(order);
        });

        $('.order-download').on('click', (element) => {
          let order: Order = this.orderTable.row($(element.currentTarget).closest('tr')).data();
          this.downloadOrder(order);
        });
      }
    });

    $('input[name="status-filter"]').on('change', () => {
      this.orderTable.ajax.reload();
    });

    $('#address').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona la dirección de envío',
      width: '100%',
      dropdownParent: $('#OrderModal')
    });
    $('#address').val(null).trigger('change');

    $("#initialDate, #finalDate").datepicker({
      enableOnReadonly: true,
      todayHighlight: true,
      autoclose: true,
    });


    $('#initialDate').on('changeDate', function () {
      $('#initialDate').val($('#initialDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#initialDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $("#finalDate").datepicker("setStartDate", dtFormatted);
    });

    $('#finalDate').on('changeDate', function () {
      $('#finalDate').val($('#finalDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#finalDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $("#initialDate").datepicker("setEndDate", dtFormatted);
    });

    $('#level').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el nivel',
      width: '100%',
      dropdownParent: $('#OrderModal')
    });
    $('#level').val(null).trigger('change');

    $('#product_code').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el producto',
      width: '100%',
      disabled: true,
      dropdownParent: $('#OrderModal')
    });
    $('#product_code').val(null).trigger('change');

    $('#paymentMethod').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el método de pago',
      width: '100%',
      dropdownParent: $('#OrderModal')
    });
    $('#paymentMethod').val(null).trigger('change');

    $('#shipping_way').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona la forma de envío',
      width: '100%',
      dropdownParent: $('#OrderModal')
    });
    $('#shipping_way').val(null).trigger('change');

    $('#grade').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el grado',
      width: '100%',
      disabled: true,
      dropdownParent: $('#OrderModal')
    });
    $('#grade').val(null).trigger('change');

    $('#states_code').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el estado',
      width: '100%',
      dropdownParent: $('#OrderModal')
    });
    $('#states_code').val(null).trigger('change');

    $('#towns_code').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el municipio',
      width: '100%',
      dropdownParent: $('#OrderModal'),
      disabled: true
    });
    $('#towns_code').val(null).trigger('change');


    $('#product').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el producto',
      width: '100%',
      dropdownParent: $('#OrderModal')
    });
    $('#product').val(null).trigger('change');

    $('#states_code').on("change", () => {
      if ($('#states_code').val() != '') {
        var currentState = $('#states_code').val();
        townsList.forEach(element => {
          if (currentState == element['code']) {
            this.towns = element['towns'];
          }
        });
        $('#towns_code').empty().trigger("change");
        this.towns.forEach((town: any) => {
          var newOption = new Option(town, town, false, false);
          $('#towns_code').append(newOption).trigger('change');
        });
        $('#towns_code').val(null).trigger("change");
        $("#towns_code").prop("disabled", false);
        this.orderForm.controls['shippingState'].setValue(currentState);
      }
    });

    $('#towns_code').on("change", () => {
      if ($('#towns_code').val() != '') {
        var currentTown = $('#towns_code').val();
        this.orderForm.controls['shippingTown'].setValue(currentTown);
      }
    });

    $('#level').on("change", () => {
      if ($('#level').val() != '') {
        var level = $('#level').val();
        this.orderForm.controls['level'].setValue(level);
        this.codes = [];

        gradesList.forEach(element => {
          if (level == element['code']) {
            this.grades = element['grades'];
          }
        });

        this.productCodes.forEach(element => {
          if (level == element['level']) {
            this.codes.push(element);
          }
        });

        $('#grade').empty().trigger("change");
        $('#product_code').empty().trigger("change");

        this.grades.forEach((grades: any) => {
          if (grades == '7') {
            grades = 'UNICO';
          }
          var newOption = new Option(grades, grades, false, false);
          $('#grade').append(newOption).trigger('change');
        });

        this.codes.forEach((element) => {
          var newOption = new Option(element.description + " - " + element.idSeason.name, element.id, false, false);
          $('#product_code').append(newOption).trigger('change');
        });

        $('#product_code').val(null).trigger("change");
        $("#product_code").prop("disabled", false);
        $('#grade').val(null).trigger("change");
        $("#grade").prop("disabled", false);
        this._level = level;
      }
    });

    $('#grade').on("change", () => {
      if ($('#grade').val() != '') {
        var grade = $('#grade').val();
        this._grade = grade;
      }
    });

    $('#product_code').on("change", () => {
      this._productCode = null;
      if ($('#product_code').val() != '') {
        var code = $('#product_code').val();
        this.orderForm.controls['productCode'].setValue(code);
        this.productCodes.forEach(element => {
          if (code == element['id']) {
            this._productCode = element;
          }
        });
      }
    });

    $('#product').on("change", () => {
      if ($('#product').val() != '') {
        var code = $('#product').val();
        this.products.forEach(element => {
          if (code == element['id']) {
            this._additionalProduct = element;
          }
        });
      }
    });

    $('#address').on("change", () => {
      if ($('#address').val() != '') {
        var address = $('#address').val();
        this.addresses.forEach(element => {
          if (address == element['id']) {
            this.orderForm.controls['idDistributor'].setValue(element.distributor.id);
            this.orderForm.controls['addressName'].setValue(element.addressName);
            this.orderForm.controls['freight'].setValue(element.freight);
            this.orderForm.controls['freightName'].setValue(element.freightName);
            this.orderForm.controls['freightPaternalSurname'].setValue(element.freightPaternalSurname);
            this.orderForm.controls['freightMaternalSurname'].setValue(element.freightMaternalSurname);
            this.orderForm.controls['freightPhone'].setValue(element.freightPhone);
            this.orderForm.controls['shippingAddress'].setValue(element.shippingAddress);
            this.orderForm.controls['shippingColony'].setValue(element.shippingColony);
            this.orderForm.controls['shippingZip'].setValue(element.shippingZip);
            this.orderForm.controls['shippingPhone'].setValue(element.shippingPhone);
            this.orderForm.controls['invoiceAddress'].setValue(element.invoiceAddress);
            this.orderForm.controls['invoiceColony'].setValue(element.invoiceColony);
            this.orderForm.controls['invoiceZip'].setValue(element.invoiceZip);
            this.orderForm.controls['businessName'].setValue(element.businessName);
            this.orderForm.controls['shippingState'].setValue(element.shippingState);
            this.orderForm.controls['shippingTown'].setValue(element.shippingTown);
            this.orderForm.controls['rfc'].setValue(element.rfc);
            $('#shipping_way').val(element.shippingWay).trigger('change');
            $('#states_code').val(element.shippingState).trigger('change');
            $('#towns_code').val(element.shippingTown).trigger('change');
          }
        });
      }
    });

    $('#paymentMethod').on("change", () => {
      if ($('#paymentMethod').val() != '') {
        var paymentMethod = $('#paymentMethod').val();
        this.orderForm.controls['paymentMethod'].setValue(paymentMethod);
      }
    });

    $('#shipping_way').on("change", () => {
      if ($('#shipping_way').val() != '') {
        var shippingWay = $('#shipping_way').val();
        this.orderForm.controls['shippingWay'].setValue(shippingWay);
      }
    });
  }

  ngOnInit() {
    this.loading = false;
  }

  //modal functions
  createOrder() {
    this.api.getAll('addresses/getAllByDistributor').subscribe((addressesData: Address[]) => {
      this.addresses = addressesData;
    });
    this.cancelOrderForm();
    this.createdAt = formatDate(new Date(), 'dd/MM/yyyy', 'en');
    this.orderForm.controls['type'].setValue("Orden");
    this.createModal.open(true);
    this.isShowingOrder = false;
  }

  editOrder(order: Order) {
    this.cancelOrderForm();
    this.createdAt = formatDate(new Date(), 'dd/MM/yyyy', 'en');
    this.api.get('orders/productsOfOrder', order.id).subscribe((productsDistributionData: OrderProducts[]) => {
      this.productsOfOrder = productsDistributionData;
      this.isShowingOrder = true;
      Object.keys(order).forEach(key => {
        if (this.orderForm.controls[key])
          this.orderForm.controls[key].setValue(order[key]);
      });
      this.orderForm.controls['idDistributor'].setValue(order.idDistributor.id);
      this.orderForm.controls['freight'].setValue(order.idDistributor.freight);
      this.orderForm.controls['freightName'].setValue(order.idDistributor.freightName);
      this.orderForm.controls['freightPaternalSurname'].setValue(order.idDistributor.freightPaternalSurname);
      this.orderForm.controls['freightMaternalSurname'].setValue(order.idDistributor.freightMaternalSurname);
      this.orderForm.controls['freightPhone'].setValue(order.idDistributor.freightPhone);
      this.orderForm.controls['shippingAddress'].setValue(order.idDistributor.shippingAddress);
      this.orderForm.controls['shippingColony'].setValue(order.idDistributor.shippingColony);
      this.orderForm.controls['shippingZip'].setValue(order.idDistributor.shippingZip);
      this.orderForm.controls['shippingPhone'].setValue(order.idDistributor.shippingPhone);


      this.createModal.open(false);
      $('#type').val(order.type).trigger('change');

      this.productsOfOrder.forEach((product: OrderProducts) => {
        var orderProduct = new OrderProduct();
        if (product.grade == '7')
            product.grade = 'UNICO';
        console.log(product.grade );
        orderProduct.grade = product.grade;
        orderProduct.level = product.level;

        this.orderProducts.push(orderProduct);
        this.productsFormArray.push(this.formBuilder.group({
          quantity: [product.quantity, [Validators.required, Validators.min(1)]],
          grade: product.grade,
          level: product.level
        }));
      });

    });
  }

  showOrder(order: Order) {
    this.cancelOrderForm();
    this.showTypeOrder = order.type;
    this.showPaymentMethod = order.paymentMethod;
    this.showCreatedAtOrder = order.createdAt;
    this.showShippingWay = order.shippingWay;
    this.showFreight = order.freight;
    this.showFreightName = order.freightName;
    this.showFreightPaternalSurname = order.freightPaternalSurname;
    this.showFreightMaternalSurname = order.freightMaternalSurname;
    this.showFreightPhone = order.freightPhone;
    this.showShippingAddress = order.shippingAddress;
    this.showShippingColony = order.shippingColony;
    this.showShippingZip = order.shippingZip;
    this.showShippingPhone = order.shippingPhone;
    this.showShippingState = order.shippingState;
    this.showShippingTown = order.shippingTown;
    this.showInvoiceAddress = order.invoiceAddress;
    this.showInvoiceColony = order.invoiceColony;
    this.showInvoiceZip = order.invoiceZip;
    this.showRFC = order.businessName;
    this.showBusinessName = order.rfc;

    this.api.get('orders/productsOfOrder', order.id).subscribe((boxesDistributionData: OrderProducts[]) => {
      this.api.get('orders/getAllAdditionalProductsOfOrder', order.id).subscribe((productsDistributionData: OrderAdditionalProducts[]) => {
        console.log(boxesDistributionData, productsDistributionData);
        this.productsOfOrder = boxesDistributionData;
        this.additionalProductsOfOrder = productsDistributionData;
        this.showModal.open(false);
        this.isShowingOrder = true;
      });
    });

  }

  cancelOrder(order: Order) {
    Object.keys(order).forEach(key => {
      if (this.orderDeleteForm.controls[key])
        this.orderDeleteForm.controls[key].setValue(order[key]);
    });
    this.deleteModal.open();
  }

  downloadOrder(order: Order) {
    this.loading = true;
    this.isDownloadPDF = true;
    this.currentUser = this.auth.user;
    this.api.get('orders/generateOrderPDF' , order.id, { userRolId: this.currentUser.role.id }).pipe(finalize(() => { this.loading = false; this.isDownloadPDF = false; })).subscribe((pdf: any) => {
      const mediaType = 'application/pdf';
      var binary = atob(pdf.pdf.replace(/\s/g, ''));
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }
      const blob = new Blob([view], { type: mediaType });
      saveAs(blob, "Pedido-" + order.id + ".pdf");
    });
  }

  //general functions
  cancelOrderForm() {
    this.orderForm.reset();
    this.createModal.close();
    this.refreshLevelAndGrade();
    this.resetFormArray();
    this.refreshProduct();
    $('#type').val(null).trigger('change');
    $('#address').val(null).trigger('change');
    $('#paymentMethod').val(null).trigger('change');
    $('#shipping_way').val(null).trigger('change');
    $('#states_code').val(null).trigger('change');
    $('#towns_code').val(null).trigger('change');
    this.isOrderConfirmed = false;
    this._productCode = null;
  }

  closeShowForm() {
    this.showModal.close();
    this.showPaymentMethod = "";
    this.showShippingWay = "";
    this.showTypeOrder = "";
    this.showCreatedAtOrder = "";
    this.showFreight = "";
    this.showFreightName = "";
    this.showFreightPaternalSurname = "";
    this.showFreightMaternalSurname = "";
    this.showFreightPhone = "";
    this.showShippingAddress = "";
    this.showShippingColony = "";
    this.showShippingZip = "";
    this.showShippingPhone = "";
    this.showShippingState = "";
    this.showShippingTown = "";
    this.showInvoiceAddress = "";
    this.showInvoiceColony = "";
    this.showInvoiceZip = "";
    this.showRFC = "";
    this.showBusinessName = "";
  }

  cancelDeleteOrderForm() {
    this.deleteModal.close();
    this.orderDeleteForm.reset();
    this.confirmDelete = false;
  }

  resetFormArray() {
    while (this.productsFormArray.length !== 0) {
      this.productsFormArray.removeAt(0)
    }
    this.orderProducts = [];

    while (this.additionalProductsFormArray.length !== 0) {
      this.additionalProductsFormArray.removeAt(0)
    }
    this.orderAdditionalProducts = [];
  }

  refreshLevelAndGrade() {
    $("#level").prop("disabled", false);
    $("#grade").prop("disabled", true);
    $("#product_code").prop("disabled", true);
    $('#level').val(null).trigger('change');
    $('#grade').val(null).trigger('change');
    $('#product_code').val(null).trigger('change');
  }

  refreshGrade() {
    $('#grade').val(null).trigger('change');
  }

  refreshProduct() {
    $('#product').val(null).trigger('change');
  }

  refreshOrderTable() {
    this.orderTable.ajax.reload();
  }

  resetOrderTable() {
    $('#initialDate').data('datepicker').setDate(null);
    // $('#finalDate').data('datepicker').setDate(null);
    $("#search").val('');
    this.orderTable.ajax.reload();
  }

  confirmOrder() {
    this.isOrderConfirmed = true;
  }

  cancelConfirmOrder() {
    this.isOrderConfirmed = false;
  }

  confirmDeleteOrderForm() {
    this.confirmDelete = true;
  }

  anyAddress(): boolean {
    if (this.addresses.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  anyProduct(): boolean {
    if (this.products.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  anyAdditionalProductsOfOrder() {
    if (this.additionalProductsOfOrder.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  anyProductsOfOrder() {
    if (this.productsOfOrder.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  //Functions add products to  Order
  addProduct() {
    if (this._level != null && this._grade != null && this._productCode != null) {
      this.includes = false;
      const grade = this._grade;
      var orderProduct = new OrderProduct();
      orderProduct.grade = this._grade;
      orderProduct.level = this._level;
      orderProduct.description = this._productCode.description + " " + orderProduct.grade + " de " + orderProduct.level + this._productCode.idSeason.name;

      this.orderProducts.forEach((product: OrderProduct) => {
        if (orderProduct.level + orderProduct.grade == product.level + product.grade) {
          this.includes = true;
          this.refreshGrade();
          Alerts.AlertFailed('El producto ya existe en el pedido');
        }
      });

      if (!this.includes) {
        this.orderProducts.push(orderProduct);

        this.api.getAdditionalProducts('products/checkProductExists', this._productCode.id, this._grade).subscribe(
          (additionalProducts: any[]) => { 
            if (additionalProducts && additionalProducts.length > 0) {
              additionalProducts.forEach((product: Product) => {
                const formattedProduct = {
                  id: product.idProduct.id,
                  code: product.idProduct.code,
                  distributorActive: product.idProduct.distributorActive,
                  grade: product.idProduct.grade,
                  level: product.idProduct.level,
                  relation: 1,
                  title: product.idProduct.title,
                  is_multiplied: product.isMultiplied,
                  boxOriginGrade: grade,
                };
                this.orderAdditionalProducts.push(formattedProduct);
                this.additionalProductsFormArray.push(this.formBuilder.group({
                  id: formattedProduct.id,
                  quantity: [1, [Validators.required, Validators.min(1)]],
                  relation: formattedProduct.relation,
                  is_multiplied: formattedProduct.is_multiplied,
                  boxOriginGrade: formattedProduct.boxOriginGrade,
                }));
                this.refreshProduct();
                Alerts.AlertSuccess('El producto ha sido agregado con éxito');
  
              });
  
            } else {
              console.log('No se encontraron productos adicionales');
            }
          });

        this.productsFormArray.push(this.formBuilder.group({
          description: orderProduct.description,
          quantity: [1, [Validators.required, Validators.min(1)]],
          grade: orderProduct.grade
        }));
        this.refreshGrade();
        Alerts.AlertSuccess('El producto ha sido agregado con éxito');
      }
    }

    if (this.orderProducts.length > 0) {
      $("#level").prop("disabled", true);
      $("#product_code").prop("disabled", true);
    }
  }

  addAdditionalProducts() {
    if (this._additionalProduct != null) {
      this.includes = false;

      this.orderAdditionalProducts.forEach((product: Product) => {
        if (this._additionalProduct.id == product.id) {
          this.includes = true;
          this.refreshProduct();
          Alerts.AlertFailed('El producto ya existe en el pedido');
        }
      });

      if (!this.includes) {
        this.orderAdditionalProducts.push(this._additionalProduct);
        this.additionalProductsFormArray.push(this.formBuilder.group({
          id: this._additionalProduct.id,
          quantity: [1, [Validators.required, Validators.min(1)]],
          relation: 0,
          is_multiplied: false,
          boxOriginGrade: '0'
        }));
        this.refreshProduct();
        Alerts.AlertSuccess('El producto ha sido agregado con éxito');
      }
    }
  }

  onQuantityChange(event, index: number) {
    this.currentProduct = this.orderProducts[index];
    if (!event.target.value || event.target.value < 1) {
      this.productsFormArray.at(index).get('quantity').setValue('1');
    }
  }

  onAdditionalQuantityChange(event, index: number) {
    const newValue = parseInt(event.target.value, 10);
    if (newValue < 1 || isNaN(newValue)) {
      this.additionalProductsFormArray.at(index).get('quantity').setValue(1);
    } else {
      this.additionalProductsFormArray.at(index).get('quantity').setValue(newValue);
    }
  }

  removeOrderProduct(index: number) {
    const removedProduct = this.orderProducts[index];
    const removedGrade = removedProduct.grade;
    
    this.productsFormArray.removeAt(index);
    this.orderProducts.splice(index, 1);

    for (let i = this.orderAdditionalProducts.length - 1; i >= 0; i--) {
        if (this.orderAdditionalProducts[i].boxOriginGrade === removedGrade) {
            this.additionalProductsFormArray.removeAt(i);
            this.orderAdditionalProducts.splice(i, 1);
        }
    }

    if (this.orderProducts.length < 1) {
      this.refreshLevelAndGrade();
    }
  }

  removeOrderAdditionalProduct(index: number) {
    this.additionalProductsFormArray.removeAt(index);
    this.orderAdditionalProducts.splice(index, 1);
  }

  //Address functions
  copyData(evt) {
    this.copyAddress = evt.target.checked;
    if (this.copyAddress) {
      this.currentShippingAddress();
      this.currentShippingColony();
      this.currentShippingZip();
    }
    else {
      this.orderForm.controls['invoiceAddress'].setValue("");
      this.orderForm.controls['invoiceColony'].setValue("");
      this.orderForm.controls['invoiceZip'].setValue("");
    }
  }

  currentShippingAddress() {
    if (this.copyAddress && this.orderForm.controls['shippingAddress'].value != null && this.orderForm.controls['shippingAddress'].value.length > 1) {
      this.orderForm.controls['invoiceAddress'].setValue(this.orderForm.controls['shippingAddress'].value.toString());
    }
  }

  currentShippingColony() {
    if (this.copyAddress && this.orderForm.controls['shippingColony'].value != null && this.orderForm.controls['shippingColony'].value.length > 1) {
      this.orderForm.controls['invoiceColony'].setValue(this.orderForm.controls['shippingColony'].value.toString());
    }
  }

  currentShippingZip() {
    if (this.copyAddress && this.orderForm.controls['shippingZip'].value != null && this.orderForm.controls['shippingZip'].value.length > 1) {
      this.orderForm.controls['invoiceZip'].setValue(this.orderForm.controls['shippingZip'].value.toString());
    }
  }

  isInValidForm(): boolean {
    if (this.orderForm.valid && (this.productsFormArray.length > 0 || this.additionalProductsFormArray.length > 0)) {
      return false;
    }
    else {
      return true;
    }
  }

  //subbmit function
  // onSubmitOrderForm() {
  //   if (this.orderForm.invalid) {
  //     this.submitted = true;
  //     return;
  //   }

  //   this.loading = true;
  //   Alerts.AlertWarning('Se está guardando el pedido.');

  //   var formData: FormData = new FormData();
  //   var products: any = [];
  //   var additionalProducts: any = [];

  //   // Convert 'UNICO' to '7' in products
  //   for (var i = 0; i < this.orderProducts.length; i++) {
  //     let product = Object.assign({}, this.productsFormArray.at(i).value);
  //     if (product.grade === 'UNICO') {
  //         product.grade = '7';
  //     }
  //     products.push(product);
  //   }
  //   // Convert 'UNICO' to '7' in additionalProducts
  //   for (var i = 0; i < this.orderAdditionalProducts.length; i++) {
  //       let additionalProduct = Object.assign({}, this.additionalProductsFormArray.at(i).value);
  //       if (additionalProduct.boxOriginGrade === 'UNICO') {
  //           additionalProduct.boxOriginGrade = '7';
  //       }
  //       additionalProducts.push(additionalProduct);
  //   }

  //   var address: Address = Object.assign({}, this.orderForm.value);
  //   formData.append('idDistributor', this.orderForm.controls['idDistributor'].value);
  //   formData.append('type', this.orderForm.controls['type'].value);
  //   formData.append('products', JSON.stringify(products));
  //   formData.append('additionalProducts', JSON.stringify(additionalProducts));
  //   formData.append('level', this.orderForm.controls['level'].value);
  //   formData.append('paymentMethod', this.orderForm.controls['paymentMethod'].value);
  //   formData.append('shippingWay', this.orderForm.controls['shippingWay'].value);
  //   formData.append('freight', this.orderForm.controls['freight'].value);
  //   formData.append('freightName', this.orderForm.controls['freightName'].value);
  //   formData.append('freightPaternalSurname', this.orderForm.controls['freightPaternalSurname'].value);
  //   formData.append('freightMaternalSurname', this.orderForm.controls['freightMaternalSurname'].value);
  //   formData.append('freightPhone', this.orderForm.controls['freightPhone'].value);
  //   formData.append('shippingAddress', this.orderForm.controls['shippingAddress'].value);
  //   formData.append('shippingColony', this.orderForm.controls['shippingColony'].value);
  //   formData.append('shippingZip', this.orderForm.controls['shippingZip'].value);
  //   formData.append('shippingPhone', this.orderForm.controls['shippingPhone'].value);
  //   formData.append('shippingState', this.orderForm.controls['shippingState'].value);
  //   formData.append('shippingTown', this.orderForm.controls['shippingTown'].value);
  //   formData.append('invoiceAddress', this.orderForm.controls['invoiceAddress'].value);
  //   formData.append('invoiceColony', this.orderForm.controls['invoiceColony'].value);
  //   formData.append('invoiceZip', this.orderForm.controls['invoiceZip'].value);
  //   formData.append('businessName', this.orderForm.controls['businessName'].value);
  //   formData.append('rfc', this.orderForm.controls['rfc'].value);
  //   formData.append('productCode', this.orderForm.controls['productCode'].value);

  //   if (this.addresses.length < 1) {
  //     this.loading = true;
  //     this.api.post('addresses', address).pipe(finalize(() => { this.loading = false })).subscribe((data: any) => {
  //       Alerts.AlertSuccess('Dirección guardada con éxito');
  //       this.api.post('orders/storeOrderByDistributor', formData).pipe(finalize(() => { this.loading = false; })).subscribe((_data: any) => {
  //         this.orderTable.ajax.reload();
  //         this.cancelOrderForm();
  //         this.loading = false;
  //         Alerts.AlertSuccess('Pedido registrado con éxito');
  //       }, (error) => {
  //         Alerts.AlertFailed(error.error.message);
  //       });
  //     }, (error) => {
  //         Alerts.AlertFailed(error.error.message);
  //     });
  //   } else {
  //     this.loading = true;
  //     this.api.post('orders/storeOrderByDistributor', formData).pipe(finalize(() => { this.loading = false })).subscribe((_data: any) => {
  //       this.orderTable.ajax.reload();
  //       this.cancelOrderForm();
  //       this.loading = false;
  //       Alerts.AlertSuccess('Pedido registrado con éxito');
  //     }, (error) => {
  //         Alerts.AlertFailed(error.error.message);
  //     });
  //   }
  // }

  onSubmitOrderForm() {
    if (this.orderForm.invalid) {
        this.submitted = true;
        return;
    }

    this.loading = true;
    Alerts.AlertWarning('Se está guardando el pedido.');

    const formData: FormData = new FormData();
    const products = this.processProducts(this.orderProducts, this.productsFormArray);
    const additionalProducts = this.processProducts(this.orderAdditionalProducts, this.additionalProductsFormArray, 'boxOriginGrade');

    const orderControls = [
        'idDistributor', 'type', 'level', 'paymentMethod', 'shippingWay',
        'freight', 'freightName', 'freightPaternalSurname', 'freightMaternalSurname',
        'freightPhone', 'shippingAddress', 'shippingColony', 'shippingZip', 'shippingPhone',
        'shippingState', 'shippingTown', 'invoiceAddress', 'invoiceColony', 'invoiceZip',
        'businessName', 'rfc', 'productCode'
    ];

    orderControls.forEach(control => {
        formData.append(control, this.orderForm.controls[control].value);
    });

    formData.append('products', JSON.stringify(products));
    formData.append('additionalProducts', JSON.stringify(additionalProducts));

    const address: Address = { ...this.orderForm.value };

    if (this.addresses.length < 1) {
        this.saveAddressAndOrder(address, formData);
    } else {
        this.saveOrder(formData);
    }
  }

  processProducts(orderItems: any[], formArray: FormArray, gradeField: string = 'grade'): any[] {
    return orderItems.map((item, index) => {
        const product = { ...formArray.at(index).value };
        if (product[gradeField] === 'UNICO') {
            product[gradeField] = '7';
        }
        return product;
    });
  }

  saveAddressAndOrder(address: Address, formData: FormData) {
    this.api.post('addresses', address)
        .pipe(finalize(() => { this.loading = false }))
        .subscribe({
            next: () => {
                Alerts.AlertSuccess('Dirección guardada con éxito');
                this.saveOrder(formData);
            },
            error: (error) => {
                Alerts.AlertFailed(error.error.message);
            }
        });
  }

  saveOrder(formData: FormData) {
    this.api.post('orders/storeOrderByDistributor', formData)
        .pipe(finalize(() => { this.loading = false }))
        .subscribe({
            next: () => {
                this.orderTable.ajax.reload();
                this.cancelOrderForm();
                Alerts.AlertSuccess('Pedido registrado con éxito');
            },
            error: (error) => {
                Alerts.AlertFailed(error.error.message);
            }
        });
  }

  onSubmitDeleteOrderForm() {
    if (this.orderDeleteForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    this.api.patch('orders/delete', this.orderDeleteForm.controls['id'].value, this.orderDeleteForm.controls['comment'].value).pipe(finalize(() => { this.loading = false })).subscribe(() => {
      this.cancelDeleteOrderForm();
      this.orderTable.ajax.reload();
      Alerts.AlertSuccess('Pedido cancelado con éxito');
    }, (error) => {
      this.cancelDeleteOrderForm();
      Alerts.AlertFailed(error.error.message);
    });
  }

  capitalizeSentence(string) {
    return string.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

}
