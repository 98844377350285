import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/_api/api.service';
import { ModalReturnComponent } from 'src/app/components/modal-return/modal-return.component';
import { DeleteModalComponent } from 'src/app/components/delete-modal/delete-modal.component';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Alerts } from 'src/app/utils/alerts';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-return',
  templateUrl: './return.component.html',
  styleUrls: ['./return.component.sass']
})
export class ReturnComponent implements OnInit, AfterViewInit {
  @ViewChild('approveModal')
  approveModal: DeleteModalComponent;
  @ViewChild('rejectModal')
  rejectModal: DeleteModalComponent;
  @ViewChild('returnModal')
  returnModal: ModalReturnComponent;
  boxesFormArray: FormArray;
  approveForm: FormGroup;
  approveModalTitle = '';
  rejectForm: FormGroup;
  returnForm: FormGroup;
  returnsTable: any;
  submitted = false;
  approved = false;
  rejected = false;
  loading = false;
  boxCode = '';

  constructor(private api : ApiService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.returnForm = this.formBuilder.group({
      id: [null],
      code: [null],
      createdAt: [null],
      returnComment: [null],
      boxes: this.formBuilder.array([])
    });
    this.boxesFormArray = this.returnForm.get('boxes') as FormArray;
    this.approveForm = this.formBuilder.group({
      id: [null, Validators.required]
    });
    this.rejectForm = this.formBuilder.group({
      id: [null, Validators.required],
      rejectComment: [null, Validators.required]
    });
  }

  ngAfterViewInit(): void {
    $('.datepicker').datepicker({ autoclose: true, todayHighlight: true, enableOnReadonly: false });

    this.returnsTable = $('#returns-table').DataTable({
      'ajax': {
        url: `${environment.apiUrl}/returns/all`,
        data: function (data) {
          data.keywords = $('#keywords').val();
          data.to = $('#final').val();
          data.from = $('#initial').val();
        }
      },
      'paging': true,
      'pageLength': 10,
      'searching': false,
      'serverSide': true,
      'lengthChange': false,
      'language': { 'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json' },
      columns: [
        { data: 'code' },
        {
          data: 'distributorId',
          render: (data, type, row) => {
            return row.distributorId.name + ' ' + row.distributorId.paternalSurname + ' ' + row.distributorId.maternalSurname;
          }
        },
        { data: 'createdAt' },
        {
          data: 'status',
          render: (data, type, row) => {
            var label = '';
            if (row.status == 'Por Aprobar') {
              label = '<label class="badge badge-warning">Por aprobar</label>';
            } else if (row.status == 'Aprobada') {
              label = '<label class="badge badge-success">Aprobada</label>';
            } else if (row.status == 'Rechazada') {
              label = '<label class="badge badge-danger">Rechazada</label>';
            }
            return label;
          }
        },
        {
          data: null,
          orderable: false,
          searchable: false,
          defaultContent: '',
          createdCell: function (td, cellData, rowData, row, col) {
            var content =
              `<div class="btn-group" role="group" aria-label="Acciones">
                <button type="button" class="btn btn-info return-details" title="Ver detalles">
                  <i class="mdi mdi-plus"></i>
                </button>
              `;
            if (rowData.status == 'Por Aprobar') {
              content +=
                `
                  <button type="button" class="btn btn-facebook return-approve" title="Aprobar">
                    <i class="mdi mdi-check"></i>
                  </button>
                  <button type="button" class="btn btn-dribbble return-reject" title="Rechazar">
                    <i class="mdi mdi-close"></i>
                  </button>
                `;
            } else if (rowData.status == 'Aprobada' && !rowData.onStock) {
              content +=
                `
                  <button type="button" class="btn btn-warning update-warehouse" title="Sumar a Almacén">
                    <i class="fa fa-dropbox"></i>
                  </button>
                `;
            }
            content += `</div>`;
            $(td).prepend(content);
          }
        }
      ],
      drawCallback: () => {
        $('.return-details').on('click', (element) => {
          let data: any = this.returnsTable.row($(element.currentTarget).closest('tr')).data();
          this.openReturnModal(data);
        });

        $('.return-approve').on('click', (element) => {
          let data: any = this.returnsTable.row($(element.currentTarget).closest('tr')).data();
          this.openApproveModal(data);
        });

        $('.return-reject').on('click', (element) => {
          let data: any = this.returnsTable.row($(element.currentTarget).closest('tr')).data();
          this.openRejectModal(data);
        });

        $('.update-warehouse').on('click', (element) => {
          let data: any = this.returnsTable.row($(element.currentTarget).closest('tr')).data();
          this.openWarehouseModal(data);
        });
      }
    });

    $('#startDate').on('changeDate', function () {
      $('#startDate').val($('#startDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#startDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $('#endDate').datepicker('setStartDate', dtFormatted);
    });

    $('#endDate').on('changeDate', function () {
      $('#endDate').val($('#endDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#endDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $('#startDate').datepicker('setEndDate', dtFormatted);
    });
  }

  openReturnModal(data: any) {
    Object.keys(data).forEach(key => {
      if (this.returnForm.controls[key]) {
        this.returnForm.controls[key].setValue(data[key]);
      }
    });
    this.api.get('returns/findBoxes', this.returnForm.controls['id'].value).pipe(finalize(() => {
      this.returnModal.open(true);
    })).subscribe((data: any) => {
      data.forEach(element => {
        this.boxesFormArray.push(this.formBuilder.group({
          code: element.code,
          grade: element.grade,
          level: element.level,
        }));
      });
    });
  }

  closeReturnModal() {
    this.returnModal.close();
    this.returnForm.reset();
    while (this.boxesFormArray.length !== 0) {
      this.boxesFormArray.removeAt(0);
    }
    $('.modal-backdrop').remove();
  }

  openApproveModal(data: any) {
    Object.keys(data).forEach(key => {
      if (this.approveForm.controls[key]) {
        this.approveForm.controls[key].setValue(data[key]);
      }
    });
    this.approveModalTitle = 'Aprobar Devolución';
    this.boxCode = data.code;
    this.approveModal.open();
  }
  
  closeApproveModal() {
    if (!this.loading) {
      this.approveModalTitle = '';
      this.approveModal.close();
      this.approveForm.reset();
      this.approved = false;
      this.boxCode = '';
      $('.modal-backdrop').remove();
    }
  }

  submitApproveForm1() {
    if (this.approveForm.invalid) {
      return;
    }
    this.loading = true;
    var form: FormData = new FormData();
    form.append('id', this.approveForm.controls['id'].value);
    this.api.post('returns/approve', form).pipe(finalize(() => {
      this.loading = false;
      this.resetTable();
    })).subscribe(() => {
      this.approved = true;
      this.approveModalTitle = 'Sumar al Almacén';
      Alerts.AlertSuccess('La solicitud de devolución ha sido aprobada');
    }, (error) => {
      var errors = '';
      if (error.error.error) {
        errors = error.error.message;
      } else {
        errors = error.error.errors.join('; ');
      }
      Alerts.AlertFailed(errors);
    });
  }

  submitApproveForm2() {
    if (this.approveForm.invalid) {
      return;
    }
    this.loading = true;
    var form: FormData = new FormData();
    form.append('id', this.approveForm.controls['id'].value);
    this.api.post('returns/updateWarehouse', form).pipe(finalize(() => {
      this.loading = false;
      this.closeApproveModal();
      this.resetTable();
    })).subscribe(() => {
      Alerts.AlertSuccess('Las cajas han sido sumadas al almacén');
    }, (error) => {
      var errors = '';
      if (error.error.error) {
        errors = error.error.message;
      } else {
        errors = error.error.errors.join('; ');
      }
      Alerts.AlertFailed(errors);
    });
  }

  openRejectModal(data: any) {
    Object.keys(data).forEach(key => {
      if (this.rejectForm.controls[key]) {
        this.rejectForm.controls[key].setValue(data[key]);
      }
    });
    this.boxCode = data.code;
    this.rejectModal.open()
  }

  updateRejectModal() {
    this.rejected = true;
  }

  closeRejectModal() {
    if (!this.loading) {
      this.rejectModal.close();
      this.rejectForm.reset();
      this.submitted = false;
      this.rejected = false;
      this.boxCode = '';
      $('.modal-backdrop').remove();
    }
  }

  submitRejectForm() {
    if (this.rejectForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    var form: FormData = new FormData();
    form.append('id', this.rejectForm.controls['id'].value);
    form.append('rejectComment', this.rejectForm.controls['rejectComment'].value);
    this.api.post('returns/reject', form).pipe(finalize(() => {
      this.loading = false;
      this.resetTable();
    })).subscribe(() => {
      this.loading = false;
      this.closeRejectModal();
      Alerts.AlertSuccess('La solicitud de devolución ha sido rechazada');
    }, (error) => {
      var errors = '';
      if (error.error.error) {
        errors = error.error.message;
      } else {
        errors = error.error.errors.join('; ');
      }
      Alerts.AlertFailed(errors);
    });
  }

  openWarehouseModal(data: any) {
    Object.keys(data).forEach(key => {
      if (this.approveForm.controls[key]) {
        this.approveForm.controls[key].setValue(data[key]);
      }
    });
    this.approveModalTitle = 'Sumar al Almacén';
    this.boxCode = data.code;
    this.approved = true;
    this.approveModal.open();
  }
  
  refreshTable() {
    this.returnsTable.ajax.reload();
  }

  resetTable() {
    $('#keywords').val('');
    $('#final').val('');
    $('#initial').val('');
    this.returnsTable.ajax.reload();
  }
}
