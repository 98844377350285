import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService } from 'src/app/_api/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Alerts } from 'src/app/utils/alerts';

declare var $: any;

@Component({
  selector: 'app-exams',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.sass']
})
export class ExamsComponent implements OnInit, AfterViewInit {
  labelsForm: FormGroup;
  image: string = '';
  submitted = false;
  loading = false;
  success = false;

  constructor(private api: ApiService, private formBuilder: FormBuilder) {
    this.labelsForm = this.formBuilder.group({
      text_1: ['', [Validators.pattern(new RegExp('^[0-9A-Za-z ]*$')), Validators.maxLength(24)]],
      text_2: ['', [Validators.pattern(new RegExp('^[0-9A-Za-z ]*$')), Validators.maxLength(12)]],
      pieces: ['', [Validators.required, Validators.pattern(new RegExp('^[0-9]*$')), Validators.min(1)]],
      labels: ['', [Validators.required, Validators.pattern(new RegExp('^[0-9]*$')), Validators.min(1)]],
    });
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void { }

  submitForm() {
    if (this.labelsForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    var form: FormData = new FormData();
    Object.keys(this.labelsForm.value).forEach(key => {
      if (key != 'labels')
        form.append(key, this.labelsForm.controls[key].value);
    });
    this.api.post('printers/generatePreview', form).pipe(finalize(() => {
      this.loading = false;
    })).subscribe((data: any) => {
      this.success = true;
      this.image = 'data:image/png;base64,' + data.image;
    }, (error) => {
      Alerts.AlertFailed(error.error.message);
    });
  }

  cancelForm() {
    this.labelsForm.reset();
    this.submitted = false;
    this.loading = false;
    this.success = false;
    this.image = '';
  }

  printLabels() {
    if (this.labelsForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    var form: FormData = new FormData();
    Object.keys(this.labelsForm.value).forEach(key => {
      form.append(key, this.labelsForm.controls[key].value);
    });
    this.api.post('printers/print', form).pipe(finalize(() => {
      this.cancelForm();
    })).subscribe((data: any) => {
      if (data.Error) {
        Alerts.AlertFailed(data.Error);
      } else {
        if (data.ZPL) {
          Alerts.AlertSuccess('Impresión en curso, por favor espere un momento');
          this.openPrintWindow(data.ZPL);
        }
      }
    });
  }

  openPrintWindow(zpl: string) {
    var printWindow = window.open();
    printWindow.document.open('text/plain');
    printWindow.document.write(zpl);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  }
}