import { AbstractControl } from '@angular/forms';

export class ValidationUnit {
    static ValidateUnit(AC: AbstractControl) {
      var unit = AC.get('unit').value;
      if (unit == null) {
        AC.get('unit').setErrors({ ValidateUnit: true });
      } else {
        return null;
      }
    }
  }