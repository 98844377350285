import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm, AbstractControl } from '@angular/forms';
import { ApiService } from 'src/app/_api/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PasswordReset } from 'src/app/models/password-reset';
import { finalize } from 'rxjs/operators';
import { PasswordMatchValidator } from 'src/app/utils/password-match-validator';
declare var $: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.sass']
})

export class ResetPasswordComponent implements OnInit {
  passwordResetForm: FormGroup;
  loading = false;
  submitted = false;
  token: string = "";

  constructor(private formBuilder: FormBuilder,
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    this.passwordResetForm = this.formBuilder.group(
      {
        token: [null], 
        password: ['', [Validators.required, Validators.minLength(8)]], 
        confirmPassword: ['', Validators.required]
      }, 
      {
        validators: [PasswordMatchValidator.MatchPassword]
      }
    );
    this.token = this.route.snapshot.queryParamMap.get('token');
    this.passwordResetForm.controls['token'].setValue(this.token);
  }

  onSubmit() {
    if (this.passwordResetForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    var form: PasswordReset = Object.assign({}, this.passwordResetForm.value);
    this.api.post('reset-password', form).pipe(finalize(() => { this.loading = false })).subscribe((data: any) => {
      this.showSuccessToast("Tu contraseña fue cambiada con éxito");
      this.passwordResetForm.reset();
      this.router.navigate(['']);
    }, (error) => {
      this.showDangerToast(error.error.errors.join('; '));
      this.loading = false;
    });
  }

  get passwordMatch(): boolean {
    return this.passwordResetForm.controls['password'].value == this.passwordResetForm.controls['confirmPassword'].value;
  }

  showDangerToast(title) {
    $.toast({
      heading: 'Alerta',
      text: title,
      showHideTransition: 'slide',
      icon: 'error',
      loaderBg: '#f2a654',
      position: 'bottom-left'
    })
  };

  showSuccessToast(title) {
    $.toast({
      heading: 'Realizado',
      text: title,
      showHideTransition: 'slide',
      icon: 'success',
      loaderBg: '#f96868',
      position: 'bottom-left'
    });
  }
}
