import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/_api/api.service';
import { Alerts } from 'src/app/utils/alerts';
import { finalize } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-box-search',
  templateUrl: './box-search.component.html',
  styleUrls: ['./box-search.component.sass']
})

export class BoxSearchComponent implements OnInit {
  distribution: any = null;
  returns: any[] = [];
  books: any[] = [];
  searching = false;
  logs: any[] = [];
  invalid = false;
  loading = false;
  status = '';
  grade = '';
  level = '';
  date = '';
  id = null;
  
  constructor(private api: ApiService) { }

  ngOnInit() { }

  search() {
    var boxCode = $('#boxCode').val();
    if (!(/^C[0-9]{7}$/i.test(boxCode))) {
      this.invalid = true;
      return;
    }
    this.searching = true;
    this.invalid = false;
    this.loading = true;
    this.api.get('boxes/findBox', boxCode).pipe(finalize(() => {
      this.loading = false;
    })).subscribe((data: any) => {
      this.id = data.id;
      this.books = data.books;
      this.grade = data.grade;
      this.level = data.level;
      this.status = data.status;
      this.date = data.createdAt.slice(0, 10).replace(/-/g, '/');
      this.returns = data.returns;
      this.distribution = data.distribution;
      this.logs = [{ event:'Entrada a almacén', date: data.createdAt.slice(0, 10).replace(/-/g, '/') }];
      if (this.distribution) {
        this.distribution.idOrder.createdAt = this.distribution.idOrder.createdAt.slice(0, 10).replace(/-/g, '/');
        this.distribution.createdAt = this.distribution.createdAt.slice(0, 10).replace(/-/g, '/');
        this.logs.push({ event:'Salida de almacén', date: this.distribution.createdAt });
      }
      this.returns.forEach(element => {
        this.logs.push({ event: 'Solicitud de devolución ' + element.code, date: element.createdAt });
        if (element.status == 'Aprobada') {
          this.logs.push({ event: 'Solicitud de devolución ' + element.code + ' aprobada', date: element.updatedAt });
        } else if (element.status == 'Rechazada') {
          this.logs.push({ event: 'Solicitud de devolución ' + element.code + ' rechazada', date: element.updatedAt });
        }
      });
      this.logs.sort(this.compare);
    }, (error) => {
      this.reset();
      if (error.error.error) {
        Alerts.AlertFailed(error.error.message);
      } else {
        Alerts.AlertFailed(error.error.errors.join('; '));
      }
    });
  }

  reset() {
    this.distribution = null;
    this.searching = false;
    this.invalid = false;
    this.loading = false;
    this.returns = [];
    this.status = '';
    this.books = [];
    this.grade = '';
    this.level = '';
    this.date = '';
    this.id = null;
    this.logs = [];
    $('#boxCode').val('');
  }
  
  compare(a, b) {
    const dateA = a.date.split('/').reverse().join('/');
    const dateB = b.date.split('/').reverse().join('/');
    let comparison = 0;
    if (dateA > dateB) {
      comparison = 1;
    } else if (dateA < dateB) {
      comparison = -1;
    }
    return comparison;
  }
}
