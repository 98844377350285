import { Component, OnInit, ViewChild, Output, ViewContainerRef, EventEmitter, Input } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-modal-return',
  templateUrl: './modal-return.component.html',
  styleUrls: ['./modal-return.component.sass']
})
export class ModalReturnComponent implements OnInit {
  @ViewChild('modal', {read: ViewContainerRef}) modalContainer;
  @Output() onClose = new EventEmitter<void>();
  @Input() creatingTitle: string;
  @Input() showingTitle: string;
  @Input() loading = false;
  isCreating: boolean = true;

  constructor() { }

  ngOnInit() {
  }
  open(isCreating: boolean) {
    this.isCreating = isCreating;
    $(this.modalContainer.element.nativeElement).modal('show');
  }

  close() {
    $(this.modalContainer.element.nativeElement).modal('hide');
  }

  dismiss() {
    this.onClose.emit();
  }
}
