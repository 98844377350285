import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ApiService } from 'src/app/_api/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateModalComponent } from 'src/app/components/create-modal/create-modal.component';
import statesList from 'src/assets/js/statelist.json';
import townsList from 'src/assets/js/townlist.json';
import { Configuration } from 'src/app/models/configuration';
import { finalize } from 'rxjs/operators';
import { Alerts } from 'src/app/utils/alerts';
declare var $: any;

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.sass']
})

export class ConfigurationComponent implements OnInit, AfterViewInit {
  @ViewChild(CreateModalComponent)
  private createModal: CreateModalComponent;

  configurationForm: FormGroup;
  submitted: boolean;
  loading: boolean;
  isEditingConfiguration: boolean;
  states: any;
  towns: any;
  configuration: Configuration = null;

  constructor(private api: ApiService, private formBuilder: FormBuilder) {
    this.states = statesList;
  }

  ngAfterViewInit() {
    $('#states_office').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el estado',
      width: '100%',
      dropdownParent: $('#ConfigurationModal')
    });
    $('#states_office').val(null).trigger('change');

    $('#towns_office').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el municipio',
      width: '100%',
      disabled: true,
      dropdownParent: $('#ConfigurationModal')
    });
    $('#towns_office').val(null).trigger('change');

    $('#states_warehouse').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el estado',
      width: '100%',
      dropdownParent: $('#ConfigurationModal')
    });
    $('#states_warehouse').val(null).trigger('change');

    $('#towns_warehouse').select2({
      theme: "bootstrap",
      placeholder: 'Selecciona el municipio',
      width: '100%',
      disabled: true,
      dropdownParent: $('#ConfigurationModal')
    });
    $('#towns_warehouse').val(null).trigger('change');

    $('#states_office').on("change", () => {
      if ($('#states_office').val() != '') {
        var currentState = $('#states_office').val();
        townsList.forEach(element => {
          if (currentState == element['code']) {
            this.towns = element['towns'];
          }
        });
        $('#towns_office').empty().trigger("change");
        this.towns.forEach((town: any) => {
          var newOption = new Option(town, town, false, false);
          $('#towns_office').append(newOption).trigger('change');
        });
        $('#towns_office').val(null).trigger("change");
        $("#towns_office").prop("disabled", false);
        this.configurationForm.controls['stateOffice'].setValue(currentState);
      }
    });

    $('#towns_office').on("change", () => {
      if ($('#towns_office').val() != '') {
        var currentTown = $('#towns_office').val();
        this.configurationForm.controls['townOffice'].setValue(currentTown);
      }
    });

    $('#states_warehouse').on("change", () => {
      if ($('#states_warehouse').val() != '') {
        var currentState = $('#states_warehouse').val();
        townsList.forEach(element => {
          if (currentState == element['code']) {
            this.towns = element['towns'];
          }
        });
        $('#towns_warehouse').empty().trigger("change");
        this.towns.forEach((town: any) => {
          var newOption = new Option(town, town, false, false);
          $('#towns_warehouse').append(newOption).trigger('change');
        });
        $('#towns_warehouse').val(null).trigger("change");
        $("#towns_warehouse").prop("disabled", false);
        this.configurationForm.controls['stateWarehouse'].setValue(currentState);
      }
    });

    $('#towns_warehouse').on("change", () => {
      if ($('#towns_warehouse').val() != '') {
        var currentTown = $('#towns_warehouse').val();
        this.configurationForm.controls['townWarehouse'].setValue(currentTown);
      }
    });

    this.api.getAll('configurations').subscribe((configurationData: Configuration) => {
      this.configuration = configurationData;
    });
  }

  ngOnInit() {
    this.configurationForm = this.formBuilder.group({
      id: [null],
      nameOffice: ['', Validators.required],
      addressOffice: ['', Validators.required],
      colonyOffice: ['', Validators.required],
      zipOffice: ['', Validators.required],
      stateOffice: ['', Validators.required],
      townOffice: ['', Validators.required],
      phoneOffice: ['', Validators.required],
      addressWarehouse: ['', Validators.required],
      colonyWarehouse: ['', Validators.required],
      zipWarehouse: ['', Validators.required],
      stateWarehouse: ['', Validators.required],
      townWarehouse: ['', Validators.required],
    });
  }

  createConfiguration() {
    this.createModal.open(true);
    this.isEditingConfiguration = false;
  }

  editConfiguration() {
    Object.keys(this.configuration).forEach(key => {
      if (this.configurationForm.controls[key])
        this.configurationForm.controls[key].setValue(this.configuration[key]);
    });
    $('#states_office').val(this.configuration.stateOffice).trigger('change');
    $('#towns_office').val(this.configuration.townOffice).trigger('change');
    $('#states_warehouse').val(this.configuration.stateWarehouse).trigger('change');
    $('#towns_warehouse').val(this.configuration.townWarehouse).trigger('change');
    this.createModal.open(false);
    this.isEditingConfiguration = true;
  }

  cancelConfigurationForm() {
    this.configurationForm.reset();
    this.isEditingConfiguration = false;
    this.loading = false;
    this.submitted = false;
    this.createModal.close();
    $('#collapse-1').collapse('hide');
    $('#collapse-2').collapse('hide');
  }

  onSubmitConfigurationForm() {
    if (this.configurationForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    if (this.configurationForm.controls['id'].value) {
      var configuration: Configuration = Object.assign({}, this.configurationForm.value);
      this.api.patch('configurations', this.configurationForm.controls['id'].value, configuration).pipe(finalize(() => { this.loading = false; })).subscribe((configurationData: Configuration) => {
        Alerts.AlertSuccess('Configuración actualizada con éxito');
        this.configuration = configurationData;
        this.cancelConfigurationForm();
      }, (error) => {
        Alerts.AlertFailed(error.error.errors.join('; '));
      });
    } else {
      var configuration: Configuration = Object.assign({}, this.configurationForm.value);
      this.api.post('configurations', configuration).pipe(finalize(() => { this.loading = false; })).subscribe((configurationData: Configuration) => {
        Alerts.AlertSuccess('Configuración registrada con éxito');
        this.configuration = configurationData;
        this.cancelConfigurationForm();
      }, (error) => {
        Alerts.AlertFailed(error.error.errors.join('; '));
      });
    }
  }

  anyConfiguration(): boolean {
    if (this.configuration != null) {
      return true;
    } else {
      return false;
    }
  }

  capitalizeSentence(string) {
    return string.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
