import { Distributor } from './distributor';
import { User } from './user';
import { CodesProducts } from './codes-products';
import { Season } from './season';

export class Order{
    id: any;
    type: string;
    level: string;
    idDistributor: Distributor;
    idCodeProduct: CodesProducts;
    idSeason: Season;
    paymentMethod: string;
    shippingWay: string;
    freight: string;
    freightName: string;
    freightPaternalSurname: string;
    freightMaternalSurname: string;
    freightPhone: string;
    shippingAddress: string;
    shippingColony: string;
    shippingZip: string;
    shippingPhone: string;
    shippingState: string;
    shippingTown: string;
    invoiceAddress: string;
    invoiceColony: string;
    invoiceZip: string;
    businessName: string;
    rfc: string;
    invoiceNumber: string;
    invoiceDate: string;
    status: boolean;
    orderStatus: string;
    createdAt: string;
    updatedAt: string;
    idUser: User;
    comment: string;
}