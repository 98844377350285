import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { CreateModalComponent } from 'src/app/components/create-modal/create-modal.component';
import { DeleteModalComponent } from 'src/app/components/delete-modal/delete-modal.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/_api/api.service';
import { Season } from 'src/app/models/season';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-seasons',
  templateUrl: './seasons.component.html',
  styleUrls: ['./seasons.component.sass']
})
export class SeasonsComponent implements OnInit, AfterViewInit {
  @ViewChild(CreateModalComponent)
  private createModal: CreateModalComponent;
  @ViewChild(DeleteModalComponent)
  private deleteModal: DeleteModalComponent;
  isEditingSeason: boolean = false;
  private seasonsTable: any;
  seasonForm: FormGroup;
  submitted = false;
  loading = false;

  constructor(private api: ApiService, private builder: FormBuilder) { }

  ngOnInit(): void {
    this.seasonForm = this.builder.group({
      id: [null],
      description: [''],
      name: ['', Validators.required],
      finalDate: ['', Validators.required],
      initialDate: ['', Validators.required],
      activated: [false, Validators.required]
    });
  }

  ngAfterViewInit(): void {
    $('#datepicker-1,#datepicker-2').datepicker({ enableOnReadonly: false, todayHighlight: true, autoclose: true });

    $('#datepicker-3,#datepicker-4').datepicker({ format: 'dd/mm/yyyy', enableOnReadonly: false, todayHighlight: true, autoclose: true });

    this.seasonsTable = $('#seasons-table').DataTable({
      'ajax': {
        url: `${environment.apiUrl}/seasons`,
        data: function (data) {
          data.name = $('#searchName').val();
          data.from = $('#fromDate').val();
          data.to = $('#toDate').val();
        }
      },
      'paging': true,
      'searching': false,
      'serverSide': true,
      'lengthChange': false,
      'language': { 'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json' },
      columns: [
        { data: 'id' },
        { data: 'name' },
        { data: 'description' },
        {
          data: 'initialDate',
          render: function (data, type, row) {
            return data.split('-').reverse().join('-');
          }
        },
        {
          data: 'finalDate',
          render: function (data, type, row) {
            return data.split('-').reverse().join('-');
          }
        },
        {
          data: 'activated',
          render: function (data, type, row) {
            if (data) {
              return 'Activa';
            } else {
              return 'Inactiva';
            }
          }
        },
        {
          data: null,
          orderable: false,
          searchable: false,
          defaultContent: '',
          createdCell: function (td, cellData, rowData, row, col) {
            $(td).prepend(
              `<div class="btn-group" role="group" aria-label="Acciones">
                <button type="button" class="btn btn-warning season-edit" title="Editar">
                  <i class="mdi mdi-lead-pencil text-white"></i>
                </button>
                <button type="button" class="btn btn-danger season-delete" title="Eliminar">
                  <i class="mdi mdi-delete-forever"></i>
                </button>
              </div>`
            );
          }
        }
      ],
      columnDefs: [{ 'targets': [0], 'visible': false, 'searchable': false }],
      drawCallback: () => {
        $('.season-edit').on('click', (element) => {
          let season: Season = this.seasonsTable.row($(element.currentTarget).closest('tr')).data();
          this.editSeason(season);
        });

        $('.season-delete').on('click', (element) => {
          let season: Season = this.seasonsTable.row($(element.currentTarget).closest('tr')).data();
          this.deleteSeason(season);
        });
      }
    });

    $('#datepicker-1').on('changeDate', function () {
      var thisValue = $(this).datepicker('getFormattedDate');
      if (!thisValue) {
        return;
      }
      $('#fromDate').val(thisValue);
      $('#datepicker-2').datepicker('setStartDate', new Date(thisValue));
    });

    $('#datepicker-2').on('changeDate', function () {
      var thisValue = $(this).datepicker('getFormattedDate');
      if (!thisValue) {
        return;
      }
      $('#toDate').val(thisValue);
      $('#datepicker-1').datepicker('setEndDate', new Date(thisValue));
    });

    $('#initialDate').on('change', () => {
      var thisValue = $('#initialDate').val().split('/').reverse().join('-');
      this.seasonForm.controls['initialDate'].setValue(thisValue);
      if (thisValue != '') {
        $('#datepicker-4').datepicker('setStartDate', new Date(thisValue + 'T23:59:59'));
      } else {
        $('#datepicker-4').datepicker('setStartDate', -Infinity);
      }
    });

    $('#finalDate').on('change', () => {
      var thisValue = $('#finalDate').val().split('/').reverse().join('-');
      this.seasonForm.controls['finalDate'].setValue(thisValue);
      if (thisValue != '') {
        $('#datepicker-3').datepicker('setEndDate', new Date(thisValue + 'T23:59:59'));
      } else {
        $('#datepicker-3').datepicker('setEndDate', Infinity);
      }
    });

    $('#activated').on('change', () => {
      if ($('#activated').is(':checked')) {
        $('#activated').next().text('Activa');
      } else {
        $('#activated').next().text('Inactiva');
      }
    });
  }

  createSeason(): void {
    this.createModal.open(true);
    this.isEditingSeason = false;
  }

  refreshSeasonsTable(): void {
    this.seasonsTable.ajax.reload();
  }

  resetSeasonsTable(): void {
    $('#searchName').val('');
    $('#datepicker-1').data('datepicker').setDate(null);
    $('#datepicker-2').data('datepicker').setDate(null);
    $('#datepicker-1,#datepicker-2').datepicker('setStartDate', -Infinity);
    $('#datepicker-1,#datepicker-3').datepicker('setEndDate', Infinity);
    this.seasonsTable.ajax.reload();
  }

  cancelSeasonForm(): void {
    this.submitted = false;
    this.seasonForm.reset();
    this.createModal.close();
    this.deleteModal.close();
    this.seasonsTable.ajax.reload();
    $('#datepicker-3,#datepicker-4').datepicker('setStartDate', -Infinity);
    $('#datepicker-3,#datepicker-4').datepicker('setEndDate', Infinity);
  }

  editSeason(season: Season): void {
    this.isEditingSeason = true;
    Object.keys(season).forEach(key => {
      if (this.seasonForm.controls[key]) {
        if (key == 'initialDate') {
          $('#datepicker-3').data('datepicker').setDate(season[key].split('-').reverse().join('/'));
        } else if (key == 'finalDate') {
          $('#datepicker-4').data('datepicker').setDate(season[key].split('-').reverse().join('/'));
        }
        this.seasonForm.controls[key].setValue(season[key]);
      }
    });
    this.createModal.open(false);
  }

  deleteSeason(season: Season): void {
    Object.keys(season).forEach(key => {
      if (this.seasonForm.controls[key])
        this.seasonForm.controls[key].setValue(season[key]);
    });
    this.deleteModal.open();
  }

  onSubmitSeasonForm(): void {
    if (this.seasonForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    var season: Season = Object.assign({}, this.seasonForm.value);
    if (this.seasonForm.controls['id'].value) {
      this.api.patch('seasons', this.seasonForm.controls['id'].value, season).pipe(finalize(() => { this.loading = false; })).subscribe((seasons: Season[]) => {
        this.cancelSeasonForm();
        this.alertSuccess('Temporada actualizada con éxito');
      }, (error) => {
        this.alertFailed(error.error.errors.join('; '));
      });
    } else {
      this.api.post('seasons', season).pipe(finalize(() => { this.loading = false; })).subscribe((seasons: Season[]) => {
        this.cancelSeasonForm();
        this.alertSuccess('Temporada guardada con éxito');
      }, (error) => {
        this.alertFailed(error.error.errors.join('; '));
      });
    }
  }

  onDeleteSeasonForm(): void {
    this.loading = true;
    var season: Season = Object.assign({}, this.seasonForm.value);
    this.api.patch('seasons/delete', this.seasonForm.controls['id'].value, season).pipe(finalize(() => { this.loading = false; })).subscribe((seasons: Season[]) => {
      this.cancelSeasonForm();
      this.alertSuccess('Temporada eliminada con éxito');
    });
  }

  alertSuccess(title): void {
    $.toast({
      heading: 'Realizado',
      text: title,
      showHideTransition: 'slide',
      icon: 'success',
      loaderBg: '#f96868',
      position: 'bottom-left'
    });
  }

  alertFailed(title): void {
    $.toast({
      heading: 'Error',
      text: title,
      showHideTransition: 'slide',
      icon: 'error',
      loaderBg: '#f2a654',
      position: 'bottom-left'
    });
  }
}
