import { Season } from './season';

export class CodesProducts {
    id: any;
    prefix: string;
    description : string; 
    level: string; 
    quantity: number;
    price: number;
    idSeason: Season;
    physicalLabels: boolean;
    onOrder: boolean;
}