import { Component, OnInit, ViewChild, Output, Input, ViewContainerRef, EventEmitter } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-delete-modal-return',
  templateUrl: './delete-modal-return.component.html',
  styleUrls: ['./delete-modal-return.component.sass']
})
export class DeleteModalReturnComponent implements OnInit {
  @ViewChild('modal', {read: ViewContainerRef}) modalContainer;
  @Output() onClose = new EventEmitter<void>();
  @Input() deletingTitle: string;
  @Input() loading = false;

  constructor() { }

  ngOnInit() {
  }

  open() {
    $(this.modalContainer.element.nativeElement).modal('show');
  }

  close() {
    $(this.modalContainer.element.nativeElement).modal('hide');
  }

  dismiss() {
    this.onClose.emit();
  }

}
