import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/_api/api.service';
import { PasswordReset } from 'src/app/models/password-reset';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { User } from 'src/app/models';
import { PasswordMatchValidator } from 'src/app/utils/password-match-validator';
import { finalize } from 'rxjs/operators';
declare var $ : any;

@Component({
  selector: 'app-reset-password-user',
  templateUrl: './reset-password-user.component.html',
  styleUrls: ['./reset-password-user.component.sass']
})

export class ResetPasswordUserComponent implements OnInit {
  passwordResetForm: FormGroup;
  loading = false;
  submitted = false;
  currentUser: User;

  constructor(private formBuilder: FormBuilder,
    private api: ApiService,
    private auth: AuthenticationService) { }

  ngOnInit() {
    this.currentUser = this.auth.user;
    this.passwordResetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required]
    }, {
      validators: [PasswordMatchValidator.MatchPassword]
    });
  }

  onSubmit() {
    if (this.passwordResetForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    var formData: FormData = new FormData();
    formData.append('password', this.passwordResetForm.controls['password'].value);
    formData.append('email', this.currentUser.email);

    this.api.post('reset-password/user', formData).pipe(finalize(() => { this.loading = false;})).subscribe((data: any) => {
      
      this.showSuccessToast("Tu contraseña fue cambiada con éxito");
      this.passwordResetForm.reset();
      this.submitted = false;
    });

  }

  get passwordMatch(): boolean {
    return this.passwordResetForm.controls['password'].value == this.passwordResetForm.controls['confirmPassword'].value;
  }

  showSuccessToast(title) {
    $.toast({
      heading: 'Realizado',
      text: title,
      showHideTransition: 'slide',
      icon: 'success',
      loaderBg: '#f96868',
      position: 'bottom-left'
    });
  }
}
