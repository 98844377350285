import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService } from 'src/app/_api/api.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-products-log',
  templateUrl: './products-log.component.html',
  styleUrls: ['./products-log.component.sass']
})
export class ProductsLogComponent implements OnInit, AfterViewInit {
  logsTable: any;

  constructor(private api: ApiService) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    $('.datepicker').datepicker({
      enableOnReadonly: true,
      todayHighlight: true,
      autoclose: true,
    });

    $('#initial-date').on('changeDate', function () {
      $('#start-date').val($('#initial-date').datepicker('getFormattedDate'));
      var dtMax = new Date($('#start-date').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $('#final-date').datepicker('setStartDate', dtFormatted);
    });

    $('#final-date').on('changeDate', function () {
      $('#last-date').val($('#final-date').datepicker('getFormattedDate'));
      var dtMax = new Date($('#last-date').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $('#initial-date').datepicker('setEndDate', dtFormatted);
    });

    this.logsTable = $('#logs-table').DataTable({
      'ajax': {
        url: `${environment.apiUrl}/products-log`,
        data: function (data) {
          data.searchData = $('#search').val();
          data.from = $('#start-date').val();
          data.to = $('#last-date').val();
        }
      },
      'paging': true,
      'searching': false,
      'serverSide': true,
      'lengthChange': false,
      'order': [[2, 'desc']],
      'language': { 'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json' },
      'columns': [
        {
          data: 'productId',
          render: (data, type, row, meta) => {
            return row.productId.code + ' - ' + row.productId.title;
          }
        },
        { data: 'quantity' },
        { data: 'createdAt' }
      ]
    });
  }

  refreshTable() {
    this.logsTable.ajax.reload();
  }

  resetTable() {
    $('#initial-date').data('datepicker').setDate(null);
    $('#final-date').data('datepicker').setDate(null);
    $('#search').val('');
    this.logsTable.ajax.reload();
  }
}