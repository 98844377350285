import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule, LOCALE_ID } from '@angular/core';
import { MatTable, MatTableModule, MatPaginatorModule } from '@angular/material';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { MainComponent } from './layouts/main/main.component';
import { LoginComponent } from './pages/login/login.component';
import { BlankComponent } from './pages/blank/blank.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'; 
import { JwtInterceptor } from './interceptors/jwt-interceptor';
import { ErrorInterceptor } from './interceptors/error-interceptor';
import { FormsModule } from '@angular/forms';
import { ExamsComponent } from './pages/exams/exams.component';
import { ProductSupplyComponent } from './pages/product-supply/product-supply.component';
import { WorkbookSupplyComponent } from './pages/workbook-supply/workbook-supply.component';
import { UsersComponent } from './pages/users/users.component';
import { CreateModalComponent } from './components/create-modal/create-modal.component';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { BookSearchComponent } from './pages/book-search/book-search.component';
import { BoxSearchComponent } from './pages/box-search/box-search.component';
import { ReturnsComponent } from './pages/returns/returns.component';
import { Ng2FileSizeModule } from 'ng2-file-size';
import { ModalReturnComponent } from './components/modal-return/modal-return.component';
import { DeleteModalReturnComponent } from './components/delete-modal-return/delete-modal-return.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { DistributorsComponent } from './pages/distributors/distributors.component';
import { ProductsComponent } from './pages/products/products.component';
import { DistributionsComponent } from './pages/distributions/distributions.component';
import { ReturnComponent } from './pages/return/return.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { LogComponent } from './pages/log/log.component';
import { DistributionComponent } from './pages/distribution/distribution.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ResetPasswordUserComponent } from './pages/reset-password-user/reset-password-user.component';
import { RegisterComponent } from './pages/register/register.component';
import { AddressesComponent } from './pages/addresses/addresses.component';
import { NgxMaskModule, IConfig} from 'ngx-mask';
import { OrdersComponent } from './pages/orders/orders.component';
import { OrderComponent } from './pages/order/order.component';
import { SeasonsComponent } from './pages/seasons/seasons.component'
import { ProductsSettingsComponent } from './pages/products-settings/products-settings.component';
import { DistributorPricesComponent } from './pages/distributor-prices/distributor-prices.component';
import { PaymentFormsComponent } from './pages/payment-forms/payment-forms.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { DistributorPaymentsComponent } from './pages/distributor-payments/distributor-payments.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { BoxesCodesComponent } from './pages/boxes-codes/boxes-codes.component';
import { GenerateCodesComponent } from './pages/generate-codes/generate-codes.component';
import { TrashBoxesComponent } from './pages/trash-boxes/trash-boxes.component';
import { DistributorAddressesComponent } from './pages/distributor-addresses/distributor-addresses.component';
import { ProductCodeWithouthLabelSupplyComponent } from './pages/product-code-withouth-label-supply/product-code-withouth-label-supply.component';
import { ProductsLogComponent } from './pages/products-log/products-log.component';
import { BoxesInformationComponent } from './pages/boxes-information/boxes-information.component';
import { TrashBoxesUnlabeledComponent } from './pages/trash-boxes-unlabeled/trash-boxes-unlabeled.component';
import { ProductsToOrderComponent } from './pages/products-to-order/products-to-order.component';
import { ImportoutputComponent } from './pages/importoutput/importoutput.component';
import { ReportComponent } from './pages/report/report.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    MainComponent,
    LoginComponent,
    BlankComponent,
    ExamsComponent,
    ProductSupplyComponent,
    WorkbookSupplyComponent,
    UsersComponent,
    CreateModalComponent,
    DeleteModalComponent,
    BookSearchComponent,
    BoxSearchComponent,
    ReturnsComponent,
    ModalReturnComponent,
    DeleteModalReturnComponent,
    CategoriesComponent,
    DistributorsComponent,
    ProductsComponent,
    DistributionsComponent,
    ReturnComponent,
    ReportsComponent,
    LogComponent,
    DistributionComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ResetPasswordUserComponent,
    RegisterComponent,
    AddressesComponent,
    OrdersComponent,
    OrderComponent,
    SeasonsComponent,
    ProductsSettingsComponent,
    DistributorPricesComponent,
    PaymentFormsComponent,
    PaymentsComponent,
    DistributorPaymentsComponent,
    ConfigurationComponent,
    BoxesCodesComponent,
    GenerateCodesComponent,
    TrashBoxesComponent,
    DistributorAddressesComponent,
    ProductCodeWithouthLabelSupplyComponent,
    ProductsLogComponent,
    BoxesInformationComponent,
    TrashBoxesUnlabeledComponent,
    ProductsToOrderComponent,
    ImportoutputComponent,
    ReportComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    Ng2FileSizeModule,
    NgxMaskModule.forRoot(options)
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-MX' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }