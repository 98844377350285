import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService } from 'src/app/_api/api.service';
import { Season } from 'src/app/models/season';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-boxes-information',
  templateUrl: './boxes-information.component.html',
  styleUrls: ['./boxes-information.component.sass']
})
export class BoxesInformationComponent implements OnInit, AfterViewInit {
  boxesTable: any;
  seasons: Season[] = [];
  constructor(private api: ApiService) { }

  ngOnInit() {
    this.api.getAll('seasons/getSeasons').subscribe((seasons: Season[]) => {
      this.seasons = seasons;
    });
  }

  ngAfterViewInit(): void {
    $('.select2-control').select2({ width: '100%', theme: 'bootstrap' });

    this.boxesTable = $('#boxes-table').DataTable({
      ajax: {
        url: `${environment.apiUrl}/boxes/information`,
        data: function (data) {
          data.searchData = $("#search").val();
          data.searchGrade = $("#grades").val();
          data.searchSeason = $("#idSeason").val();
        },
        dataSrc: ""
      },
      'serverSide': false,
      'searching': false,
      'paging': true,
      'lengthChange': false,
      'language': {
        'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json'
      },
      columns: [
        { data: 'product.prefix' },
        { data: 'product.description' },
        { data: 'product.level' },
        {
          data: 'grade',
          render: function (data, type, row) {
            if (data == '7') {
              return 'Unico';
            } else {
              return data;
            }
          }
        },
        { data: 'product.idSeason.name' },
        { data: 'boxesInWarehouse' },
        { data: 'boxesDistributed' },
      ],
    });
  }

  refreshBoxesTable() {
    this.boxesTable.ajax.reload();
  }

  resetBoxesTable() {
    $("#search").val('');
    $("#grades").val(null).trigger('change');
    $("#idSeason").val(null).trigger('change');
    this.boxesTable.ajax.reload();
  }
}
