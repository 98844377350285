import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlankComponent } from './pages/blank/blank.component';
import { MainComponent } from './layouts/main/main.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { ExamsComponent } from './pages/exams/exams.component';
import { ProductSupplyComponent } from "./pages/product-supply/product-supply.component";
import { WorkbookSupplyComponent } from "./pages/workbook-supply/workbook-supply.component";
import { UsersComponent } from "./pages/users/users.component";
import { BookSearchComponent } from './pages/book-search/book-search.component';
import { BoxSearchComponent } from './pages/box-search/box-search.component';
import { ReturnsComponent } from './pages/returns/returns.component';
import { RoleGuard } from './guards/role.guard';
import { CategoriesComponent } from './pages/categories/categories.component';
import { DistributorsComponent } from './pages/distributors/distributors.component';
import { ProductsComponent } from './pages/products/products.component';
import { DistributionsComponent } from './pages/distributions/distributions.component';
import { ReturnComponent } from './pages/return/return.component';
import { SeasonsComponent } from './pages/seasons/seasons.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { LogComponent } from './pages/log/log.component';
import { DistributionComponent } from './pages/distribution/distribution.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ResetPasswordUserComponent } from './pages/reset-password-user/reset-password-user.component';
import { RegisterComponent } from './pages/register/register.component';
import { AddressesComponent } from './pages/addresses/addresses.component';
import { OrderComponent } from './pages/order/order.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { ProductsSettingsComponent } from './pages/products-settings/products-settings.component';
import { DistributorPricesComponent } from './pages/distributor-prices/distributor-prices.component';
import { PaymentFormsComponent } from './pages/payment-forms/payment-forms.component';
import { PaymentsComponent } from './pages/payments/payments.component';
import { DistributorPaymentsComponent } from './pages/distributor-payments/distributor-payments.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { BoxesCodesComponent } from './pages/boxes-codes/boxes-codes.component';
import { GenerateCodesComponent } from './pages/generate-codes/generate-codes.component';
import { TrashBoxesComponent } from './pages/trash-boxes/trash-boxes.component';
import { DistributorAddressesComponent } from './pages/distributor-addresses/distributor-addresses.component';
import { ProductCodeWithouthLabelSupplyComponent } from './pages/product-code-withouth-label-supply/product-code-withouth-label-supply.component';
import { ProductsLogComponent } from './pages/products-log/products-log.component';
import { BoxesInformationComponent } from './pages/boxes-information/boxes-information.component';
import { TrashBoxesUnlabeledComponent } from './pages/trash-boxes-unlabeled/trash-boxes-unlabeled.component';
import { ProductsToOrderComponent } from './pages/products-to-order/products-to-order.component';
import { ImportoutputComponent } from './pages/importoutput/importoutput.component';
import { ReportComponent } from './pages/report/report.component';
const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: BlankComponent
      },
      {
        path: 'generate-codes',
        component: GenerateCodesComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR']
        }
      },
      {
        path: 'exam-labels',
        component: ExamsComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR']
        }
      },
      {
        path: 'boxes-codes',
        component: BoxesCodesComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR']
        }
      },
      {
        path: 'product-settings',
        component: ProductsSettingsComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR']
        }
      },
      {
        path: 'distributor-prices',
        component: DistributorPricesComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR']
        }
      },
      {
        path: 'distributor-addresses',
        component: DistributorAddressesComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR']
        }
      },
      {
        path: 'payment-forms',
        component: PaymentFormsComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR']
        }
      },
      {
        path: 'office-configuration',
        component: ConfigurationComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR']
        }
      },
      {
        path : 'product-supply',
        component: ProductSupplyComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR', 'ROLE_WAREHOUSEMAN']
        }
      },
      {
        path: 'workbook-supply',
        component: WorkbookSupplyComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR', 'ROLE_WAREHOUSEMAN']
        }
      },
      {
        path: 'product-code-supply',
        component: ProductCodeWithouthLabelSupplyComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR', 'ROLE_WAREHOUSEMAN']
        }
      },
      {
        path: 'products-log',
        component: ProductsLogComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR', 'ROLE_WAREHOUSEMAN']
        }
      },
      {
        path: 'boxes-information',
        component: BoxesInformationComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR', 'ROLE_WAREHOUSEMAN']
        }
      },
      {
        path: 'categories',
        component: CategoriesComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR']
        }
      },
      {
        path: 'products',
        component: ProductsComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR']
        }
      },
      {
        path: 'distributors',
        component: DistributorsComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR']
        }
      },
      {
        path: 'orders',
        component: OrdersComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR']
        }
      },
      {
        path: 'distributions',
        component: DistributionsComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR', 'ROLE_WAREHOUSEMAN']
        }
      },
      /* {
        path: 'books-search',
        component: BookSearchComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR']
        }
      }, */
      {
        path: 'box-search',
        component: BoxSearchComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR']
        }
      },
      {
        path: 'trash-boxes',
        component: TrashBoxesComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR', 'ROLE_WAREHOUSEMAN']
        }
      },
      {
        path: 'trash-boxes-unlabeled',
        component: TrashBoxesUnlabeledComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR', 'ROLE_WAREHOUSEMAN']
        }
      },
      {
        path: 'payments',
        component: PaymentsComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR']
        }
      },
      {
        path: 'returns',
        component: ReturnComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR']
        }
      },
      {
        path: 'seasons',
        component: SeasonsComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR']
        }
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR']
        }
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR']
        }
      },
      {
        path: 'logs',
        component: LogComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_SUPER_ADMINISTRATOR']
        }
      },
      {
        path: 'addresses',
        component: AddressesComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_DISTRIBUTOR']
        }
      },
      {
        path: 'orders-distributor',
        component: OrderComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_DISTRIBUTOR']
        }
      },
      {
        path: 'distribution',
        component: DistributionComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_DISTRIBUTOR']
        }
      },
      /*{
        path: 'distributor-payments',
        component: DistributorPaymentsComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_DISTRIBUTOR']
        }
      },*/
      {
        path: 'returns-distributor',
        component: ReturnsComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_DISTRIBUTOR']
        }
      },
      {
        path: 'reset-password-user',
        component: ResetPasswordUserComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_WAREHOUSEMAN', 'ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR', 'ROLE_DISTRIBUTOR']
        }
      },
      {
        path: 'role-admin',
        component: BlankComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_DISTRIBUTOR', 'ROLE_ADMINISTRATOR', 'ROLE_SUPER_ADMINISTRATOR']
        }
      },
      {
        path: 'products-to-order',
        component: ProductsToOrderComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_DISTRIBUTOR', 'ROLE_ADMINISTRATOR', 'ROLE_SUPER_ADMINISTRATOR']
        }
      },
      /*{
        path: 'import-output',
        component: ImportoutputComponent,
        canActivate: [RoleGuard],
        data: {
          //roles: ['ROLE_DISTRIBUTOR', 'ROLE_ADMINISTRATOR', 'ROLE_SUPER_ADMINISTRATOR']
          roles: ['ROLE_SUPER_ADMINISTRATOR', 'ROLE_ADMINISTRATOR', 'ROLE_WAREHOUSEMAN']
        }
      },*/
      {
        path: 'report',
        component: ReportComponent,
        canActivate: [RoleGuard],
        data: {
          roles: ['ROLE_DISTRIBUTOR']
        }
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }