import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ModalReturnComponent } from 'src/app/components/modal-return/modal-return.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/_api/api.service';
import { Address } from 'src/app/models/address';
import statesList from 'src/assets/js/statelist.json';
import townsList from 'src/assets/js/townlist.json';
import { finalize } from 'rxjs/operators';
import { Alerts } from 'src/app/utils/alerts';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-distributor-addresses',
  templateUrl: './distributor-addresses.component.html',
  styleUrls: ['./distributor-addresses.component.sass']
})
export class DistributorAddressesComponent implements OnInit, AfterViewInit {
  @ViewChild('create')
  private createModal: ModalReturnComponent;

  private addressesTable: any;
  addressForm: FormGroup;
  submitted = false
  editForm = false;
  productName = '';
  showForm = false;
  modalTitle = '';
  loading = false;
  states: any;
  towns: any;
  copyAddress: boolean = false;

  constructor(private changes: ChangeDetectorRef, private formBuilder: FormBuilder, private api: ApiService) {
    this.states = statesList;
  }

  ngAfterViewInit() {
    $('#states_code').select2({ width: '100%', theme: 'bootstrap', dropdownParent: $('#AddressModal'), placeholder: 'Selecciona el estado' });

    $('#states_code').val(null).trigger('change');

    $('#towns_code').select2({ width: '100%', disabled: true, theme: "bootstrap", dropdownParent: $('#AddressModal'), placeholder: 'Selecciona el municipio' });

    $('#towns_code').val(null).trigger('change');

    $('#shipping_way').select2({ width: '100%', theme: 'bootstrap', dropdownParent: $('#AddressModal'), placeholder: 'Selecciona la forma de envío' });

    $('#states_code').on('change', () => {
      if ($('#states_code').val() != '') {
        var currentState = $('#states_code').val();
        townsList.forEach(element => {
          if (currentState == element['code']) {
            this.towns = element['towns'];
          }
        });

        $('#towns_code').empty().trigger('change');

        this.towns.forEach((town: any) => {
          var newOption = new Option(town, town, false, false);
          $('#towns_code').append(newOption).trigger('change');
        });

        $('#towns_code').val(null).trigger('change');
        $('#towns_code').prop('disabled', false);

        this.addressForm.controls['shippingState'].setValue(currentState);
        this.addressForm.controls['shippingTown'].setValue('');
      }
    });

    $('#towns_code').on('change', () => {
      if ($('#towns_code').val() != '') {
        var currentTown = $('#towns_code').val();
        this.addressForm.controls['shippingTown'].setValue(currentTown);
      }
    });

    $('#shipping_way').on('change', () => {
      if ($('#shipping_way').val() != '') {
        var currentShippingWay = $('#shipping_way').val();
        this.addressForm.controls['shippingWay'].setValue(currentShippingWay);
      }
    });

    this.addressesTable = $('#addresses-table').DataTable({
      'ajax': {
        url: `${environment.apiUrl}/addresses/getAllAddressess`,
        data: function (data) {
          data.name = $('#name').val();
        }
      },
      'serverSide': true,
      'searching': false,
      'paging': true,
      'pageLength': 10,
      'lengthChange': false,
      'language': {
        'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json'
      },
      columns: [
        {
          data: 'id',
          orderable: false,
          defaultContent: '',
          className: 'details-control',
          render: function (data, type, row, meta) {
            return '';
          }
        },
        { data: 'id', visible: false },
        { data: 'code' },
        {
          data: 'name',
          render: (data, type, row, meta) => {
            return row['name'] + ' ' + row['paternalSurname'] + ' ' + row['maternalSurname'];
          }
        },
        {
          data: 'state',
          render: (data, type, row, meta) => {
            var state = this.states.find((element) => { return element.code == row['state']; });
            return state.name.replace(/\w\S*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
          }
        }
      ]
    });

    $('#addresses-table tbody').on('click', 'td.details-control', (event) => {
      var table = $('#addresses-table').DataTable();
      var tr = $(event.currentTarget).closest('tr');
      var row = table.row(tr);
      if (row.child.isShown()) {
        row.child.hide();
        tr.removeClass('shown');
      } else {
        this.api.get('addresses/getAllAddressesByDistributor', row.data().id).subscribe((addresses: Address[]) => {
          var newTable = `<table border="0" cellpadding="5" cellspacing="0" width="100%">`;
          for (let index = 0; index < addresses.length; index++) {
            var style = ``;
            if (index == 0) {
              style = `border-top: none;`;
            }
            var newTr =
              `<tr>
                <td style="` + style + ` text-align: left;">` + addresses[index].addressName + `</td>
                <td style="` + style + ` text-align: left;">
                  <input type="hidden" class="distributor-id" value="` + row.data().id + `">
                  <input type="hidden" class="address-id" value="` + addresses[index].id + `">
                  <div class="btn-group" role="group" aria-label="Acciones" style="float: right;" align="right">
                    <button type="button" class="btn btn-info addresses-details" title="Ver detalles">
                      <i class="mdi mdi-plus text-white"></i>
                    </button>
                    <button type="button" class="btn btn-warning addresses-edit" title="Editar">
                      <i class="mdi mdi-lead-pencil text-white"></i>
                    </button>
                  </div>
                </td>
              </tr>`;
            newTable += newTr;
          }
          newTable += `</table>`;
          row.child(newTable).show();
          tr.addClass('shown');
        });
      }
    });

    $(document).on('click', '.addresses-details', (event) => {
      var distributor = $(event.currentTarget).closest('td').find('input.distributor-id').val();
      var address = $(event.currentTarget).closest('td').find('input.address-id').val();
      this.showAddress(distributor, address);
      this.createModal.open(false);
    });

    $(document).on('click', '.addresses-edit', (event) => {
      var distributor = $(event.currentTarget).closest('td').find('input.distributor-id').val();
      var address = $(event.currentTarget).closest('td').find('input.address-id').val();
      this.editAddress(distributor, address);
      this.createModal.open(false);
    });
  }

  ngOnInit() {
    this.addressForm = this.formBuilder.group({
      id: [null],
      idDistributor: ['', Validators.required],
      addressName: ['', Validators.required],
      freight: ['', Validators.required],
      freightName: ['', Validators.required],
      freightPaternalSurname: ['', Validators.required],
      freightMaternalSurname: ['', Validators.required],
      freightPhone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      shippingAddress: ['', Validators.required],
      shippingColony: ['', Validators.required],
      shippingZip: ['', Validators.required],
      shippingTown: [null, Validators.required],
      shippingState: [null, Validators.required],
      shippingPhone: [''],
      shippingWay: ['', Validators.required],
      invoiceAddress: ['', Validators.required],
      invoiceColony: ['', Validators.required],
      invoiceZip: ['', Validators.required],
      businessName: ['', Validators.required],
      rfc: ['', Validators.required]
    });
  }

  showAddress(distributor: number, address: number): void {
    this.showForm = true;
    this.addressForm.controls['idDistributor'].setValue(distributor);

    this.api.get('addresses', address).subscribe((address: Address) => {
      Object.keys(address).forEach(key => {
        if (this.addressForm.controls[key]) {
          this.addressForm.controls[key].setValue(address[key]);
        }
      });
      $('#states_code').val(address.shippingState).trigger('change');
      $('#towns_code').val(address.shippingTown).trigger('change');
      $('#shipping_way').val(address.shippingWay).trigger('change');
      $('#states_code').prop('disabled', 'disabled');
      $('#towns_code').prop('disabled', 'disabled');
      $('#shipping_way').prop('disabled', 'disabled');
      this.createModal.open(false);
    });
  }

  editAddress(distributor: number, address: number): void {
    this.editForm = true;
    this.addressForm.controls['idDistributor'].setValue(distributor);
    this.api.get('addresses', address).subscribe((address: Address) => {
      Object.keys(address).forEach(key => {
        if (this.addressForm.controls[key]) {
          this.addressForm.controls[key].setValue(address[key]);
        }
      });
      $('#states_code').val(address.shippingState).trigger('change');
      $('#towns_code').val(address.shippingTown).trigger('change');
      $('#shipping_way').val(address.shippingWay).trigger('change');
      $('#states_code').prop('disabled', false);
      $('#towns_code').prop('disabled', false);
      $('#shipping_way').prop('disabled', false);
      this.createModal.open(true);
    });

  }

  cancelAddressForm() {
    this.addressesTable.ajax.reload();
    $('#states_code').val(null).trigger('change');
    $('#towns_code').val(null).trigger('change');
    $('#shipping_way').val(null).trigger('change');
    $('#copy-checkbox').prop('checked', false);
    this.createModal.close();
    this.addressForm.reset();
    this.submitted = false;
    this.editForm = false;
    this.showForm = false;
  }

  copyData(evt) {
    this.copyAddress = evt.target.checked;
    if (this.copyAddress) {
      this.currentShippingAddress();
      this.currentShippingColony();
      this.currentShippingZip();
    } else {
      this.addressForm.controls['invoiceAddress'].setValue('');
      this.addressForm.controls['invoiceColony'].setValue('');
      this.addressForm.controls['invoiceZip'].setValue('');
    }
  }

  onSubmitAddressForm() {
    if (this.addressForm.invalid) {
      this.submitted = true;
      return;
    }
    this.loading = true;
    var formAddressData: FormData = new FormData();

    formAddressData.append('addressName', this.addressForm.controls['addressName'].value);
    formAddressData.append('freight', this.addressForm.controls['freight'].value);
    formAddressData.append('freightName', this.addressForm.controls['freightName'].value);
    formAddressData.append('freightPaternalSurname', this.addressForm.controls['freightPaternalSurname'].value);
    formAddressData.append('freightMaternalSurname', this.addressForm.controls['freightMaternalSurname'].value);
    formAddressData.append('freightPhone', this.addressForm.controls['freightPhone'].value);
    formAddressData.append('shippingAddress', this.addressForm.controls['shippingAddress'].value);
    formAddressData.append('shippingColony', this.addressForm.controls['shippingColony'].value);
    formAddressData.append('shippingPhone', this.addressForm.controls['shippingPhone'].value);
    formAddressData.append('shippingState', this.addressForm.controls['shippingState'].value);
    formAddressData.append('shippingTown', this.addressForm.controls['shippingTown'].value);
    formAddressData.append('shippingWay', this.addressForm.controls['shippingWay'].value);
    formAddressData.append('shippingZip', this.addressForm.controls['shippingZip'].value);
    formAddressData.append('invoiceAddress', this.addressForm.controls['invoiceAddress'].value);
    formAddressData.append('invoiceColony', this.addressForm.controls['invoiceColony'].value);
    formAddressData.append('invoiceZip', this.addressForm.controls['invoiceZip'].value);
    formAddressData.append('businessName', this.addressForm.controls['businessName'].value);
    formAddressData.append('rfc', this.addressForm.controls['rfc'].value);
    formAddressData.append('idDistributor', this.addressForm.controls['idDistributor'].value);

    this.api.putFormData('addresses/updatedAddressByAdmin', this.addressForm.controls['id'].value, formAddressData).pipe(finalize(() => { this.loading = false; this.changes.detectChanges(); })).subscribe((addresses: Address[]) => {
      this.cancelAddressForm();
      Alerts.AlertSuccess('Dirección actualizada con éxito');
    }, (error) => {
      Alerts.AlertFailed(error.error.errors.join('; '));
    });
  }

  currentShippingAddress() {
    if (this.copyAddress && this.addressForm.controls['shippingAddress'].value != null && this.addressForm.controls['shippingAddress'].value.length > 1) {
      this.addressForm.controls['invoiceAddress'].setValue(this.addressForm.controls['shippingAddress'].value.toString());
    }
  }

  currentShippingColony() {
    if (this.copyAddress && this.addressForm.controls['shippingColony'].value != null && this.addressForm.controls['shippingColony'].value.length > 1) {
      this.addressForm.controls['invoiceColony'].setValue(this.addressForm.controls['shippingColony'].value.toString());
    }
  }

  currentShippingZip() {
    if (this.copyAddress && this.addressForm.controls['shippingZip'].value != null && this.addressForm.controls['shippingZip'].value.length > 1) {
      this.addressForm.controls['invoiceZip'].setValue(this.addressForm.controls['shippingZip'].value.toString());
    }
  }

  capitalizeSentence(string) {
    return string.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  refreshTable(): void {
    this.addressesTable.ajax.reload();
  }

  resetTable(): void {
    $('#name').val('');
    this.addressesTable.ajax.reload();
  }
}
