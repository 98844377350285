import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CodesProducts } from 'src/app/models/codes-products';
import { Alerts } from 'src/app/utils/alerts';
import { ApiService } from 'src/app/_api/api.service';
import gradesList from 'src/assets/js/grade.json';

declare let $: any;

@Component({
    selector: 'app-trash-boxes-unlabeled',
    templateUrl: './trash-boxes-unlabeled.component.html',
    styleUrls: ['./trash-boxes-unlabeled.component.sass']
})
export class TrashBoxesUnlabeledComponent implements OnInit, AfterViewInit {
    grades: any;
    loading = false;
    submitted = false;
    productForm: FormGroup;
    currentStock: number = 0;
    products: CodesProducts[] = [];
    currentProduct: CodesProducts = new CodesProducts();

    constructor(private api: ApiService, private builder: FormBuilder, private router: Router) {
        this.productForm = this.builder.group({
            product_code: [null, Validators.required],
            grade: [null, Validators.required],
            current_stock: [null, Validators.required],
            trash_stock: [null, [Validators.required, Validators.min(1)]]
        });
    }

    ngOnInit() { }

    ngAfterViewInit() {
        this.api.getAll('productsCodes/getAllProductsWithoutLabels').subscribe((response: CodesProducts[]) => {
            this.products = response;
        });

        $('.select2-control').select2({
            theme: 'bootstrap',
            width: '100%'
        });

        $('#product-code').on('change', () => {
            if ($('#product-code').val() != '') {
                this.currentProduct = this.products.find((product: CodesProducts) => {
                    return product.id == $('#product-code').val();
                });

                gradesList.forEach(element => {
                    if (this.currentProduct.level == element['code']) {
                        this.grades = element['grades'];
                    }
                });

                $('#grade').empty().trigger('change');

                this.grades.forEach((grades: any) => {
                    if (grades == '7') {
                        grades = 'UNICO';
                    }

                    let newOption = new Option(grades, grades, false, false);

                    $('#grade').append(newOption);

                });

                $('#grade').val(null).trigger('change');

                this.productForm.controls['product_code'].setValue(this.currentProduct.id);
                this.productForm.controls['grade'].setValue(null);
                this.productForm.controls['current_stock'].setValue(null);

                this.currentStock = 0;
            }
        });

        $('#grade').on('change', () => {
            let grade = $('#grade').val();

            if (grade != null) {
                this.productForm.controls['grade'].setValue(grade);

                this.api.getAll('boxes/getStockOfProduct', { code_product_id: this.productForm.controls['product_code'].value, grade: this.productForm.controls['grade'].value }).subscribe((stock: number) => {
                    this.currentStock = stock;
                    this.productForm.controls['current_stock'].setValue(stock);
                });
            }
        });
    }

    cancelForm() {
        this.currentStock = 0;
        this.productForm.reset();
        this.currentProduct = null;
        $('#product-code, #grade').val(null).trigger('change');
    }

    onSubmit() {
        if (this.productForm.invalid) {
            this.submitted = true;
            return;
        }

        let trash = parseInt(this.productForm.controls['trash_stock'].value, 10);
        let stock = parseInt(this.productForm.controls['current_stock'].value, 10);

        if (trash > stock) {
            Alerts.AlertFailed('La cantidad de cajas merma ingresada es mayor a las existencias actuales');
            return;
        }

        this.loading = true;

        let form: FormData = new FormData();
        form.append('product', this.currentProduct.id);
        form.append('grade', this.productForm.controls['grade'].value);
        form.append('trash_stock', this.productForm.controls['trash_stock'].value);
        
        this.api.post('trash-boxes/unlabeledBoxes', form).pipe(finalize(() => {
            this.loading = false;
        })).subscribe((response: any) => {
            this.cancelForm();
            
            Alerts.AlertSuccess('Registro de cajas merma exitoso');
        });
    }
}