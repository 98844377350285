import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { CreateModalComponent } from 'src/app/components/create-modal/create-modal.component';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { User } from 'src/app/models';
import { ApiService } from 'src/app/_api/api.service';
import { DatePipe } from '@angular/common';
import { Role } from 'src/app/models/role';
import { DeleteModalComponent } from 'src/app/components/delete-modal/delete-modal.component';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
declare var $: any;

export function ValidateRole(control: AbstractControl) {
  if (control.value == null) {
    return { validRole: true };
  }
  return null;
}

@Component({
  selector: '[app-users], [app-create-modal]',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.sass'],
})
export class UsersComponent implements OnInit, AfterViewInit {

  @ViewChild(CreateModalComponent)
  private createModal: CreateModalComponent;
  @ViewChild(DeleteModalComponent)
  private deleteModal: DeleteModalComponent;
  private usersTable: any;
  roles: Role[] = [];

  isEditingUser: boolean = false;
  submitted = false;
  loading: boolean = false;
  userForm: FormGroup;
  nowDate: string;
  statusUsername: string;
  statusUserEmail: string;
  @Input('title') titleModal: string;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.nowDate = new DatePipe('en-US').transform(new Date().toISOString().toLocaleString().slice(0, 16), 'dd:MM:yyyy hh:mm:ss', 'GMT-6');
  }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      id: [null],
      username: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      role: [null, ValidateRole],
    });
  }

  ngAfterViewInit(): void {
    $('.datepicker').datepicker({
      enableOnReadonly: true,
      todayHighlight: true,
      autoclose: true,
    });

    $('#initialDate').on('changeDate', function () {
      $('#initialDate').val($('#initialDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#initialDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $("#finalDate").datepicker("setStartDate", dtFormatted);
    });

    $('#finalDate').on('changeDate', function () {
      $('#finalDate').val($('#finalDate').datepicker('getFormattedDate'));
      var dtMax = new Date($('#finalDate').val());
      var dd = dtMax.getDate();
      var mm = dtMax.getMonth() + 1;
      var y = dtMax.getFullYear();
      var dtFormatted = mm + '/' + dd + '/' + y;
      $("#initialDate").datepicker("setEndDate", dtFormatted);
    });

    this.usersTable = $('#user-table').DataTable({
      'ajax': {
        url: `${environment.apiUrl}/users`,
        data: function (data) {
          data.from = $("#initialDate").val();
          data.to = $("#finalDate").val();
          data.searchData = $("#search").val();
        }

      },
      'serverSide': true,
      'searching': false,
      'paging': true,
      'lengthChange': false,
      'language': {
        'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json'
      },
      columns: [
        { data: 'id' },
        { data: 'name' },
        { data: 'username' },
        { data: 'email' },
        { data: 'role.description' },
        {
          data: null,
          defaultContent: '',
          createdCell: function (td, cellData, rowData, row, col) {
            if(cellData['role']['name'] != 'ROLE_SUPER_ADMINISTRATOR' && cellData['role']['name'] != 'ROLE_DISTRIBUTOR') {
              $(td).prepend(
                `
                  <div class="btn-group" role="group" aria-label="Acciones">
                  <button type="button" class="btn btn-warning user-edit" title="Editar">
                  <i class="mdi mdi-lead-pencil text-white"></i>
                  </button>
                  <button type="button" class="btn btn-danger user-delete" title="Eliminar">
                  <i class="mdi mdi-delete-forever"></i>
                  </button>
                  </div>
                `
              );
            }
          },
          orderable: false,
          searchable: false
        }
      ],
      columnDefs: [
        {
            "targets": [ 0 ],
            "visible": false,
            "searchable": false
        }
      ],
      drawCallback: () => {
        $('.user-edit').on('click', (element) => {
          let user: User = this.usersTable.row($(element.currentTarget).closest('tr')).data();
          this.editUser(user);
        });

        $('.user-delete').on('click', (element) => {
          let user: User = this.usersTable.row($(element.currentTarget).closest('tr')).data();
          this.deleteUser(user);
        });
      }
    });

    $('#role').select2({
      theme: "bootstrap",
      width: '100%',
      dropdownParent: $('#UserModal')
    });

    $('#role').val(null).trigger('change');

    $('#role').on("change", () => {
      var currentRole: Role = this.roles.find((role: Role) => {
        return role.id == $('#role').val();
      });
      this.userForm.controls['role'].setValue(currentRole);
    });
  }

  createUser() {
    this.cancelUserForm();
    this.isEditingUser = false;
    this.api.getAll('roles').subscribe((rolesData: Role[]) => {
      this.roles = rolesData;
      $('#role').empty().trigger("change");
      rolesData.forEach((role: Role) => {
        if (role.name != "ROLE_DISTRIBUTOR") {
          var newOption = new Option(role.description, role.id, false, false);
          $('#role').append(newOption).trigger('change');
        }
      });

      $('#role').val(null).trigger('change');
      this.createModal.open(true);
    });
  }

  editUser(user: User) {
    this.isEditingUser = true;
    Object.keys(user).forEach(key => {
      if (this.userForm.controls[key])
        this.userForm.controls[key].setValue(user[key]);
    });
    this.api.getAll('roles').subscribe((rolesData: Role[]) => {
      this.roles = rolesData;
      $('#role').empty();
      rolesData.forEach((role: Role) => {
        if (user.role.name != "ROLE_DISTRIBUTOR") {
          if (role.name != "ROLE_DISTRIBUTOR") {
            var newOption = new Option(role.description, role.id, false, false);
            $('#role').append(newOption);
          }
          $("#role").prop("disabled", false);
        } else {
          var newOption = new Option(role.description, role.id, false, false);
          $('#role').append(newOption);
          $("#role").prop("disabled", true);
        }
      });
      //$('#role').trigger('change');
      $('#role').val(user.role.id).trigger("change");
      this.createModal.open(false);
    });
  }

  deleteUser(user: User) {
    Object.keys(user).forEach(key => {
      if (this.userForm.controls[key])
        this.userForm.controls[key].setValue(user[key]);
    });
    this.deleteModal.open();
  }

  cancelUserForm() {
    this.createModal.close();
    this.deleteModal.close();
    this.userForm.reset();
    this.submitted = false;
    $('#role_control').find('.select2-selection').removeClass('select2-is-invalid');
  }

  onSubmitUserForm() {
    if (this.userForm.invalid) {
      this.submitted = true;
      if (this.userForm.controls['role'].value == null) {
        $('#role_control').find(".select2-selection").addClass("select2-is-invalid");
      }
      return;
    }
    this.loading = true;
    if (this.userForm.controls['id'].value) {
      var user: User = Object.assign({}, this.userForm.value);
      this.api.patch('users', this.userForm.controls['id'].value, user).pipe(finalize(() => { this.loading = false; })).subscribe((users: User[]) => {
        this.cancelUserForm();
        this.usersTable.ajax.reload();
        this.alertSucces('Usuario actualizado con éxito');
      }, (error) => {
        this.alertFailed(error.error.errors.join('; '));
      });

    } else {
      this.loading = true;
      var user: User = Object.assign({}, this.userForm.value);
      this.api.post('users', user).pipe(finalize(() => { this.loading = false; })).subscribe((users: User[]) => {
        this.createModal.close();
        this.userForm.reset();
        this.usersTable.ajax.reload();
        this.alertSucces('Usuario guardado con éxito');
      }, (error) => {
        this.alertFailed(error.error.errors.join('; '));
      });
    }
  }

  onSubmitUserDelete() {
    this.loading = true;
    var user: User = Object.assign({}, this.userForm.value);
    this.api.patch('users/delete', this.userForm.controls['id'].value, user).pipe(finalize(() => { this.loading = false; })).subscribe((users: User[]) => {
      this.cancelUserForm();
      this.usersTable.ajax.reload();
      this.alertSucces('Usuario eliminado con éxito');
    });
  }

  get role() {
    return this.userForm.get('role');
  }

  refreshUsersTable() {
    this.usersTable.ajax.reload();
  }

  resetUsersTable() {
    $('#initialDate').data('datepicker').setDate(null);
    $('#finalDate').data('datepicker').setDate(null);
    $("#search").val('');
    this.usersTable.ajax.reload();
  }

  alertSucces(title) {
    $.toast({
      heading: 'Realizado',
      text: title,
      showHideTransition: 'slide',
      icon: 'success',
      loaderBg: '#f96868',
      position: 'bottom-left'
    });
  }

  alertFailed(title) {
    $.toast({
      heading: 'Alerta',
      text: title,
      showHideTransition: 'slide',
      icon: 'error',
      loaderBg: '#f2a654',
      position: 'bottom-left'
    });
  }
}
