import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { User } from 'src/app/models';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { environment } from 'src/environments/environment';
declare var PerfectScrollbar: any
declare var $: any

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.sass']
})
export class MainComponent implements OnInit {
  isNavActive = false;
  currentRoute = '';
  currentUser: User;

  constructor(private auth: AuthenticationService, private router: Router) { }

  ngOnInit(): void {
    this.currentRoute = this.router.url;
    this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        var sidebar = $('.sidebar');
        sidebar.find('.collapse.show').collapse('hide');
      }
      if (e instanceof NavigationEnd) {
        var sidebar = $('.sidebar');
        $('a.active').removeClass('active');
        $('li.active').removeClass('active');
        this.currentRoute = e.url;
        $('.nav li a', sidebar).each((i, element) => {
          var $this = $(element);
          this.addActiveClass($this);
        })

        $('.horizontal-menu .nav li a').each((i, element) => {
          var $this = $(element);
          this.addActiveClass($this);
        })
      }
    });
    this.currentUser = this.auth.user;
    $.ajaxSetup({
      beforeSend: (xhr: any, settings: any) => {
        if (settings.url.startsWith(environment.apiUrl)) {
          if (!settings.url.startsWith(`${environment.apiUrl}/auth`)) {
            xhr.setRequestHeader('Authorization', `Bearer ${this.auth.user.token}`);
          }
        }
      }
    });
    var r20 = /%20/g, rbracket = /\[\]$/, rCRLF = /\r?\n/g, rsubmitterTypes = /^(?:submit|button|image|reset|file)$/i, rsubmittable = /^(?:input|select|textarea|keygen)/i;

    function customBuildParams(prefix, obj, traditional, add) {
      var name;

      if ($.isArray(obj)) {
        // Serialize array item.
        $.each(obj, function (i, v) {
          if (traditional || rbracket.test(prefix)) {
            // Treat each array item as a scalar.
            add(prefix, v);

          } else {
            // Item is non-scalar (array or object), encode its numeric
            // index.
            customBuildParams(prefix + '['
              + (typeof v === 'object' ? i : '') + ']', v,
              traditional, add);
          }
        });

      } else if (!traditional && $.type(obj) === 'object') {
        // Serialize object item.
        for (name in obj) {
          // This is where the magic happens
          customBuildParams(prefix + '.' + name, obj[name], traditional,
            add);
        }

      } else {
        // Serialize scalar item.
        add(prefix, obj);
      }
    }

    $.param = function (a, traditional) {
      var prefix, s = [], add = function (key, value) {
        // If value is a function, invoke it and return its value
        value = $.isFunction(value) ? value() : (value == null ? ''
          : value);
        s[s.length] = encodeURIComponent(key) + '='
          + encodeURIComponent(value);
      };

      // Set traditional to true for jQuery <= 1.3.2 behavior.
      if (traditional === undefined) {
        traditional = $.ajaxSettings
          && $.ajaxSettings.traditional;
      }

      // If an array was passed in, assume that it is an array of form
      // elements.
      if ($.isArray(a) || (a.jquery && !$.isPlainObject(a))) {
        // Serialize the form elements
        $.each(a, function () {
          add(this.name, this.value);
        });

      } else {
        // If traditional, encode the 'old' way (the way 1.3.2 or older
        // did it), otherwise encode params recursively.
        for (prefix in a) {
          customBuildParams(prefix, a[prefix], traditional, add);
        }
      }

      // Return the resulting serialization
      return s.join('&').replace(r20, '+');
    };
  }

  ngAfterViewInit(): void {
    var body = $('body');
    var contentWrapper = $('.content-wrapper');
    var scroller = $('.container-scroller');
    var footer = $('.footer');
    var sidebar = $('.sidebar');


    //Close other submenu in sidebar on opening any     
    $('a.active').removeClass('active');
    $('li.active').removeClass('active');
    $('.nav li a', sidebar).each((i, element) => {
      var $this = $(element);
      this.addActiveClass($this);
    })

    $('.horizontal-menu .nav li a').each((i, element) => {
      var $this = $(element);
      this.addActiveClass($this);
    })
    sidebar.on('show.bs.collapse', '.collapse', function () {
      sidebar.find('.collapse.show').collapse('hide');
    });

    //Change sidebar and content-wrapper height
    applyStyles();

    function applyStyles() {
      //Applying perfect scrollbar
      if (!body.hasClass('rtl')) {
        if ($('.settings-panel .tab-content .tab-pane.scroll-wrapper').length) {
          const settingsPanelScroll = new PerfectScrollbar('.settings-panel .tab-content .tab-pane.scroll-wrapper');
        }
        if ($('.chats').length) {
          const chatsScroll = new PerfectScrollbar('.chats');
        }
        if (body.hasClass('sidebar-fixed')) {
          if ($('#sidebar').length) {
            var fixedSidebarScroll = new PerfectScrollbar('#sidebar .nav');
          }
        }
      }
    }

    $('[data-toggle="minimize"]').on('click', function () {
      if ((body.hasClass('sidebar-toggle-display')) || (body.hasClass('sidebar-absolute'))) {
        body.toggleClass('sidebar-hidden');
      } else {
        body.toggleClass('sidebar-icon-only');
      }
    });

    //checkbox and radios
    $('.form-check label,.form-radio label').append('<i class="input-helper"></i>');

    //Horizontal menu in mobile
    $('[data-toggle="horizontal-menu-toggle"]').on('click', function () {
      $('.horizontal-menu .bottom-navbar').toggleClass('header-toggled');
    });
    // Horizontal menu navigation in mobile menu on click
    var navItemClicked = $('.horizontal-menu .page-navigation >.nav-item');
    navItemClicked.on('click', function (event) {
      if (window.matchMedia('(max-width: 991px)').matches) {
        if (!($(this).hasClass('show-submenu'))) {
          navItemClicked.removeClass('show-submenu');
        }
        $(this).toggleClass('show-submenu');
      }
    })

    $(window).scroll(function () {
      if (window.matchMedia('(min-width: 992px)').matches) {
        var header = $('.horizontal-menu');
        if ($(window).scrollTop() >= 70) {
          $(header).addClass('fixed-on-scroll');
        } else {
          $(header).removeClass('fixed-on-scroll');
        }
      }
    });
  }

  addActiveClass(element): void {
    var current = this.currentRoute;
    if (current === '/') {
      //for root url
      if (element.attr('href') == '/' && element.attr('role') != 'button') {
        element.parents('.nav-item').last().addClass('active');
        if (element.parents('.sub-menu').length) {
          element.closest('.collapse').addClass('show');
          element.addClass('active');
        }
        if (window.matchMedia('(max-width: 767px)').matches) {
          $('.sidebar-offcanvas').removeClass('active')
        }
      }
    } else {
      //for other url
      if (element.attr('href').indexOf(current) !== -1 && element.attr('role') != 'button') {
        element.parents('.nav-item').last().addClass('active');
        if (window.matchMedia('(max-width: 767px)').matches) {
          $('.sidebar-offcanvas').removeClass('active')
        }
        if (element.parents('.sub-menu').length) {
          element.closest('.collapse').addClass('show');
          element.addClass('active');
        }
        if (element.parents('.submenu-item').length) {
          element.addClass('active');
        }
      }
    }
  }

  logout(): void {
    this.auth.logout();
  }

  toggleNav(): void {
    this.isNavActive = !this.isNavActive;
  }
}