import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/_api/api.service';
import { ModalReturnComponent } from 'src/app/components/modal-return/modal-return.component';
import { Distribution } from 'src/app/models/distribution';
import { Tracking } from 'src/app/models/trackings';
import { finalize } from 'rxjs/operators';
import { BookBox } from 'src/app/models/book-box';
import { DistributionProducts } from 'src/app/models/distribution-products';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-distribution',
  templateUrl: './distribution.component.html',
  styleUrls: ['./distribution.component.sass']
})
export class DistributionComponent implements OnInit, AfterViewInit {
  @ViewChild("show")
  private showModal: ModalReturnComponent;

  @ViewChild("addTracking")
  private addTrackingModal: ModalReturnComponent;

  loading: boolean = false;
  isDownloadPDF: boolean = false;
  currentDistribution: Distribution = new Distribution();
  trackings: Tracking[] = [];
  createdAt: string = "";

  distributionsTable: any;
  productsShowTable: any;
  code: string = "";
  name: string = "";
  state: string = "";
  town: string = "";
  isShowingDistribution: boolean = false;

  showOrder: string = "";
  showDistributorName: string = "";
  showDistributorState: string = "";
  showDistributorMunicipality: string = "";
  showOrderType: string = "";
  showPaymentMethod: string = "";
  showOrderStatus: string = "";
  showShippingAddress: string = "";
  codeDistribution: string = "";
  showDistributorCode: string = "";
  showDisitributorName: string = "";
  showDisitributorTown: string = "";
  showBoxes: BookBox[] = [];
  showCodes: boolean = false;
  showAdditionalProducts: DistributionProducts[] = [];
  showOtherProducts: DistributionProducts[] = [];

  constructor(private api: ApiService) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    $('.datepicker').datepicker({
      enableOnReadonly: true,
      todayHighlight: true,
      autoclose: true,
    });

    $('#initialDate, #finalDate').datepicker({
      enableOnReadonly: true,
      todayHighlight: true,
      autoclose: true,
    });

    this.distributionsTable = $('#distributions-table').DataTable({
      'ajax': {
        url: `${environment.apiUrl}/distributions/getDistributionsByDistributor`,
        data: function (data) {
          data.from = $("#initialDate").val();
          data.to = $("#finalDate").val();
          data.searchData = $("#search").val();
        }

      },
      'serverSide': true,
      'searching': false,
      'paging': true,
      'lengthChange': false,
      'language': {
        'url': '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json'
      },
      columns: [
        {
          data: 'idOrder',
          render: (data, type, row, meta) => {
            return row['idOrder']['id'];
          }
        },
        { data: 'createdAt' },
        {
          data: null,
          defaultContent: '',
          createdCell: function (td, cellData, rowData, row, col) {
            var actions = ``;
            if (rowData['statusDistribution'] == "Parcial") {
              actions += `<label class="badge badge-warning text-white">Parcial</label>`;
            } else {
              actions += `<label class="badge badge-success">Completo</label>`;
            }
            $(td).prepend(actions);
          },
          orderable: false,
          searchable: false
        },
        {
          data: null,
          defaultContent: '',
          createdCell: function (td, cellData, rowData, row, col) {
            var actions =
            `<div class='btn-group' role='group' aria-label='Acciones'>
              <button type='button' class='btn btn-info distribution-show' title='Ver Detalles'>
                <i class='mdi mdi-plus text-white'></i>
              </button>
              <button type='button' class='btn btn-primary distribution-trackings' title='Guías de Envío'>
                <i class='mdi mdi-truck text-white'></i>
              </button>
              <button type='button' class='btn btn-secondary distribution-download' title='Descargar PDF'>
                <i class='mdi mdi-file-pdf text-white'></i>
              </button>
            </div>`;
            $(td).prepend(actions);
          },
          orderable: false,
          searchable: false
        }
      ],
      drawCallback: () => {
        $('.distribution-show').on('click', (element) => {
          let distribution: Distribution = this.distributionsTable.row($(element.currentTarget).closest('tr')).data();
          this.showDistribution(distribution);
        });

        $('.distribution-trackings').on('click', (element) => {
         this.currentDistribution = this.distributionsTable.row($(element.currentTarget).closest('tr')).data();
          this.addTracking(this.currentDistribution);
        });

        $('.distribution-download').on('click', (element) => {
          let distribution: Distribution = this.distributionsTable.row($(element.currentTarget).closest('tr')).data();
         this.downloadDistribution(distribution);
        });
      }
    });
  }

  showDistribution(distribution: Distribution) {
    this.closeShowForm();
    this.createdAt = distribution.createdAt;
    this.showOrder = distribution.idOrder.id;
    this.showDisitributorName = distribution.idOrder.idDistributor.name + " " + distribution.idOrder.idDistributor.paternalSurname + " " + distribution.idOrder.idDistributor.maternalSurname;
    this.showDistributorState = distribution.idOrder.idDistributor.state;
    this.showDistributorMunicipality = distribution.idOrder.idDistributor.town;
    this.showOrderType = distribution.idOrder.type;
    this.showPaymentMethod = distribution.idOrder.paymentMethod;
    this.showOrderStatus = distribution.idOrder.orderStatus;
    this.showShippingAddress = distribution.idOrder.shippingAddress + ", " + distribution.idOrder.shippingColony + ", " + distribution.idOrder.shippingTown + ", " + distribution.idOrder.shippingState;

    if (distribution.idOrder.idCodeProduct) {
      this.showCodes = distribution.idOrder.idCodeProduct.physicalLabels
    } else {
      this.showCodes = false;
    }

    this.api.get('distributions/boxesOfDistribution', distribution.id).subscribe((boxesData: BookBox[]) => {
      this.api.get('distributions/additionalProductsOfDistribution', distribution.id).subscribe((additionalProducts: DistributionProducts[]) => {
        this.api.get('distributions/otherProductsOfDistribution', distribution.id).subscribe((otherProducts: DistributionProducts[]) => {
          this.showBoxes = boxesData;
          this.showAdditionalProducts = additionalProducts;
          this.showOtherProducts = otherProducts;
          this.isShowingDistribution = true;
          this.showModal.open(false);
        });
      });
    });
  }

  addTracking(distribution: Distribution) {
    this.api.get('trackings/getTrackingsOfDistribution', distribution.id).subscribe((trackingsData: Tracking[]) => {
      this.trackings = trackingsData;
      this.addTrackingModal.open(true);
    });
  }

  downloadDistribution(distribution: Distribution) {
    this.loading = true;
    this.isDownloadPDF = true;
    this.api.get('distributions/generateDistributionPDF', distribution.id).pipe(finalize(() => { this.loading = false; this.isDownloadPDF = false; })).subscribe((pdf: any) => {
      const mediaType = 'application/pdf';
      var binary = atob(pdf.pdf.replace(/\s/g, ''));
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }
      const blob = new Blob([view], { type: mediaType });
      saveAs(blob, "salida-" + distribution.id + ".pdf");
    });
  }

  closeShowForm() {
    this.showModal.close();
    this.showDistributorName = "";
    this.showDistributorState = "";
    this.showDistributorMunicipality = "";
    this.showOrderType = "";
    this.showPaymentMethod = "";
    this.showOrderStatus = "";
    this.showShippingAddress = "";
    this.showCodes = false;
  }

  cancelAddTrackingForm() {
    this.addTrackingModal.close();
    this.trackings = [];
  }

  refreshDistributionsTable() {
    this.distributionsTable.ajax.reload();
  }

  resetDistributionsTable() {
    $('#initialDate').data('datepicker').setDate(null);
    $('#finalDate').data('datepicker').setDate(null);
    $("#search").val('');
    this.distributionsTable.ajax.reload();
  }

  anyShowBox(): boolean {
    if (this.showBoxes.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  anyShowAdditionalProducts(): boolean {
    if (this.showAdditionalProducts.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  anyShowOtherProducts(): boolean {
    if (this.showOtherProducts.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}
