import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService } from 'src/app/_api/api.service';
import { finalize } from 'rxjs/operators';
import { Distribution } from 'src/app/models/distribution';
import { ProductsSorting } from 'src/app/models/products-sorting';
import { User } from 'src/app/models';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Category } from 'src/app/models/category';
import { Product } from 'src/app/models/products';
import { Season } from 'src/app/models/season';
import { Order } from 'src/app/models/order';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.sass']
})
export class BlankComponent implements OnInit {
  loading: boolean = false;
  totalUser: number = 0;
  totalCategories: number = 0;
  totalDistributions: number = 0;
  totalProductsWithOutBoxes: number = 0;
  totalProductsBoxesInWareHouse: number = 0;
  totalDistributedProductsBoxes: number = 0;
  totalDistributors: number = 0;
  totalReturns: number = 0;
  totalSeason: number = 0;
  totalOrders: number = 0;
  distributionsTable: any;
  productsTable: any;
  distributions: Distribution[] = [];
  products : ProductsSorting[] = [];
  orders : any[] = [];
  currentUser: User;
  loadingUser : boolean = false;
  loadingCategories : boolean = false;
  loadingDistributions : boolean = false;
  loadingReturns: boolean = false;
  loadingDistributors : boolean = false;
  loadingProductsWithOutBoxes : boolean = false;
  loadingProductBoxesInWareHouse : boolean = false;
  loadingDistributedProductBoxes : boolean = false;
  loadingLastDistributions: boolean = false;
  loadingLastOrders: boolean = false;
  loadingMostDistributedProducts: boolean = false;
  loadingSeasons: boolean = false;
  loadingOrders: boolean = false;

  constructor(private api: ApiService, private auth: AuthenticationService) {

  }

  ngOnInit() {
    this.currentUser = this.auth.user;
    this.loadingUser = true;
    this.loadingCategories = true;
    this.loadingDistributions = true;
    this.loadingReturns= true;
    this.loadingDistributors = true;
    this.loadingProductsWithOutBoxes = true;
    this.loadingProductBoxesInWareHouse = true;
    this.loadingDistributedProductBoxes = true;
    this.loadingLastDistributions= true;
    this.loadingLastOrders= true;
    this.loadingMostDistributedProducts= true;
    this.loadingSeasons = true;
    this.loadingOrders = true;

    this.api.getAll('users/getTotalUser').pipe(finalize(() => { this.loadingUser = false; })).subscribe((total: number) => {
      this.totalUser = total;
    });

    this.api.getAll('categories/getAll').pipe(finalize(() => { this.loadingCategories = false; })).subscribe((categories: Category[]) => {
      this.totalCategories = categories.length;
    });

    this.api.getAll('distributions/getTotalDistributions').pipe(finalize(() => { this.loadingDistributions = false; })).subscribe((distributions: Distribution[]) => {
      this.totalDistributions = distributions.length;
    });

    this.api.getAll('returns/getTotalReturns').pipe(finalize(() => { this.loadingReturns = false; })).subscribe((total: number) => {
      this.totalReturns = total;
    });

    this.api.getAll('distributors/getTotalDistributors').pipe(finalize(() => { this.loadingDistributors = false; })).subscribe((total: number) => {
      this.totalDistributors = total;
    });

    this.api.getAll('products/getAll').pipe(finalize(() => { this.loadingProductsWithOutBoxes = false; })).subscribe((products: Product[]) => {
      this.totalProductsWithOutBoxes = products.length;
    });

    this.api.getAll('boxes/getTotalBoxesInWareHouse').pipe(finalize(() => { this.loadingProductBoxesInWareHouse = false; })).subscribe((total: number) => {
      this.totalProductsBoxesInWareHouse = total;
    });

    this.api.getAll('boxes/getTotalBoxesDistributed').pipe(finalize(() => { this.loadingDistributedProductBoxes = false; })).subscribe((total: number) => {
      this.totalDistributedProductsBoxes = total;
    });

    this.api.getAll('distributions/getLastDistributions').pipe(finalize(() => { this.loadingLastDistributions = false; })).subscribe((data: Distribution[]) => {
      this.distributions = data;
    });
    this.api.getAll('orders/getLastOrders').pipe(finalize(() => { this.loadingLastOrders = false; })).subscribe((data: any[]) => {
      this.orders = data;
      
    });

    this.api.getAll('distributions/getMostDistributedProducts').pipe(finalize(() => { this.loadingMostDistributedProducts = false; })).subscribe((data : ProductsSorting[]) =>{
      this.products = data;
    });

    this.api.getAll('seasons/getTotalSeasons').pipe(finalize(() => { this.loadingSeasons = false; })).subscribe((data: Season[]) => {
      this.totalSeason  = data.length;
    });

    this.api.getAll('orders/getTotalOrders').pipe(finalize(() => { this.loadingOrders = false;})).subscribe((data : Order[]) => {
      this.totalOrders = data.length;
    });
  }
}
