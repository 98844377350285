import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/_api/api.service';
import { finalize } from 'rxjs/operators';
import { Alerts } from 'src/app/utils/alerts';
declare var $: any;

@Component({
  selector: 'app-workbook-supply',
  templateUrl: './workbook-supply.component.html',
  styleUrls: ['./workbook-supply.component.sass']
})
export class WorkbookSupplyComponent implements OnInit, AfterViewInit {
  workbookSupply: FormGroup;
  loading: boolean = false;
  dropify: any;

  constructor(private api: ApiService, private builder: FormBuilder) {
    this.workbookSupply = this.builder.group({
      csv: ['', Validators.required]
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.dropify = $('.dropify').dropify({
      messages: {
        'remove': 'Eliminar el archivo',
        'error': 'Vaya, ha ocurrido un error inesperado.',
        'replace': 'Arrastra y suelta o haz clic para reemplazar el archivo',
        'default': 'Arrastra y suelta o haz clic para seleccionar el archivo'
      },
      error: {
        'fileSize': 'El archivo es demasiado grande (2MB MAX).',
        'fileExtension': 'La extensión del archivo no es la correcta (sólo archivos CSV).'
      }
    });

    this.dropify = this.dropify.data('dropify');
  }

  resetForm(): void {
    this.workbookSupply.reset();
    this.dropify.resetPreview();
    this.dropify.clearElement();
  }
  
  onFileChange(event: any): void {
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.workbookSupply.controls['csv'].setValue(file);
    }
  }
  
  onSubmit(): void {
    if (this.workbookSupply.invalid) {
      return;
    }
    this.loading = true;
    var form: FormData = new FormData();
    form.append('file', this.workbookSupply.controls['csv'].value);
    this.api.post('boxes/supply', form).pipe(finalize(() => {
      this.resetForm();
      this.loading = false;
    })).subscribe((response: any) => {
      Alerts.AlertSuccess(response.join('. '));
    }, (error: any) => {
      if (error.error.errors) {
        if (error.error.message == 'Warning') {
          Alerts.AlertWarning(error.error.errors.join('. '));
        } else {
          Alerts.AlertFailed(error.error.errors.join('. '));
        }
      } else {
        Alerts.AlertFailed(error.message);
      }
    });
  }
}