import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private http: HttpClient) { }

    get(controller: string, id: any, params?: any, headers?: any) {
        return this.http.get(`${environment.apiUrl}/${controller}/${id}`, { headers: headers, params: params });
    }

    getAll(controller: string, params?: any, headers?: any) {
        return this.http.get(`${environment.apiUrl}/${controller}`, { headers: headers, params: params });
    }

    getCSVFile(controller: string, params?: any, headers?: any) {
        return this.http.get(`${environment.apiUrl}/${controller}`, { headers: headers, params: params, responseType: 'arraybuffer', observe: 'response' });
    }

    getExcelFile(controller: string, params?: any, headers?: any) {
        return this.http.get(`${environment.apiUrl}/${controller}`, { headers: headers, params: params, responseType: 'blob', observe: 'response' });
    }

    post(controller: string, body: any, params?: any, headers?: any) {
        return this.http.post(`${environment.apiUrl}/${controller}`, body, { headers: headers, params: params });
    }

    put(controller: string, id: number, body: any, params?: any, headers?: any) {
        return this.http.put(`${environment.apiUrl}/${controller}/${id}`, body, { headers: headers, params: params });
    }

    patch(controller: string, id: number, body: any, params?: any, headers?: any) {
        return this.http.patch(`${environment.apiUrl}/${controller}/${id}`, body, { headers: headers, params: params });
    }

    delete(controller: string, id: number, params?: any, headers?: any) {
        return this.http.delete(`${environment.apiUrl}/${controller}/${id}`, { headers: headers, params: params });
    }

    postUpload(controller: string, body: FormData, params?: any, headers?: any) {
        body.append('_method', 'POST');
        return this.http.post(`${environment.apiUrl}/${controller}`, body, { headers: headers, params: params });
    }

    putUpload(controller: string, id: number, body: FormData, params?: any, headers?: any) {
        body.append('_method', 'PUT');
        return this.http.post(`${environment.apiUrl}/${controller}/${id}`, body, { headers: headers, params: params });
    }

    putFormData(controller: string, id: number, body: FormData, params?: any, headers?: any) {
        return this.http.post(`${environment.apiUrl}/${controller}/${id}`, body, { headers: headers, params: params });
    }

    getAdditionalProducts(controller: string, id: any, grade: any, params?: any, headers?: any) {
        return this.http.get(`${environment.apiUrl}/${controller}/${id}/${grade}`, { headers: headers, params: params });
    }
    
    deleteRelation(controller: string, idCodeProduct: number, grade: string, params?: any, headers?: any) {
        return this.http.delete(`${environment.apiUrl}/${controller}/${idCodeProduct}/${grade}`, { headers: headers, params: params });
    }
}
