import { HttpInterceptor } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private auth: AuthenticationService,
    ) { }

    intercept(req: import("@angular/common/http").HttpRequest<any>, next: import("@angular/common/http").HttpHandler): import("rxjs").Observable<import("@angular/common/http").HttpEvent<any>> {
        return next.handle(req).pipe(catchError(error => {
            if (error.status == 401) {
                this.auth.logout();
            }
            if(error.status == 422 || error.status === 403 || error.status === 404 || error.status === 400) {
            }
            return throwError(error);
        }))
    }
}
